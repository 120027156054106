<template>
  <div class="flex justify-between items-center my-4">
    <div>
      <h4 class="font-bold text-2xl text-292">Gerenciar endereços</h4>
      <p class="mb-6 text-94 text-sm">Crie e edite endereços da organização</p>
    </div>
    <button @click="isCreateAddressModalOpen = true" class="bg-primary px-4 py-3 text-white flex items-center gap-2 rounded-3xl font-bold">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M14.75 11.75H9.25C8.84 11.75 8.5 11.41 8.5 11C8.5 10.59 8.84 10.25 9.25 10.25H14.75C15.16 10.25 15.5 10.59 15.5 11C15.5 11.41 15.16 11.75 14.75 11.75Z" fill="#fff" />
        <path d="M12 14.5C11.59 14.5 11.25 14.16 11.25 13.75V8.25C11.25 7.84 11.59 7.5 12 7.5C12.41 7.5 12.75 7.84 12.75 8.25V13.75C12.75 14.16 12.41 14.5 12 14.5Z" fill="#fff" />
        <path
          d="M12.0001 22.76C10.5201 22.76 9.03005 22.2 7.87005 21.09C4.92005 18.25 1.66005 13.72 2.89005 8.33C4.00005 3.44 8.27005 1.25 12.0001 1.25C12.0001 1.25 12.0001 1.25 12.0101 1.25C15.7401 1.25 20.0101 3.44 21.1201 8.34C22.3401 13.73 19.0801 18.25 16.1301 21.09C14.9701 22.2 13.4801 22.76 12.0001 22.76ZM12.0001 2.75C9.09005 2.75 5.35005 4.3 4.36005 8.66C3.28005 13.37 6.24005 17.43 8.92005 20C10.6501 21.67 13.3601 21.67 15.0901 20C17.7601 17.43 20.7201 13.37 19.6601 8.66C18.6601 4.3 14.9101 2.75 12.0001 2.75Z"
          fill="#fff"
        />
      </svg>
      Criar
    </button>
  </div>
  <div v-if="addresses && !addresses?.length" class="my-10 py-10">
    <svg xmlns="http://www.w3.org/2000/svg" width="104" height="104" viewBox="0 0 24 24" fill="none" class="mx-auto mb-4">
      <path
        opacity="0.4"
        d="M18.89 5.11L5.78999 18.21C3.89999 15.64 2.53999 12.36 3.37999 8.69C3.89999 6.38 5.17999 4.71 6.75999 3.61C8.33999 2.52 10.23 2 12 2C14.44 2 17.12 2.98 18.89 5.11Z"
        fill="#171717"
      />
      <path
        opacity="0.4"
        d="M15.78 20.98C14.72 22 13.36 22.51 12 22.51C10.64 22.51 9.28001 21.99 8.22002 20.97C7.86002 20.63 7.50001 20.25 7.14001 19.86L20.04 6.95996C20.29 7.49996 20.48 8.06996 20.62 8.69996C21.79 13.86 18.63 18.22 15.78 20.98Z"
        fill="#171717"
      />
      <path
        d="M21.7701 2.23006C21.4701 1.92006 20.9901 1.92006 20.6901 2.23006L15.0201 7.90006L14.3001 8.62006C13.7601 7.88006 12.9101 7.44006 12.0001 7.44006C10.4201 7.44006 9.13006 8.73006 9.13006 10.3101C9.13006 11.2101 9.57006 12.0601 10.3101 12.6101L2.23006 20.6901C1.92006 20.9901 1.92006 21.4701 2.23006 21.7701C2.38006 21.9201 2.57006 22.0001 2.77006 22.0001C2.97006 22.0001 3.16006 21.9201 3.31006 21.7701L21.7701 3.31006C22.0801 3.01006 22.0801 2.53006 21.7701 2.23006Z"
        fill="#171717"
      />
    </svg>
    <h4 class="font-semibold text-292 text-xl text-center">Nenhum endereço cadastrado</h4>
    <p class="text-zinc-400 text-center text-sm">Clique em criar endereço para cadastrar</p>
  </div>
  <table v-else class="w-full">
    <thead class="hidden sm:grid grid-cols-7 p-4">
      <th class="col-span-1">CEP</th>
      <th class="col-span-1">País</th>
      <th class="col-span-1">Estado</th>
      <th class="col-span-1">Municipio</th>
      <th class="col-span-1">Logradouro</th>
      <th class="col-span-1">Número</th>
      <th class="col-span-1">Ações</th>
    </thead>
    <tbody class="flex flex-col gap-4">
      <tr v-for="address in addresses" class="grid grid-cols-7 border rounded-xl p-4">
        <td class="col-span-3 sm:col-span-1">{{ address.postcode }}</td>
        <td class="col-span-3 sm:col-span-1">{{ address.country }}</td>
        <td class="col-span-3 sm:col-span-1">{{ address.state }}</td>
        <td class="col-span-3 sm:col-span-1">{{ address.city }}</td>
        <td class="col-span-3 sm:col-span-1">{{ address.street }}</td>
        <td class="col-span-3 sm:col-span-1">{{ address.number }}</td>
        <td class="col-span-1 sm:col-span-1 flex gap-4">
          <button
            
            @click="
              isEditAddressModalOpen = true;
              addressToUpdate = { ...address };
            "
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path
                d="M11.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V13"
                stroke="#171717"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="hover:parent:stroke-primary transition-all duration-200"
              />
              <path
                d="M16.5399 3.02001L8.65988 10.9C8.35988 11.2 8.05988 11.79 7.99988 12.22L7.56988 15.23C7.40988 16.32 8.17988 17.08 9.26988 16.93L12.2799 16.5C12.6999 16.44 13.2899 16.14 13.5999 15.84L21.4799 7.96001C22.8399 6.60001 23.4799 5.02001 21.4799 3.02001C19.4799 1.02001 17.8999 1.66001 16.5399 3.02001Z"
                stroke="#171717"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="hover:parent:stroke-primary transition-all duration-200"
              />
              <path
                d="M15.4099 4.15002C16.0799 6.54002 17.9499 8.41002 20.3499 9.09002"
                stroke="#171717"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="hover:parent:stroke-primary transition-all duration-200"
              />
            </svg>
          </button>
          <button class="hover:stroke-primary" @click="isConfirmDeleteModalOpen = true; addressToUpdate = address">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill='none' d="M21 5.98C17.67 5.65 14.32 5.48 10.98 5.48C9 5.48 7.02 5.58 5.04 5.78L3 5.98M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97M18.85 9.14L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79C6 22 5.91 20.78 5.8 19.21L5.15 9.14M10.33 16.5H13.66M9.5 12.5H14.5" stroke="#292F31" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary transition-all duration-200" />
            </svg>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <MyModal :height="'42.5rem'" :width="'40rem'" :isOpen="isCreateAddressModalOpen" :toggleModal="(v: boolean) => (isCreateAddressModalOpen = v)">
    <div class="p-4 sm:p-8">
      <div class="flex justify-between items-start">
        <div>
          <h4 class="font-bold text-2xl">Criar endereço</h4>
          <p class="mb-6 text-94 text-sm">Campos com (*) são obrigatórios</p>
        </div>
        <button class="transition duration-200 hover:text-primary" @click="isCreateAddressModalOpen = false">
          <svg viewBox="0 0 24 24" role="presentation" style="width: 1.5rem; height: 1.5rem">
            <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" style="fill: currentcolor"></path>
          </svg>
        </button>
      </div>
      <div class="grid grid-cols-12 gap-6 rounded-xl">
        <div class="flex flex-col gap-2 col-span-6">
          <label for="" class="font-semibold text-lg input-primary">CEP</label>
          <input type="text" v-model="addressForm.postcode" placeholder="ex: 6078643" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <!-- <div class="col-span-6 flex flex-col gap-2">
                    <label for="" class="font-semibold text-lg">País</label>
                    <MySelect :id="'country'" v-model="addressForm.country" placeholder="Selecione" :disabled="true" />
                </div> -->
        <div class="col-span-6 flex flex-col gap-2">
          <label for="" class="font-semibold text-lg">Estado</label>
          <input type="text" v-model="addressForm.state" placeholder="ex: Rio de Janeiro" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <div class="col-span-6 flex flex-col gap-2">
          <label for="" class="font-semibold text-lg">Municipio</label>
          <input type="text" v-model="addressForm.city" placeholder="ex: Fortaleza" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <div class="flex flex-col gap-2 col-span-6">
          <label for="" class="font-semibold text-lg input-primary">Logradouro</label>
          <input type="text" v-model="addressForm.street" placeholder="ex: Martins Correia" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <div class="flex flex-col gap-2 col-span-6">
          <label for="" class="font-semibold text-lg input-primary">Número</label>
          <input type="text" placeholder="ex: 4067" v-model="addressForm.number" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <div class="flex flex-col gap-2 col-span-6">
          <label for="" class="font-semibold text-lg input-primary">Complemeto</label>
          <input type="text" placeholder="ex: Alto" v-model="addressForm.complement" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <!-- <div class="flex flex-col gap-2 col-span-3">
                    <label for="" class="font-semibold text-lg input-primary">Referência</label>
                    <input type="text" placeholder="ex: Ao lado da padaria" class="border border-d5 p-3 rounded-xl w-full input-primary">
                </div> -->
        <div class="flex flex-col gap-2 col-span-6">
          <label for="" class="font-semibold text-lg input-primary">Bairro</label>
          <input type="text" placeholder="ex: Aldeota" v-model="addressForm.district" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
      </div>
      <button :disabled="!addressForm.postcode || !addressForm.state || !addressForm.city || !addressForm.street || !addressForm.number || !addressForm.district || !addressForm.complement" @click="createAddress" class="bg-primary px-8 py-3 text-white flex items-center gap-2 rounded-3xl font-bold mt-12 w-full justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M14.75 11.75H9.25C8.84 11.75 8.5 11.41 8.5 11C8.5 10.59 8.84 10.25 9.25 10.25H14.75C15.16 10.25 15.5 10.59 15.5 11C15.5 11.41 15.16 11.75 14.75 11.75Z" fill="#fff" />
          <path d="M12 14.5C11.59 14.5 11.25 14.16 11.25 13.75V8.25C11.25 7.84 11.59 7.5 12 7.5C12.41 7.5 12.75 7.84 12.75 8.25V13.75C12.75 14.16 12.41 14.5 12 14.5Z" fill="#fff" />
          <path
            d="M12.0001 22.76C10.5201 22.76 9.03005 22.2 7.87005 21.09C4.92005 18.25 1.66005 13.72 2.89005 8.33C4.00005 3.44 8.27005 1.25 12.0001 1.25C12.0001 1.25 12.0001 1.25 12.0101 1.25C15.7401 1.25 20.0101 3.44 21.1201 8.34C22.3401 13.73 19.0801 18.25 16.1301 21.09C14.9701 22.2 13.4801 22.76 12.0001 22.76ZM12.0001 2.75C9.09005 2.75 5.35005 4.3 4.36005 8.66C3.28005 13.37 6.24005 17.43 8.92005 20C10.6501 21.67 13.3601 21.67 15.0901 20C17.7601 17.43 20.7201 13.37 19.6601 8.66C18.6601 4.3 14.9101 2.75 12.0001 2.75Z"
            fill="#fff"
          />
        </svg>
        Criar endereço
      </button>
    </div>
  </MyModal>
  <MyModal :height="'42.5rem'" :width="'40rem'" :isOpen="isEditAddressModalOpen" :toggleModal="(v: boolean) => (isEditAddressModalOpen = v)">
    <div class="p-4 sm:p-8">
      <div class="flex justify-between items-start">
        <div>
          <h4 class="font-bold text-2xl">Editar endereço</h4>
          <p class="mb-6 text-94 text-sm">Campos com (*) são obrigatórios</p>
        </div>
        <button class="transition duration-200 hover:text-primary" @click="isEditAddressModalOpen = false">
          <svg viewBox="0 0 24 24" role="presentation" style="width: 1.5rem; height: 1.5rem">
            <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" style="fill: currentcolor"></path>
          </svg>
        </button>
      </div>
      <div class="grid grid-cols-12 gap-6 rounded-xl">
        <div class="flex flex-col gap-2 col-span-6">
          <label for="" class="font-semibold text-lg input-primary">CEP</label>
          <input type="text" v-model="addressToUpdate.postcode" placeholder="ex: 6078643" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <!-- <div class="col-span-6 flex flex-col gap-2">
                    <label for="" class="font-semibold text-lg">País</label>
                    <MySelect :id="'country'" v-model="addressForm.country" placeholder="Selecione" :disabled="true" />
                </div> -->
        <div class="col-span-6 flex flex-col gap-2">
          <label for="" class="font-semibold text-lg">Estado</label>
          <input type="text" v-model="addressToUpdate.state" placeholder="ex: Rio de Janeiro" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <div class="col-span-6 flex flex-col gap-2">
          <label for="" class="font-semibold text-lg">Municipio</label>
          <input type="text" v-model="addressToUpdate.city" placeholder="ex: Fortaleza" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <div class="flex flex-col gap-2 col-span-6">
          <label for="" class="font-semibold text-lg input-primary">Logradouro</label>
          <input type="text" v-model="addressToUpdate.street" placeholder="ex: Martins Correia" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <div class="flex flex-col gap-2 col-span-6">
          <label for="" class="font-semibold text-lg input-primary">Número</label>
          <input type="text" placeholder="ex: 4067" v-model="addressToUpdate.number" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <div class="flex flex-col gap-2 col-span-6">
          <label for="" class="font-semibold text-lg input-primary">Complemeto</label>
          <input type="text" placeholder="ex: Alto" v-model="addressToUpdate.complement" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <!-- <div class="flex flex-col gap-2 col-span-3">
                    <label for="" class="font-semibold text-lg input-primary">Referência</label>
                    <input type="text" placeholder="ex: Ao lado da padaria" class="border border-d5 p-3 rounded-xl w-full input-primary">
                </div> -->
        <div class="flex flex-col gap-2 col-span-6">
          <label for="" class="font-semibold text-lg input-primary">Bairro</label>
          <input type="text" placeholder="ex: Aldeota" v-model="addressToUpdate.district" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
      </div>
      <button :disabled="!addressToUpdate.postcode || !addressToUpdate.state || !addressToUpdate.city || !addressToUpdate.street || !addressToUpdate.number || !addressToUpdate.district || !addressToUpdate.complement" @click="updateAddress" class="bg-primary px-8 py-3 text-white flex items-center gap-2 rounded-3xl font-bold mt-12 w-full justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M3.61995 8.49C5.58995 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.38995 20.54C5.62995 17.88 2.46995 13.57 3.61995 8.49Z"
            stroke="#fff"
            stroke-width="1.5"
          />
          <path d="M9.25 11.5L10.75 13L14.75 9" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        Salvar alterações
      </button>
    </div>
  </MyModal>
  <MyModal :width="'330px !important'" :height="'260px !important'" :isOpen="isConfirmDeleteModalOpen" :class-name="'confirm-delete'" :toggleModal="(v: boolean) => isConfirmDeleteModalOpen = v" :zIndex="100">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
        <path d="M42.2722 12.6747L29.4021 5.24301C27.3005 4.02967 24.7005 4.02967 22.5771 5.24301L9.72881 12.6747C7.62715 13.888 6.32715 16.1413 6.32715 18.5897V33.4097C6.32715 35.8363 7.62715 38.0897 9.72881 39.3247L22.5988 46.7563C24.7005 47.9697 27.3005 47.9697 29.4238 46.7563L42.2938 39.3247C44.3955 38.1113 45.6955 35.858 45.6955 33.4097V18.5897C45.6738 16.1413 44.3738 13.9097 42.2722 12.6747ZM24.3755 16.7913C24.3755 15.903 25.1121 15.1663 26.0005 15.1663C26.8888 15.1663 27.6255 15.903 27.6255 16.7913V28.1663C27.6255 29.0547 26.8888 29.7913 26.0005 29.7913C25.1121 29.7913 24.3755 29.0547 24.3755 28.1663V16.7913ZM27.9938 36.0313C27.8855 36.2913 27.7338 36.5297 27.5388 36.7463C27.1271 37.158 26.5855 37.3747 26.0005 37.3747C25.7188 37.3747 25.4371 37.3097 25.1771 37.2013C24.8955 37.093 24.6788 36.9413 24.4621 36.7463C24.2671 36.5297 24.1155 36.2913 23.9855 36.0313C23.8771 35.7713 23.8338 35.4897 23.8338 35.208C23.8338 34.6447 24.0505 34.0813 24.4621 33.6697C24.6788 33.4747 24.8955 33.323 25.1771 33.2147C25.9788 32.868 26.9321 33.063 27.5388 33.6697C27.7338 33.8863 27.8855 34.103 27.9938 34.3847C28.1021 34.6447 28.1671 34.9263 28.1671 35.208C28.1671 35.4897 28.1021 35.7713 27.9938 36.0313Z" fill="#A30711"/>
      </svg>
      <h1>Cuidado!</h1>
      <h2>Deseja mesmo deletar esse endereço?</h2>
      <section>
        <input autocomplete='off' @click="isConfirmDeleteModalOpen = false; deleteAddress();" type="button" value="Sim">
        <button @click="isConfirmDeleteModalOpen = false;">Cancelar</button>
      </section>
    </div>
  </MyModal>
</template>

<script setup lang="ts">
  import { defineProps, ref, onMounted, watch } from 'vue'
  import MyModal from '@/components/modal/MyModal.vue'
  import axiosClient from '@/api/axios'
  import { useToast } from 'vue-toastification'
  import IAddress from '@/interfaces/organization/IAddress'

  const props = defineProps<{ organizationId: number; document: string }>()
  const addressForm = ref<Partial<IAddress>>({})
  const isCreateAddressModalOpen = ref<boolean>(false)
  const isEditAddressModalOpen = ref<boolean>(false)
  const isConfirmDeleteModalOpen = ref<boolean>(false)
  const addresses = ref<Partial<IAddress>[]>()
  const addressToUpdate = ref<Partial<IAddress>>({})
  const toast = useToast()

  function getAddresses() {
    axiosClient.get(`/organizations/${props.organizationId}/addresses`).then((res) => {
      addresses.value = res.data
    })
  }

  function createAddress() {
    axiosClient
      .post(`/organizations/${props.organizationId}/addresses`, {
        ...addressForm.value,
        country: 'Brazil',
      })
      .then((res) => {
        if (!addresses.value) {
          addresses.value = []
        }
        addresses.value?.push(res.data)
        isCreateAddressModalOpen.value = false
        addressForm.value = {}
        toast.success('Endereço criado com sucesso')
      })
  }

  function updateAddress() {
    axiosClient
      .put(`/organizations/${props.organizationId}/addresses/${addressToUpdate.value.id}`, {
        ...addressToUpdate.value,
      })
      .then((res) => {
        const updatedAddressIndex = addresses.value!.findIndex((adr) => adr.id === addressToUpdate.value.id)
        addresses.value![updatedAddressIndex] = addressToUpdate.value
        isEditAddressModalOpen.value = false
        toast.success('Endereço editado com sucesso')
      })
  }

  function deleteAddress() {
    axiosClient.delete(`/organizations/${props.organizationId}/addresses/${addressToUpdate.value.id}`)
      .then(() => {
        addresses.value = addresses.value?.filter((adr) => adr.id !== addressToUpdate.value.id)
        toast.success('Endereço deletado com sucesso')
      })
  }

  onMounted(() => {
    getAddresses()
  })
</script>
