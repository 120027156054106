import axiosClient from '@/api/axios'
import { ref } from 'vue'

const productFormats = ref<any[]>(JSON.parse(localStorage.getItem('productFormats') as string) ?? [])
const productCategories = ref<any[]>(JSON.parse(localStorage.getItem('productCategories') as string) ?? [])

export const useHoopay = () => {
  function listproductFormats() {
    if (!localStorage.getItem('productFormats')) {
      axiosClient
        .get('/static/products/formats')
        .then((resp) => {
          const result = resp.data

          if (result.length > 0) {
            productFormats.value = result
            localStorage.setItem('productFormats', JSON.stringify(result))
          }
        })
        .catch((error: any) => {
          console.error('Erro ao listar formatos:', error)
        })
    }
  }

  function listProductCategories(organizationId: number) {
    if (!localStorage.getItem('productCategories')) {
      axiosClient
        .get(`/organizations/${organizationId}/categories`)
        .then((resp) => {
          const result = resp.data

          if (result.length > 0) {
            productCategories.value = result
            localStorage.setItem('productCategories', JSON.stringify(result))
          }
        })
        .catch((error: any) => {
          console.error('Erro ao listar categorias:', error)
        })
    }
  }

  return {
    productFormats,
    listproductFormats,
    productCategories,
    listProductCategories,
  }
}
