import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "name", "value"]

import { ref } from 'vue'

  
export default /*@__PURE__*/_defineComponent({
  __name: 'FxSearchSelect',
  props: {
    name: {},
    currentValue: {},
    for: {}
  },
  emits: ["update:modelValue", "blur"],
  setup(__props: any, { emit: __emit }) {

  const inputSelectRef = ref<HTMLInputElement | null>(null)

  const props = __props;

  const emit = __emit;

  const onInputChange = (event: Event) => {
    const target = event.target as HTMLInputElement
    const newValue = target.value
    emit('update:modelValue', newValue, props.name)
  }

  const onBlur = (event: Event) => {
    const target = event.target as HTMLInputElement
    const value = target.value
    emit('blur', value);
  }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", {
    class: "mySelect",
    for: props.name || ''
  }, [
    _createElementVNode("input", {
      ref_key: "inputSelectRef",
      ref: inputSelectRef,
      type: "text",
      placeholder: "Selecione",
      autocomplete: "off",
      onInput: onInputChange,
      onBlur: onBlur,
      id: props.name,
      name: props.name,
      value: _ctx.currentValue
    }, null, 40, _hoisted_2),
    _cache[0] || (_cache[0] = _createElementVNode("span", null, null, -1)),
    _createElementVNode("div", null, [
      _createElementVNode("ul", null, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ], 8, _hoisted_1))
}
}

})