import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-10 gap-6 pb-8" }
const _hoisted_2 = { class: "col-span-12 sm:col-span-2 border rounded-xl p-4 flex flex-col gap-4 items-center" }
const _hoisted_3 = { class: "bg-f5 rounded-full p-4" }
const _hoisted_4 = {
  style: {"width":"2.5rem","height":"2.5rem"},
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none"
}
const _hoisted_5 = { class: "flex items-center gap-2" }
const _hoisted_6 = { class: "col-span-12 sm:col-span-2 border rounded-xl p-4 flex flex-col gap-4 items-center" }
const _hoisted_7 = { class: "bg-f5 rounded-full p-4" }
const _hoisted_8 = {
  style: {"width":"2.5rem","height":"2.5rem"},
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none"
}
const _hoisted_9 = { class: "flex items-center gap-2" }
const _hoisted_10 = { class: "col-span-12 sm:col-span-2 border rounded-xl p-4 flex flex-col gap-4 items-center" }
const _hoisted_11 = { class: "bg-f5 rounded-full p-4" }
const _hoisted_12 = {
  style: {"width":"2.5rem","height":"2.5rem"},
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none"
}
const _hoisted_13 = { class: "flex items-center gap-2" }
const _hoisted_14 = { class: "col-span-12 sm:col-span-2 border rounded-xl p-4 flex flex-col gap-4 items-center" }
const _hoisted_15 = { class: "bg-f5 rounded-full p-4" }
const _hoisted_16 = {
  style: {"width":"2.5rem","height":"2.5rem"},
  xmlns: "http://www.w3.org/2000/svg",
  width: "25",
  height: "24",
  viewBox: "0 0 25 24",
  fill: "none"
}
const _hoisted_17 = { class: "flex items-center gap-2" }
const _hoisted_18 = { class: "col-span-12 sm:col-span-2 border rounded-xl p-4 flex flex-col gap-4 items-center" }
const _hoisted_19 = { class: "bg-f5 rounded-full p-4" }
const _hoisted_20 = {
  style: {"width":"2.5rem","height":"2.5rem"},
  xmlns: "http://www.w3.org/2000/svg",
  width: "25",
  height: "24",
  viewBox: "0 0 25 24",
  fill: "none"
}
const _hoisted_21 = { class: "flex items-center gap-2" }
const _hoisted_22 = { class: "col-span-12 sm:col-span-2 border rounded-xl p-4 flex flex-col gap-4 items-center" }
const _hoisted_23 = { class: "bg-f5 rounded-full p-4" }
const _hoisted_24 = {
  viewBox: "0 0 24 24",
  role: "presentation",
  style: {"width":"2.5rem","height":"2.5rem"}
}
const _hoisted_25 = { class: "flex items-center gap-2" }
const _hoisted_26 = { class: "border col-span-12 sm:col-span-2 rounded-xl p-4 flex flex-col gap-4 items-center" }
const _hoisted_27 = { class: "bg-f5 rounded-full p-4" }
const _hoisted_28 = {
  style: {"width":"2.5rem","height":"2.5rem"},
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none"
}
const _hoisted_29 = { class: "flex items-center gap-2" }
const _hoisted_30 = { class: "border col-span-12 sm:col-span-2 rounded-xl p-4 flex flex-col gap-4 items-center" }
const _hoisted_31 = { class: "bg-f5 rounded-full p-4" }
const _hoisted_32 = {
  style: {"width":"2.5rem","height":"2.5rem"},
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none"
}
const _hoisted_33 = { class: "p-4" }
const _hoisted_34 = {
  viewBox: "0 0 24 24",
  role: "presentation",
  style: {"width":"1.5rem","height":"1.5rem"}
}
const _hoisted_35 = { class: "p-8" }
const _hoisted_36 = { class: "mb-8 flex items-center relative" }
const _hoisted_37 = {
  viewBox: "0 0 24 24",
  role: "presentation",
  style: {"width":"1.5rem","height":"1.5rem"}
}
const _hoisted_38 = { class: "grid grid-cols-12 gap-6" }
const _hoisted_39 = { class: "col-span-12" }
const _hoisted_40 = { class: "border p-8 rounded-xl" }
const _hoisted_41 = {
  id: "table-query-api",
  class: "w-full text-left"
}
const _hoisted_42 = { class: "flex items-center gap-2 mb-4" }
const _hoisted_43 = { class: "flex items-center gap-2 mb-4" }
const _hoisted_44 = { class: "flex items-center gap-2 mb-4" }
const _hoisted_45 = { class: "flex items-center gap-2 mb-4" }
const _hoisted_46 = { class: "flex items-center gap-2 mb-4" }
const _hoisted_47 = { class: "flex items-center gap-2 mb-4" }
const _hoisted_48 = { class: "flex items-center gap-2 mb-4" }
const _hoisted_49 = { class: "flex items-center gap-2 mb-4" }
const _hoisted_50 = { class: "text-left" }
const _hoisted_51 = { class: "flex items-center gap-2" }

import { ref } from 'vue'
  import MyModal from '@/components/modal/MyModal.vue'
  import axiosClient from '@/api/axios'

  
export default /*@__PURE__*/_defineComponent({
  __name: 'CompliancesTab',
  props: {
    organization: {}
  },
  setup(__props: any) {

  const props = __props
  const isNotesModalOpen = ref<boolean>(false)
  const isVerifyCnpjModalOpen = ref<boolean>(false)
  const cnpjQueryResult = ref<any>({})
  const complianceForm: any = ref({})

  function verifyCnpj() {
    console.log('verify cnpj')
    isVerifyCnpjModalOpen.value = true
    if (!cnpjQueryResult.value.estabelecimento) {
      axiosClient.get(`https://publica.cnpj.ws/cnpj/${props.organization.document}`).then((res: any) => {
        console.log(res)
        cnpjQueryResult.value = res.data
      })
    }
  }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(), _createElementBlock("svg", _hoisted_4, _cache[16] || (_cache[16] = [
            _createStaticVNode("<path d=\"M2 22H22\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M2.9502 22L3.00019 9.96999C3.00019 9.35999 3.2902 8.78004 3.7702 8.40004L10.7702 2.95003C11.4902 2.39003 12.5002 2.39003 13.2302 2.95003L20.2302 8.39003C20.7202 8.77003 21.0002 9.34999 21.0002 9.96999V22\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M13 17H11C10.17 17 9.5 17.67 9.5 18.5V22H14.5V18.5C14.5 17.67 13.83 17 13 17Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M9.5 13.75H7.5C6.95 13.75 6.5 13.3 6.5 12.75V11.25C6.5 10.7 6.95 10.25 7.5 10.25H9.5C10.05 10.25 10.5 10.7 10.5 11.25V12.75C10.5 13.3 10.05 13.75 9.5 13.75Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M16.5 13.75H14.5C13.95 13.75 13.5 13.3 13.5 12.75V11.25C13.5 10.7 13.95 10.25 14.5 10.25H16.5C17.05 10.25 17.5 10.7 17.5 11.25V12.75C17.5 13.3 17.05 13.75 16.5 13.75Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M18.9998 7L18.9698 4H14.5698\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path>", 6)
          ])))
        ]),
        _cache[19] || (_cache[19] = _createElementVNode("h6", null, "Residência", -1)),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (isNotesModalOpen.value = true)),
            class: "bg-success text-white p-2 py-1 rounded-lg w-full text-center flex items-center gap-2 text-xs"
          }, _cache[17] || (_cache[17] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "20",
              height: "20",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M10.5799 15.5801C10.3799 15.5801 10.1899 15.5001 10.0499 15.3601L7.21994 12.5301C6.92994 12.2401 6.92994 11.7601 7.21994 11.4701C7.50994 11.1801 7.98994 11.1801 8.27994 11.4701L10.5799 13.7701L15.7199 8.6301C16.0099 8.3401 16.4899 8.3401 16.7799 8.6301C17.0699 8.9201 17.0699 9.4001 16.7799 9.6901L11.1099 15.3601C10.9699 15.5001 10.7799 15.5801 10.5799 15.5801Z",
                fill: "#fff"
              })
            ], -1),
            _createTextVNode(" Aprovar ")
          ])),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (isNotesModalOpen.value = true)),
            class: "bg-red-400 text-white p-2 py-1 rounded-lg w-full text-center flex items-center gap-2 text-xs"
          }, _cache[18] || (_cache[18] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "20",
              height: "20",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M9.16986 15.5801C8.97986 15.5801 8.78986 15.5101 8.63986 15.3601C8.34986 15.0701 8.34986 14.5901 8.63986 14.3001L14.2999 8.64011C14.5899 8.35011 15.0699 8.35011 15.3599 8.64011C15.6499 8.93011 15.6499 9.41011 15.3599 9.70011L9.69986 15.3601C9.55986 15.5101 9.35986 15.5801 9.16986 15.5801Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M14.8299 15.5801C14.6399 15.5801 14.4499 15.5101 14.2999 15.3601L8.63986 9.70011C8.34986 9.41011 8.34986 8.93011 8.63986 8.64011C8.92986 8.35011 9.40986 8.35011 9.69986 8.64011L15.3599 14.3001C15.6499 14.5901 15.6499 15.0701 15.3599 15.3601C15.2099 15.5101 15.0199 15.5801 14.8299 15.5801Z",
                fill: "#fff"
              })
            ], -1),
            _createTextVNode(" Recusar ")
          ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(), _createElementBlock("svg", _hoisted_8, _cache[20] || (_cache[20] = [
            _createStaticVNode("<path d=\"M12.37 2.15009L21.37 5.75006C21.72 5.89006 22 6.31006 22 6.68006V10.0001C22 10.5501 21.55 11.0001 21 11.0001H3C2.45 11.0001 2 10.5501 2 10.0001V6.68006C2 6.31006 2.28 5.89006 2.63 5.75006L11.63 2.15009C11.83 2.07009 12.17 2.07009 12.37 2.15009Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M22 22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19V22Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M4 18V11\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M8 18V11\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M12 18V11\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M16 18V11\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M20 18V11\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M1 22H23\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M12 8.5C12.8284 8.5 13.5 7.82843 13.5 7C13.5 6.17157 12.8284 5.5 12 5.5C11.1716 5.5 10.5 6.17157 10.5 7C10.5 7.82843 11.1716 8.5 12 8.5Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path>", 9)
          ])))
        ]),
        _cache[23] || (_cache[23] = _createElementVNode("h6", null, "Domicílio Bancário", -1)),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (isNotesModalOpen.value = true)),
            class: "bg-success text-white p-2 py-1 rounded-lg w-full text-center flex items-center gap-2 text-xs"
          }, _cache[21] || (_cache[21] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "20",
              height: "20",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M10.5799 15.5801C10.3799 15.5801 10.1899 15.5001 10.0499 15.3601L7.21994 12.5301C6.92994 12.2401 6.92994 11.7601 7.21994 11.4701C7.50994 11.1801 7.98994 11.1801 8.27994 11.4701L10.5799 13.7701L15.7199 8.6301C16.0099 8.3401 16.4899 8.3401 16.7799 8.6301C17.0699 8.9201 17.0699 9.4001 16.7799 9.6901L11.1099 15.3601C10.9699 15.5001 10.7799 15.5801 10.5799 15.5801Z",
                fill: "#fff"
              })
            ], -1),
            _createTextVNode(" Aprovar ")
          ])),
          _createElementVNode("button", {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (isNotesModalOpen.value = true)),
            class: "bg-red-400 text-white p-2 py-1 rounded-lg w-full text-center flex items-center gap-2 text-xs"
          }, _cache[22] || (_cache[22] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "20",
              height: "20",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M9.16986 15.5801C8.97986 15.5801 8.78986 15.5101 8.63986 15.3601C8.34986 15.0701 8.34986 14.5901 8.63986 14.3001L14.2999 8.64011C14.5899 8.35011 15.0699 8.35011 15.3599 8.64011C15.6499 8.93011 15.6499 9.41011 15.3599 9.70011L9.69986 15.3601C9.55986 15.5101 9.35986 15.5801 9.16986 15.5801Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M14.8299 15.5801C14.6399 15.5801 14.4499 15.5101 14.2999 15.3601L8.63986 9.70011C8.34986 9.41011 8.34986 8.93011 8.63986 8.64011C8.92986 8.35011 9.40986 8.35011 9.69986 8.64011L15.3599 14.3001C15.6499 14.5901 15.6499 15.0701 15.3599 15.3601C15.2099 15.5101 15.0199 15.5801 14.8299 15.5801Z",
                fill: "#fff"
              })
            ], -1),
            _createTextVNode(" Recusar ")
          ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          (_openBlock(), _createElementBlock("svg", _hoisted_12, _cache[24] || (_cache[24] = [
            _createElementVNode("path", {
              d: "M15.81 20.18C15.55 20.18 15.28 20.17 14.99 20.14C14.47 20.1 13.88 20 13.27 19.85L11.59 19.45C6.98 18.36 5.47 15.92 6.55 11.32L7.53 7.13001C7.75 6.18002 8.01 5.41002 8.33 4.77002C10.05 1.22002 13.34 1.54001 15.68 2.09001L17.35 2.48001C19.69 3.03001 21.17 3.90001 22 5.23002C22.82 6.56002 22.95 8.27001 22.4 10.61L21.42 14.79C20.56 18.45 18.77 20.18 15.81 20.18ZM13.12 3.25001C11.45 3.25001 10.39 3.94002 9.68 5.42002C9.42 5.96002 9.19 6.63001 8.99 7.47001L8.01 11.66C7.12 15.44 8.15 17.09 11.93 17.99L13.61 18.39C14.15 18.52 14.66 18.6 15.12 18.64C17.84 18.91 19.19 17.72 19.95 14.45L20.93 10.27C21.38 8.34002 21.32 6.99002 20.72 6.02001C20.12 5.05001 18.94 4.39002 17 3.94002L15.33 3.55001C14.5 3.35001 13.76 3.25001 13.12 3.25001Z",
              fill: "#171717",
              class: "hover:parent:fill-primary"
            }, null, -1),
            _createElementVNode("path", {
              d: "M8.33 22.25C5.76 22.25 4.12 20.71 3.07 17.46L1.79 13.51C0.369998 9.10999 1.64 6.62999 6.02 5.20999L7.6 4.69999C8.12 4.53999 8.51 4.42999 8.86 4.36999C9.15 4.30999 9.43 4.41999 9.6 4.64999C9.77 4.87999 9.8 5.17999 9.68 5.43999C9.42 5.96999 9.19 6.63999 9 7.47999L8.02 11.67C7.13 15.45 8.16 17.1 11.94 18L13.62 18.4C14.16 18.53 14.67 18.61 15.13 18.65C15.45 18.68 15.71 18.9 15.8 19.21C15.88 19.52 15.76 19.84 15.5 20.02C14.84 20.47 14.01 20.85 12.96 21.19L11.38 21.71C10.23 22.07 9.23 22.25 8.33 22.25ZM7.78 6.21999L6.49 6.63999C2.92 7.78999 2.07 9.46999 3.22 13.05L4.5 17C5.66 20.57 7.34 21.43 10.91 20.28L12.49 19.76C12.55 19.74 12.6 19.72 12.66 19.7L11.6 19.45C6.99 18.36 5.48 15.92 6.56 11.32L7.54 7.12999C7.61 6.80999 7.69 6.49999 7.78 6.21999Z",
              fill: "#171717",
              class: "hover:parent:fill-primary"
            }, null, -1),
            _createElementVNode("path", {
              d: "M17.49 10.51C17.43 10.51 17.37 10.5 17.3 10.49L12.45 9.26002C12.05 9.16002 11.81 8.75002 11.91 8.35002C12.01 7.95002 12.42 7.71002 12.82 7.81002L17.67 9.04002C18.07 9.14002 18.31 9.55002 18.21 9.95002C18.13 10.28 17.82 10.51 17.49 10.51Z",
              fill: "#171717",
              class: "hover:parent:fill-primary"
            }, null, -1),
            _createElementVNode("path", {
              d: "M14.56 13.89C14.5 13.89 14.44 13.88 14.37 13.87L11.46 13.13C11.06 13.03 10.82 12.62 10.92 12.22C11.02 11.82 11.43 11.58 11.83 11.68L14.74 12.42C15.14 12.52 15.38 12.93 15.28 13.33C15.2 13.67 14.9 13.89 14.56 13.89Z",
              fill: "#171717",
              class: "hover:parent:fill-primary"
            }, null, -1)
          ])))
        ]),
        _cache[27] || (_cache[27] = _createElementVNode("h6", null, "Contrato Social", -1)),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("button", {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (isNotesModalOpen.value = true)),
            class: "bg-success text-white p-2 py-1 rounded-lg w-full text-center flex items-center gap-2 text-xs"
          }, _cache[25] || (_cache[25] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "20",
              height: "20",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M10.5799 15.5801C10.3799 15.5801 10.1899 15.5001 10.0499 15.3601L7.21994 12.5301C6.92994 12.2401 6.92994 11.7601 7.21994 11.4701C7.50994 11.1801 7.98994 11.1801 8.27994 11.4701L10.5799 13.7701L15.7199 8.6301C16.0099 8.3401 16.4899 8.3401 16.7799 8.6301C17.0699 8.9201 17.0699 9.4001 16.7799 9.6901L11.1099 15.3601C10.9699 15.5001 10.7799 15.5801 10.5799 15.5801Z",
                fill: "#fff"
              })
            ], -1),
            _createTextVNode(" Aprovar ")
          ])),
          _createElementVNode("button", {
            onClick: _cache[5] || (_cache[5] = ($event: any) => (isNotesModalOpen.value = true)),
            class: "bg-red-400 text-white p-2 py-1 rounded-lg w-full text-center flex items-center gap-2 text-xs"
          }, _cache[26] || (_cache[26] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "20",
              height: "20",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M9.16986 15.5801C8.97986 15.5801 8.78986 15.5101 8.63986 15.3601C8.34986 15.0701 8.34986 14.5901 8.63986 14.3001L14.2999 8.64011C14.5899 8.35011 15.0699 8.35011 15.3599 8.64011C15.6499 8.93011 15.6499 9.41011 15.3599 9.70011L9.69986 15.3601C9.55986 15.5101 9.35986 15.5801 9.16986 15.5801Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M14.8299 15.5801C14.6399 15.5801 14.4499 15.5101 14.2999 15.3601L8.63986 9.70011C8.34986 9.41011 8.34986 8.93011 8.63986 8.64011C8.92986 8.35011 9.40986 8.35011 9.69986 8.64011L15.3599 14.3001C15.6499 14.5901 15.6499 15.0701 15.3599 15.3601C15.2099 15.5101 15.0199 15.5801 14.8299 15.5801Z",
                fill: "#fff"
              })
            ], -1),
            _createTextVNode(" Recusar ")
          ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          (_openBlock(), _createElementBlock("svg", _hoisted_16, _cache[28] || (_cache[28] = [
            _createStaticVNode("<path d=\"M17.0156 21H7.01562C3.01562 21 2.01562 20 2.01562 16V8C2.01562 4 3.01562 3 7.01562 3H17.0156C21.0156 3 22.0156 4 22.0156 8V16C22.0156 20 21.0156 21 17.0156 21Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M14.0156 8H19.0156\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M15.0156 12H19.0156\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M17.0156 16H19.0156\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M8.51557 11.29C9.5152 11.29 10.3256 10.4797 10.3256 9.48004C10.3256 8.48041 9.5152 7.67004 8.51557 7.67004C7.51593 7.67004 6.70557 8.48041 6.70557 9.48004C6.70557 10.4797 7.51593 11.29 8.51557 11.29Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M12.0156 16.33C11.8756 14.88 10.7256 13.74 9.27563 13.61C8.77563 13.56 8.26562 13.56 7.75562 13.61C6.30562 13.75 5.15563 14.88 5.01562 16.33\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path>", 6)
          ])))
        ]),
        _cache[31] || (_cache[31] = _createElementVNode("h6", null, "Documento Sócio - CNH", -1)),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("button", {
            onClick: _cache[6] || (_cache[6] = ($event: any) => (isNotesModalOpen.value = true)),
            class: "bg-success text-white p-2 py-1 rounded-lg w-full text-center flex items-center gap-2 text-xs"
          }, _cache[29] || (_cache[29] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "20",
              height: "20",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M10.5799 15.5801C10.3799 15.5801 10.1899 15.5001 10.0499 15.3601L7.21994 12.5301C6.92994 12.2401 6.92994 11.7601 7.21994 11.4701C7.50994 11.1801 7.98994 11.1801 8.27994 11.4701L10.5799 13.7701L15.7199 8.6301C16.0099 8.3401 16.4899 8.3401 16.7799 8.6301C17.0699 8.9201 17.0699 9.4001 16.7799 9.6901L11.1099 15.3601C10.9699 15.5001 10.7799 15.5801 10.5799 15.5801Z",
                fill: "#fff"
              })
            ], -1),
            _createTextVNode(" Aprovar ")
          ])),
          _createElementVNode("button", {
            onClick: _cache[7] || (_cache[7] = ($event: any) => (isNotesModalOpen.value = true)),
            class: "bg-red-400 text-white p-2 py-1 rounded-lg w-full text-center flex items-center gap-2 text-xs"
          }, _cache[30] || (_cache[30] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "20",
              height: "20",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M9.16986 15.5801C8.97986 15.5801 8.78986 15.5101 8.63986 15.3601C8.34986 15.0701 8.34986 14.5901 8.63986 14.3001L14.2999 8.64011C14.5899 8.35011 15.0699 8.35011 15.3599 8.64011C15.6499 8.93011 15.6499 9.41011 15.3599 9.70011L9.69986 15.3601C9.55986 15.5101 9.35986 15.5801 9.16986 15.5801Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M14.8299 15.5801C14.6399 15.5801 14.4499 15.5101 14.2999 15.3601L8.63986 9.70011C8.34986 9.41011 8.34986 8.93011 8.63986 8.64011C8.92986 8.35011 9.40986 8.35011 9.69986 8.64011L15.3599 14.3001C15.6499 14.5901 15.6499 15.0701 15.3599 15.3601C15.2099 15.5101 15.0199 15.5801 14.8299 15.5801Z",
                fill: "#fff"
              })
            ], -1),
            _createTextVNode(" Recusar ")
          ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          (_openBlock(), _createElementBlock("svg", _hoisted_20, _cache[32] || (_cache[32] = [
            _createElementVNode("path", {
              d: "M13.03 20.4201H6.71C3.55 20.4201 2.5 18.3201 2.5 16.2101V7.79008C2.5 4.63008 3.55 3.58008 6.71 3.58008H13.03C16.19 3.58008 17.24 4.63008 17.24 7.79008V16.2101C17.24 19.3701 16.18 20.4201 13.03 20.4201Z",
              stroke: "#171717",
              "stroke-width": "1.5",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            }, null, -1),
            _createElementVNode("path", {
              d: "M20.02 17.0999L17.24 15.1499V8.83989L20.02 6.88989C21.38 5.93989 22.5 6.51989 22.5 8.18989V15.8099C22.5 17.4799 21.38 18.0599 20.02 17.0999Z",
              stroke: "#171717",
              "stroke-width": "1.5",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            }, null, -1),
            _createElementVNode("path", {
              d: "M12 11C12.8284 11 13.5 10.3284 13.5 9.5C13.5 8.67157 12.8284 8 12 8C11.1716 8 10.5 8.67157 10.5 9.5C10.5 10.3284 11.1716 11 12 11Z",
              stroke: "#171717",
              "stroke-width": "1.5",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            }, null, -1)
          ])))
        ]),
        _cache[35] || (_cache[35] = _createElementVNode("h6", null, "Vídeo - Liveness", -1)),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("button", {
            onClick: _cache[8] || (_cache[8] = ($event: any) => (isNotesModalOpen.value = true)),
            class: "bg-success text-white p-2 py-1 rounded-lg w-full text-center flex items-center gap-2 text-xs"
          }, _cache[33] || (_cache[33] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "20",
              height: "20",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M10.5799 15.5801C10.3799 15.5801 10.1899 15.5001 10.0499 15.3601L7.21994 12.5301C6.92994 12.2401 6.92994 11.7601 7.21994 11.4701C7.50994 11.1801 7.98994 11.1801 8.27994 11.4701L10.5799 13.7701L15.7199 8.6301C16.0099 8.3401 16.4899 8.3401 16.7799 8.6301C17.0699 8.9201 17.0699 9.4001 16.7799 9.6901L11.1099 15.3601C10.9699 15.5001 10.7799 15.5801 10.5799 15.5801Z",
                fill: "#fff"
              })
            ], -1),
            _createTextVNode(" Aprovar ")
          ])),
          _createElementVNode("button", {
            onClick: _cache[9] || (_cache[9] = ($event: any) => (isNotesModalOpen.value = true)),
            class: "bg-red-400 text-white p-2 py-1 rounded-lg w-full text-center flex items-center gap-2 text-xs"
          }, _cache[34] || (_cache[34] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "20",
              height: "20",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M9.16986 15.5801C8.97986 15.5801 8.78986 15.5101 8.63986 15.3601C8.34986 15.0701 8.34986 14.5901 8.63986 14.3001L14.2999 8.64011C14.5899 8.35011 15.0699 8.35011 15.3599 8.64011C15.6499 8.93011 15.6499 9.41011 15.3599 9.70011L9.69986 15.3601C9.55986 15.5101 9.35986 15.5801 9.16986 15.5801Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M14.8299 15.5801C14.6399 15.5801 14.4499 15.5101 14.2999 15.3601L8.63986 9.70011C8.34986 9.41011 8.34986 8.93011 8.63986 8.64011C8.92986 8.35011 9.40986 8.35011 9.69986 8.64011L15.3599 14.3001C15.6499 14.5901 15.6499 15.0701 15.3599 15.3601C15.2099 15.5101 15.0199 15.5801 14.8299 15.5801Z",
                fill: "#fff"
              })
            ], -1),
            _createTextVNode(" Recusar ")
          ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("div", _hoisted_23, [
          (_openBlock(), _createElementBlock("svg", _hoisted_24, _cache[36] || (_cache[36] = [
            _createElementVNode("path", {
              d: "M12 3C7.58 3 4 4.79 4 7V17C4 19.21 7.59 21 12 21S20 19.21 20 17V7C20 4.79 16.42 3 12 3M18 17C18 17.5 15.87 19 12 19S6 17.5 6 17V14.77C7.61 15.55 9.72 16 12 16S16.39 15.55 18 14.77V17M18 12.45C16.7 13.4 14.42 14 12 14C9.58 14 7.3 13.4 6 12.45V9.64C7.47 10.47 9.61 11 12 11C14.39 11 16.53 10.47 18 9.64V12.45M12 9C8.13 9 6 7.5 6 7S8.13 5 12 5C15.87 5 18 6.5 18 7S15.87 9 12 9Z",
              style: {"fill":"currentcolor"}
            }, null, -1)
          ])))
        ]),
        _cache[39] || (_cache[39] = _createElementVNode("h6", null, "Verificação BIGDATA", -1)),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("button", {
            onClick: _cache[10] || (_cache[10] = ($event: any) => (isNotesModalOpen.value = true)),
            class: "bg-success text-white p-2 py-1 rounded-lg w-full text-center flex items-center gap-2 text-xs"
          }, _cache[37] || (_cache[37] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "20",
              height: "20",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M10.5799 15.5801C10.3799 15.5801 10.1899 15.5001 10.0499 15.3601L7.21994 12.5301C6.92994 12.2401 6.92994 11.7601 7.21994 11.4701C7.50994 11.1801 7.98994 11.1801 8.27994 11.4701L10.5799 13.7701L15.7199 8.6301C16.0099 8.3401 16.4899 8.3401 16.7799 8.6301C17.0699 8.9201 17.0699 9.4001 16.7799 9.6901L11.1099 15.3601C10.9699 15.5001 10.7799 15.5801 10.5799 15.5801Z",
                fill: "#fff"
              })
            ], -1),
            _createTextVNode(" Aprovar ")
          ])),
          _createElementVNode("button", {
            onClick: _cache[11] || (_cache[11] = ($event: any) => (isNotesModalOpen.value = true)),
            class: "bg-red-400 text-white p-2 py-1 rounded-lg w-full text-center flex items-center gap-2 text-xs"
          }, _cache[38] || (_cache[38] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "20",
              height: "20",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M9.16986 15.5801C8.97986 15.5801 8.78986 15.5101 8.63986 15.3601C8.34986 15.0701 8.34986 14.5901 8.63986 14.3001L14.2999 8.64011C14.5899 8.35011 15.0699 8.35011 15.3599 8.64011C15.6499 8.93011 15.6499 9.41011 15.3599 9.70011L9.69986 15.3601C9.55986 15.5101 9.35986 15.5801 9.16986 15.5801Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M14.8299 15.5801C14.6399 15.5801 14.4499 15.5101 14.2999 15.3601L8.63986 9.70011C8.34986 9.41011 8.34986 8.93011 8.63986 8.64011C8.92986 8.35011 9.40986 8.35011 9.69986 8.64011L15.3599 14.3001C15.6499 14.5901 15.6499 15.0701 15.3599 15.3601C15.2099 15.5101 15.0199 15.5801 14.8299 15.5801Z",
                fill: "#fff"
              })
            ], -1),
            _createTextVNode(" Recusar ")
          ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("div", _hoisted_27, [
          (_openBlock(), _createElementBlock("svg", _hoisted_28, _cache[40] || (_cache[40] = [
            _createStaticVNode("<path d=\"M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M8.0001 3H9.0001C7.0501 8.84 7.0501 15.16 9.0001 21H8.0001\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M15 3C16.95 8.84 16.95 15.16 15 21\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M3 16V15C8.84 16.95 15.16 16.95 21 15V16\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path><path d=\"M3 8.99998C8.84 7.04998 15.16 7.04998 21 8.99998\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-c46b4aa8></path>", 5)
          ])))
        ]),
        _cache[43] || (_cache[43] = _createElementVNode("h6", null, "Verificação OFAC", -1)),
        _createElementVNode("div", _hoisted_29, [
          _createElementVNode("button", {
            onClick: _cache[12] || (_cache[12] = ($event: any) => (isNotesModalOpen.value = true)),
            class: "bg-success text-white p-2 py-1 rounded-lg w-full text-center flex items-center gap-2 text-xs"
          }, _cache[41] || (_cache[41] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "20",
              height: "20",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M10.5799 15.5801C10.3799 15.5801 10.1899 15.5001 10.0499 15.3601L7.21994 12.5301C6.92994 12.2401 6.92994 11.7601 7.21994 11.4701C7.50994 11.1801 7.98994 11.1801 8.27994 11.4701L10.5799 13.7701L15.7199 8.6301C16.0099 8.3401 16.4899 8.3401 16.7799 8.6301C17.0699 8.9201 17.0699 9.4001 16.7799 9.6901L11.1099 15.3601C10.9699 15.5001 10.7799 15.5801 10.5799 15.5801Z",
                fill: "#fff"
              })
            ], -1),
            _createTextVNode(" Aprovar ")
          ])),
          _createElementVNode("button", {
            onClick: _cache[13] || (_cache[13] = ($event: any) => (isNotesModalOpen.value = true)),
            class: "bg-red-400 text-white p-2 py-1 rounded-lg w-full text-center flex items-center gap-2 text-xs"
          }, _cache[42] || (_cache[42] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "20",
              height: "20",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M9.16986 15.5801C8.97986 15.5801 8.78986 15.5101 8.63986 15.3601C8.34986 15.0701 8.34986 14.5901 8.63986 14.3001L14.2999 8.64011C14.5899 8.35011 15.0699 8.35011 15.3599 8.64011C15.6499 8.93011 15.6499 9.41011 15.3599 9.70011L9.69986 15.3601C9.55986 15.5101 9.35986 15.5801 9.16986 15.5801Z",
                fill: "#fff"
              }),
              _createElementVNode("path", {
                d: "M14.8299 15.5801C14.6399 15.5801 14.4499 15.5101 14.2999 15.3601L8.63986 9.70011C8.34986 9.41011 8.34986 8.93011 8.63986 8.64011C8.92986 8.35011 9.40986 8.35011 9.69986 8.64011L15.3599 14.3001C15.6499 14.5901 15.6499 15.0701 15.3599 15.3601C15.2099 15.5101 15.0199 15.5801 14.8299 15.5801Z",
                fill: "#fff"
              })
            ], -1),
            _createTextVNode(" Recusar ")
          ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_30, [
        _createElementVNode("div", _hoisted_31, [
          (_openBlock(), _createElementBlock("svg", _hoisted_32, _cache[44] || (_cache[44] = [
            _createElementVNode("path", {
              d: "M11.9999 22.7499C10.8699 22.7499 9.7899 22.4199 8.9799 21.8099L4.6799 18.5999C3.5399 17.7499 2.6499 15.9799 2.6499 14.5599V7.11994C2.6499 5.57994 3.7799 3.93994 5.2299 3.39994L10.2199 1.52994C11.2099 1.15994 12.7699 1.15994 13.7599 1.52994L18.7599 3.39994C20.2099 3.93994 21.3399 5.57994 21.3399 7.11994V10.5499C21.3399 10.9599 20.9999 11.2999 20.5899 11.2999C20.1799 11.2999 19.8399 10.9599 19.8399 10.5499V7.11994C19.8399 6.20994 19.0899 5.12994 18.2299 4.79994L13.2399 2.92994C12.5799 2.67994 11.4099 2.67994 10.7499 2.92994L5.7599 4.80994C4.8999 5.12994 4.1499 6.20994 4.1499 7.12994V14.5599C4.1499 15.5099 4.8199 16.8399 5.5699 17.3999L9.8699 20.6099C10.4199 21.0199 11.1899 21.2499 11.9899 21.2499C12.3999 21.2499 12.7399 21.5899 12.7399 21.9999C12.7399 22.4099 12.4099 22.7499 11.9999 22.7499Z",
              fill: "#171717"
            }, null, -1),
            _createElementVNode("path", {
              d: "M16 20.75C13.38 20.75 11.25 18.62 11.25 16C11.25 13.38 13.38 11.25 16 11.25C18.62 11.25 20.75 13.38 20.75 16C20.75 18.62 18.62 20.75 16 20.75ZM16 12.76C14.21 12.76 12.75 14.22 12.75 16.01C12.75 17.8 14.21 19.26 16 19.26C17.79 19.26 19.25 17.8 19.25 16.01C19.25 14.22 17.79 12.76 16 12.76Z",
              fill: "#171717"
            }, null, -1),
            _createElementVNode("path", {
              d: "M21 22C20.93 22 20.87 21.9899 20.8 21.9799C20.74 21.9699 20.68 21.95 20.62 21.92C20.56 21.9 20.5 21.87 20.44 21.83C20.39 21.79 20.34 21.7499 20.29 21.7099C20.11 21.5199 20 21.26 20 21C20 20.87 20.03 20.74 20.08 20.62C20.13 20.5 20.2 20.39 20.29 20.29C20.66 19.92 21.34 19.92 21.71 20.29C21.8 20.39 21.87 20.5 21.92 20.62C21.97 20.74 22 20.87 22 21C22 21.26 21.89 21.5199 21.71 21.7099C21.52 21.8899 21.26 22 21 22Z",
              fill: "#171717"
            }, null, -1)
          ])))
        ]),
        _cache[46] || (_cache[46] = _createElementVNode("h6", null, "Consulta CNPJ", -1)),
        _createElementVNode("div", { class: "flex items-center gap-2" }, [
          _createElementVNode("button", {
            onClick: verifyCnpj,
            class: "bg-zinc-800 text-white p-8 py-1 rounded-lg w-full text-center flex items-center gap-2 text-sm font-bold"
          }, _cache[45] || (_cache[45] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10",
                stroke: "#fff",
                "stroke-width": "1.5",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }),
              _createElementVNode("path", {
                d: "M12 18.5C13.3807 18.5 14.5 17.3807 14.5 16C14.5 14.6193 13.3807 13.5 12 13.5C10.6193 13.5 9.5 14.6193 9.5 16C9.5 17.3807 10.6193 18.5 12 18.5Z",
                stroke: "#fff",
                "stroke-width": "1.5",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }),
              _createElementVNode("path", {
                d: "M17 22H7C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22Z",
                stroke: "#fff",
                "stroke-width": "1.5",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              })
            ], -1),
            _createTextVNode(" Verficar ")
          ]))
        ])
      ])
    ]),
    _createVNode(MyModal, {
      width: '30rem',
      height: '18rem',
      isOpen: isNotesModalOpen.value,
      toggleModal: (v) => (isNotesModalOpen.value = v)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("button", {
            class: "transition duration-200 hover:text-primary",
            onClick: _cache[14] || (_cache[14] = ($event: any) => (isNotesModalOpen.value = false))
          }, [
            (_openBlock(), _createElementBlock("svg", _hoisted_34, _cache[47] || (_cache[47] = [
              _createElementVNode("path", {
                d: "M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z",
                style: {"fill":"currentcolor"}
              }, null, -1)
            ])))
          ]),
          _cache[48] || (_cache[48] = _createElementVNode("div", { class: "flex flex-col gap-2 col-span-6" }, [
            _createElementVNode("label", { class: "font-semibold text-lg input-primary text-292" }, "Observação"),
            _createElementVNode("textarea", {
              style: {"background":"#f7f7f7"},
              class: "p-4 bg-d5 rounded-xl p-3 rounded-xl w-full input-primary",
              placeholder: "Sua verificação falhou, pois..."
            })
          ], -1)),
          _cache[49] || (_cache[49] = _createElementVNode("button", { class: "bg-primary px-8 py-3 text-white flex items-center gap-2 rounded-3xl font-bold mt-12 w-full justify-center" }, "Recusar", -1))
        ])
      ]),
      _: 1
    }, 8, ["isOpen", "toggleModal"]),
    _createVNode(MyModal, {
      width: '70rem',
      height: '55rem',
      isOpen: isVerifyCnpjModalOpen.value,
      toggleModal: (v) => (isVerifyCnpjModalOpen.value = v)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_35, [
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("button", {
              class: "transition duration-200 hover:text-primary z-10",
              onClick: _cache[15] || (_cache[15] = ($event: any) => (isVerifyCnpjModalOpen.value = false))
            }, [
              (_openBlock(), _createElementBlock("svg", _hoisted_37, _cache[50] || (_cache[50] = [
                _createElementVNode("path", {
                  d: "M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z",
                  style: {"fill":"currentcolor"}
                }, null, -1)
              ])))
            ]),
            _cache[51] || (_cache[51] = _createElementVNode("div", { class: "w-full flex justify-center absolute" }, [
              _createElementVNode("h4", { class: "font-bold text-2xl" }, "Verificação de CNPJ")
            ], -1))
          ]),
          _createElementVNode("div", _hoisted_38, [
            _createElementVNode("div", _hoisted_39, [
              _createElementVNode("div", _hoisted_40, [
                _createElementVNode("table", _hoisted_41, [
                  _cache[54] || (_cache[54] = _createElementVNode("tr", { class: "flex items-center gap-2 mb-4" }, [
                    _createElementVNode("td", { class: "text-292 text-xl font-semibold ttext-lef" }, "CADASTRO"),
                    _createElementVNode("td", { class: "text-292 text-xl font-semibold" }, "RECEITA FEDERAL")
                  ], -1)),
                  _cache[55] || (_cache[55] = _createElementVNode("tr", null, [
                    _createElementVNode("td", { class: "text-292 text-xl font-semibold" }, "CNPJ"),
                    _createElementVNode("td")
                  ], -1)),
                  _createElementVNode("tr", _hoisted_42, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.organization.document), 1),
                    _createElementVNode("td", null, _toDisplayString(cnpjQueryResult.value.estabelecimento?.cnpj), 1)
                  ]),
                  _cache[56] || (_cache[56] = _createElementVNode("tr", null, [
                    _createElementVNode("td", { class: "text-292 text-xl font-semibold" }, "Razão social"),
                    _createElementVNode("td")
                  ], -1)),
                  _createElementVNode("tr", _hoisted_43, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.organization.name), 1),
                    _createElementVNode("td", null, _toDisplayString(cnpjQueryResult.value.razao_social), 1)
                  ]),
                  _cache[57] || (_cache[57] = _createElementVNode("tr", null, [
                    _createElementVNode("td", { class: "text-292 text-xl font-semibold" }, "Nome fantasia"),
                    _createElementVNode("td")
                  ], -1)),
                  _createElementVNode("tr", _hoisted_44, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.organization.nameFantasy), 1),
                    _createElementVNode("td", null, _toDisplayString(cnpjQueryResult.value.estabelecimento?.nome_fantasia), 1)
                  ]),
                  _cache[58] || (_cache[58] = _createElementVNode("tr", null, [
                    _createElementVNode("td", { class: "text-292 text-xl font-semibold" }, "Telefone"),
                    _createElementVNode("td")
                  ], -1)),
                  _createElementVNode("tr", _hoisted_45, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.organization.contactPhone), 1),
                    _createElementVNode("td", null, _toDisplayString(cnpjQueryResult.value.estabelecimento?.ddd1) + _toDisplayString(cnpjQueryResult.value.estabelecimento?.telefone1), 1)
                  ]),
                  _cache[59] || (_cache[59] = _createElementVNode("tr", null, [
                    _createElementVNode("td", { class: "text-292 text-xl font-semibold" }, "Email"),
                    _createElementVNode("td")
                  ], -1)),
                  _createElementVNode("tr", _hoisted_46, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.organization.contactEmail), 1),
                    _createElementVNode("td", null, _toDisplayString(cnpjQueryResult.value.estabelecimento?.email), 1)
                  ]),
                  _cache[60] || (_cache[60] = _createElementVNode("tr", null, [
                    _createElementVNode("td", { class: "text-292 text-xl font-semibold" }, "Inscrição estadual"),
                    _createElementVNode("td")
                  ], -1)),
                  _createElementVNode("tr", _hoisted_47, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.organization.stateRegistration), 1),
                    _createElementVNode("td", null, _toDisplayString(Array.isArray(cnpjQueryResult.value.inscricoes_estaduais) ? cnpjQueryResult.value.inscricoes_estaduais[0].inscricao_estadual : ''), 1)
                  ]),
                  _cache[61] || (_cache[61] = _createElementVNode("tr", null, [
                    _createElementVNode("td", { class: "text-292 text-xl font-semibold" }, "Enquadramento"),
                    _createElementVNode("td")
                  ], -1)),
                  _createElementVNode("tr", _hoisted_48, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.organization.businessType), 1),
                    _createElementVNode("td", null, _toDisplayString(cnpjQueryResult.value.porte?.descricao), 1)
                  ]),
                  _cache[62] || (_cache[62] = _createElementVNode("tr", null, [
                    _createElementVNode("td", { class: "text-292 text-xl font-semibold" }, "CEP"),
                    _createElementVNode("td")
                  ], -1)),
                  _createElementVNode("tr", _hoisted_49, [
                    _cache[52] || (_cache[52] = _createElementVNode("td", null, "@ soeifjoiwe", -1)),
                    _createElementVNode("td", _hoisted_50, _toDisplayString(cnpjQueryResult.value.estabelecimento?.cep), 1)
                  ]),
                  _cache[63] || (_cache[63] = _createElementVNode("tr", null, [
                    _createElementVNode("td", { class: "text-292 text-xl font-semibold" }, "Sócios"),
                    _createElementVNode("td")
                  ], -1)),
                  _createElementVNode("tr", _hoisted_51, [
                    _cache[53] || (_cache[53] = _createElementVNode("td", null, "@ Carlão", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cnpjQueryResult.value.socios, (socio) => {
                      return (_openBlock(), _createElementBlock("td", null, _toDisplayString(socio.nome), 1))
                    }), 256))
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["isOpen", "toggleModal"])
  ]))
}
}

})