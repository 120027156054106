import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "font-inter flex flex-col gap-8 withdrawals" }
const _hoisted_2 = { class: "grid grid-cols-12 withdrawals__filter" }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = { class: "flex flex-col gap-4 pb-12 mb-10 text-292" }
const _hoisted_5 = { class: "withdrawal-tr border rounded-xl p-4" }
const _hoisted_6 = { class: "flex items-center font-bold flex flex-wrap" }
const _hoisted_7 = { class: "flex items-center gap-2" }
const _hoisted_8 = { class: "p-4 confirm-withdrawal-approval" }
const _hoisted_9 = { class: "flex justify-between mt-8 confirm-withdrawal-approval__btns items-center" }
const _hoisted_10 = { class: "p-4 confirm-withdrawal-approval" }
const _hoisted_11 = { class: "flex justify-between mt-8 confirm-withdrawal-approval__btns items-center" }

import { onMounted, ref } from 'vue'
  import MyModal from '@/components/modal/MyModal.vue'
  import axiosClient from '@/api/axios'
  // import Pagination from '@/components/pagination/Pagination.vue'

  // const selectedOrganizationActions = ref<number>()
  
export default /*@__PURE__*/_defineComponent({
  __name: 'WithdrawalsView',
  setup(__props) {

  const isApproveWithdrawalModalOpen = ref(false)
  const isRejectWithdrawalModalOpen = ref(false)
  const organizations = ref<any>([])

  onMounted(() => {
    axiosClient.get('/administrator/organizations').then((res: any) => {
      organizations.value = res.data.data
    })
  })

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[21] || (_cache[21] = _createElementVNode("div", null, [
      _createElementVNode("h1", { class: "text-3xl font-bold pb-2 text-292 font-nunito font-bold" }, "Gerenciar saques"),
      _createElementVNode("p", { class: "text-gray-500 text-sm" }, "Adicione, edite e exclua saques")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
        class: "rounded-xl w-full relative col-span-9 withdrawals__filter__search"
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("input", {
          autocomplete: "off",
          type: "text",
          placeholder: "Busque pelo nome da organização",
          class: "rounded-xl input-primary w-full h-full p-4 py-3 bg-f5 pl-11"
        }, null, -1),
        _createElementVNode("svg", {
          class: "absolute top-1/2 -translate-y-1/2 left-4",
          xmlns: "http://www.w3.org/2000/svg",
          width: "16",
          height: "16",
          viewBox: "0 0 16 16",
          fill: "none"
        }, [
          _createElementVNode("path", {
            d: "M0.0247006 7.87823C0.162935 9.46532 0.887052 10.9532 2.06688 12.0735C3.24671 13.1938 4.80475 13.8732 6.46033 13.9891C8.11592 14.1051 9.76012 13.6501 11.0972 12.7061L14.2375 15.7092C14.4312 15.8952 14.6938 15.9996 14.9677 15.9996C15.2415 15.9996 15.5043 15.8953 15.6978 15.7093C15.8915 15.5235 16.0002 15.2714 16 15.0085C16.0002 14.7458 15.8915 14.4937 15.698 14.3078L12.5681 11.2947C13.9283 9.5308 14.2998 7.23853 13.5616 5.1613C12.8236 3.08439 11.0702 1.48725 8.86994 0.888013C6.66989 0.288729 4.30337 0.763496 2.53789 2.15874C0.772543 3.55382 -0.16685 5.69131 0.0244658 7.87791L0.0247006 7.87823ZM6.96867 2.62946C8.26895 2.62946 9.51591 3.12511 10.4353 4.00726C11.3547 4.88941 11.8713 6.08607 11.8713 7.33363C11.8713 8.58119 11.3547 9.77768 10.4353 10.6598C9.51591 11.542 8.2689 12.0376 6.96867 12.0376C5.66844 12.0376 4.42124 11.542 3.50184 10.6598C2.58245 9.77768 2.06587 8.58119 2.06587 7.33363C2.06587 6.08607 2.58245 4.88941 3.50184 4.00726C4.42124 3.12511 5.66844 2.62946 6.96867 2.62946Z",
            fill: "#676767"
          })
        ], -1)
      ]), 32)
    ]),
    _createElementVNode("table", _hoisted_3, [
      _cache[12] || (_cache[12] = _createElementVNode("thead", { class: "withdrawal-thead p-4 text-292" }, [
        _createElementVNode("div", { class: "" }, "Organização"),
        _createElementVNode("div", { class: "" }, "Banco"),
        _createElementVNode("div", { class: "" }, "Agência"),
        _createElementVNode("div", { class: "" }, "Conta"),
        _createElementVNode("div", { class: "" }, "Saldo atual"),
        _createElementVNode("div", { class: "" }, "Data de solicitação"),
        _createElementVNode("div", { class: "" }, "Valor"),
        _createElementVNode("div", { class: "" }, "Ações")
      ], -1)),
      _createElementVNode("tbody", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(organizations.value, (organization, index) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("td", _hoisted_6, [
              _createVNode(_component_RouterLink, {
                to: {
                name: 'organizations.edit',
                params: { organizationId: organization.id },
              },
                class: "hover:text-primary transition-color duration-200"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(organization.name), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _cache[6] || (_cache[6] = _createElementVNode("td", { class: "" }, "@ Bradesco", -1)),
            _cache[7] || (_cache[7] = _createElementVNode("td", {
              class: "flex flex-col",
              id: "td-contact"
            }, "@ Agência 123", -1)),
            _cache[8] || (_cache[8] = _createElementVNode("td", { class: "rounded-xl flex items-center gap-2 font-semibold" }, "@ conta", -1)),
            _cache[9] || (_cache[9] = _createElementVNode("td", { class: "rounded-xl flex items-center gap-2 font-semibold" }, "@ R$ 127,00", -1)),
            _cache[10] || (_cache[10] = _createElementVNode("td", { class: "rounded-xl flex items-center gap-2 font-semibold" }, "@ 20/10/2023", -1)),
            _cache[11] || (_cache[11] = _createElementVNode("td", { class: "rounded-xl flex items-center gap-2 font-semibold" }, "@ R$ 30, 00", -1)),
            _createElementVNode("td", null, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("button", {
                  class: "bg-red-600 p-2 rounded-lg text-white",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (isRejectWithdrawalModalOpen.value = true))
                }, "Recusar"),
                _createElementVNode("button", {
                  class: "bg-emerald-600 p-2 rounded-lg text-white",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (isApproveWithdrawalModalOpen.value = true))
                }, "Aprovar")
              ])
            ])
          ]))
        }), 256))
      ])
    ]),
    _createVNode(MyModal, {
      width: '330px !important',
      height: '270px !important',
      isOpen: isApproveWithdrawalModalOpen.value,
      toggleModal: (v) => isApproveWithdrawalModalOpen.value = v
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _cache[14] || (_cache[14] = _createElementVNode("svg", {
            class: "mx-auto",
            xmlns: "http://www.w3.org/2000/svg",
            width: "53",
            height: "53",
            viewBox: "0 0 24 24",
            fill: "none"
          }, [
            _createElementVNode("path", {
              d: "M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z",
              fill: "#079f72"
            })
          ], -1)),
          _cache[15] || (_cache[15] = _createElementVNode("h3", null, "Atenção", -1)),
          _cache[16] || (_cache[16] = _createElementVNode("p", { class: "pt-4" }, "Tem certeza que deseja aprovar o saque?", -1)),
          _createElementVNode("div", _hoisted_9, [
            _cache[13] || (_cache[13] = _createElementVNode("button", null, "Sim", -1)),
            _createElementVNode("button", {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (isApproveWithdrawalModalOpen.value = false))
            }, "Cancelar")
          ])
        ])
      ]),
      _: 1
    }, 8, ["isOpen", "toggleModal"]),
    _createVNode(MyModal, {
      width: '330px !important',
      height: '270px !important',
      isOpen: isRejectWithdrawalModalOpen.value,
      toggleModal: (v) => isRejectWithdrawalModalOpen.value = v
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_10, [
          _cache[18] || (_cache[18] = _createElementVNode("svg", {
            class: "mx-auto",
            xmlns: "http://www.w3.org/2000/svg",
            width: "53",
            height: "53",
            viewBox: "0 0 24 24",
            fill: "none"
          }, [
            _createElementVNode("path", {
              d: "M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z",
              fill: "#A20711"
            })
          ], -1)),
          _cache[19] || (_cache[19] = _createElementVNode("h3", null, "Atenção", -1)),
          _cache[20] || (_cache[20] = _createElementVNode("p", { class: "pt-4" }, "Tem certeza que deseja aprovar o saque?", -1)),
          _createElementVNode("div", _hoisted_11, [
            _cache[17] || (_cache[17] = _createElementVNode("button", null, "Sim", -1)),
            _createElementVNode("button", {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (isRejectWithdrawalModalOpen.value = false))
            }, "Cancelar")
          ])
        ])
      ]),
      _: 1
    }, 8, ["isOpen", "toggleModal"])
  ]))
}
}

})