<template>
  <div class="font-inter edit-product" v-if="product">
    <RouterLink :to="{ name: 'products' }" class="inline-flex items-center gap-2 go-back hover:stroke-fff">
      <div class="bg-f5 p-2 rounded-full icon-wrapper transition-all duration-200">
        <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#292f31"
              class="transition-stroke duration-200 hover:parent:stroke-fff stroke-292"
            >
              <path d="M9.57 5.92969L3.5 11.9997L9.57 18.0697M20.5 11.9997H3.67" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </div>
      <h1 class="font-bold text-3xl font-nunito transition-color duration-200">Editar produto</h1>
    </RouterLink>
    <p class="mb-6 text-94">Campos com (*) são obrigatórios</p>
    <div class="flex flex-col gap-8">
      <div class="grid grid-cols-12 gap-4 edit-product__container">
        <div class="flex flex-col gap-2 col-span-6">
          <label for="" class="font-semibold text-lg input-primary">Título</label>
          <input type="text" v-model="product.title" placeholder="ex: Secador" class="border border-d5 p-3 rounded-xl w-full input-primary" />
        </div>
        <!-- <div class="flex flex-col gap-2">
                    <label for="" class="font-semibold text-lg">Descrição</label>
                    <textarea name="" id="" placeholder="ex: Produto de beleza" class="border border-d5 p-4 rounded-xl w-full input-primary" cols="10" rows="10"></textarea>
                </div> -->
        <div class="col-span-6 flex flex-col gap-2">
          <label for="" class="font-semibold text-lg">Categoria</label>
          <FxSelect :name="'categoryId'" :currentValue="productCategories.find((c) => c.id == product!.categoryId)?.title ?? null">
            <li v-for="(category, index) in productCategories" :key="index" @mousedown="product.categoryId = category.id">
              {{ category.title }}
            </li>
          </FxSelect>
        </div>
        <div class="col-span-6 flex flex-col gap-2">
          <label for="" class="font-semibold text-lg">Tipo de produto</label>
          <FxSelect :name="'formatId'" :currentValue="productFormats.find((c) => c.id == product!.formatId)?.title ?? null">
            <li v-for="(format, index) in productFormats" :key="index" @mousedown="product.formatId = format.id">
              {{ format.title }}
            </li>
          </FxSelect>
        </div>
        <div class="col-span-6 flex flex-col gap-2">
          <label for="" class="font-semibold text-lg">Tipo de pagamento</label>
          <FxSelect :name="'formatId'" :currentValue="paymentTypeTranslations[product.typePayment]">
            <li v-for="paymentType in PaymentTypeEnum" @click="product.typePayment = paymentType">{{ paymentTypeTranslations[paymentType] }}</li>
          </FxSelect>
        </div>
      </div>
      <!-- <label class="input-image rounded-xl flex justify-center items-center flex-col gap-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" style="width: 6.5rem; height: 6.5rem;">
                    <path d="M9.5 10C10.6046 10 11.5 9.10457 11.5 8C11.5 6.89543 10.6046 6 9.5 6C8.39543 6 7.5 6.89543 7.5 8C7.5 9.10457 8.39543 10 9.5 10Z" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V10" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18.5 8V2L20.5 4" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18.5 2L16.5 4" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.17 18.9501L8.1 15.6401C8.89 15.1101 10.03 15.1701 10.74 15.7801L11.07 16.0701C11.85 16.7401 13.11 16.7401 13.89 16.0701L18.05 12.5001C18.83 11.8301 20.09 11.8301 20.87 12.5001L22.5 13.9001" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <p class="text-sm text-d5">Arraste e solte</p>
                <button class="bg-primary px-4 py-3 rounded-xl text-white font-medium">Selecionar imagem</button>
            </label> -->
      <div class="flex flex-col gap-6">
        <h2 class="font-semibold text-2xl">Configurações</h2>
        <div class="flex gap-4 items-center">
          <div class="flex gap-4">
            <MySwitch />
            <label class="text-94" for="">@ Produto ativado</label>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-6">
        <h2 class="font-semibold text-2xl">Checkout</h2>
        <div class="flex flex-col gap-4">
          <div class="flex gap-4 flex-col sm:flex-row">
            <div class="flex gap-4">
              <MySwitch />
              <label class="text-94" for="">@ Pedir endereço do cliente</label>
            </div>
            <div class="flex gap-4">
              <MySwitch :id="'billet'" :checked="product.billet == 'accept'" />
              <label class="text-94" for="">Boleto ativado</label>
            </div>
            <div class="flex gap-4">
              <MySwitch :id="'pix'" :checked="product.pix == 'accept'" />
              <label class="text-94" for="">Pix ativado</label>
            </div>
            <div class="flex gap-4">
              <MySwitch :id="'creditCard'" :checked="product.creditCard == 'accept'" />
              <label class="text-94" for="">Cartão ativado</label>
            </div>
          </div>
          <div class="flex flex-col gap-3">
            <label for="" class="font-semibold text-lg input-primary">@ Descrição na fatura</label>
            <input type="text" placeholder="ex: fatura da sua conta" class="border border-d5 p-3 rounded-xl w-full input-primary" />
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-6">
        <h2 class="font-semibold text-2xl">Suporte</h2>
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 sm:col-span-4 flex flex-col gap-2">
            <label for="" class="font-semibold text-lg input-primary">Telefone de suporte</label>
            <input v-model="product.contactPhone" type="text" placeholder="ex: +55 45 99999-9999" class="border border-d5 p-3 rounded-xl w-full input-primary" />
          </div>
          <div class="col-span-12 sm:col-span-4 flex flex-col gap-2">
            <label for="" class="font-semibold text-lg input-primary">Email de suporte</label>
            <input v-model="product.contactEmail" type="text" placeholder="ex: email@suporte.com" class="border border-d5 p-3 rounded-xl w-full input-primary" />
          </div>
          <div class="col-span-12 sm:col-span-4 flex flex-col gap-2">
            <label for="" class="font-semibold text-lg input-primary">Site do produto</label>
            <input v-model="product.website" type="text" placeholder="ex: https://site.com" class="border border-d5 p-3 rounded-xl w-full input-primary" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import axiosClient from '@/api/axios'
  import FxSelect from '../../../components/inputs/FxSelect.vue'
  import MySwitch from '../../../components/inputs/MySwitch.vue'
  import { onMounted, ref } from 'vue'
  import { useHoopay } from '@/hooks/Hoopay'
  import PaymentTypeEnum, { paymentTypeTranslations } from '@/enums/product/PaymentTypeEnum'
  import IProduct from '@/interfaces/product/IProduct'

  const props = defineProps<{ productId: number; organizationId: number }>()
  const product = ref<IProduct>()
  const { listproductFormats, productFormats, listProductCategories, productCategories } = useHoopay()

  function getProduct(): void {
    axiosClient.get(`/organizations/${props.organizationId}/products/${props.productId}`).then((res: any) => {
      product.value = res.data
      listProductCategories(product.value!.organizationId)
    })
  }
  onMounted(() => {
    getProduct()
    listproductFormats()
  })
</script>

<style lang="scss">
  .edit-product {
    input[type='text'] {
      width: 100%;
      height: 55px;
      border-radius: 14px;
      border: 1px solid #d3d3d3;
      color: $primary-color;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 15px 20px;
      box-sizing: border-box;
      transition:
        border-color 0.2s,
        color 0.2s,
        background 0.2s;
      &:focus {
        outline: none;
        border: 2px solid $primary-color;
        &::placeholder {
          color: transparent;
        }
      }
      &::placeholder {
        color: $placeholder-color;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        transition: color 0.2s;
      }
    }

    .input-image {
      border: 1px dashed #d5d5d5;
      padding: 1rem;
      width: 50%;
      height: 22rem;
    }
  }
  @media screen and (max-width: 600px) {
    .edit-product {
      &__container {
        display: flex;
        flex-direction: column;
      }
    }
  }
</style>
