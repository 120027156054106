<template>
  <div class="font-inter flex flex-col gap-8 withdrawals">
    <div>
      <h1 class="text-3xl font-bold pb-2 text-292 font-nunito font-bold">Gerenciar saques</h1>
      <p class="text-gray-500 text-sm">Adicione, edite e exclua saques</p>
    </div>
    <div class="grid grid-cols-12 withdrawals__filter">
      <form @submit.prevent class="rounded-xl w-full relative col-span-9 withdrawals__filter__search">
        <input autocomplete="off" type="text" placeholder="Busque pelo nome da organização" class="rounded-xl input-primary w-full h-full p-4 py-3 bg-f5 pl-11" />
        <svg class="absolute top-1/2 -translate-y-1/2 left-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M0.0247006 7.87823C0.162935 9.46532 0.887052 10.9532 2.06688 12.0735C3.24671 13.1938 4.80475 13.8732 6.46033 13.9891C8.11592 14.1051 9.76012 13.6501 11.0972 12.7061L14.2375 15.7092C14.4312 15.8952 14.6938 15.9996 14.9677 15.9996C15.2415 15.9996 15.5043 15.8953 15.6978 15.7093C15.8915 15.5235 16.0002 15.2714 16 15.0085C16.0002 14.7458 15.8915 14.4937 15.698 14.3078L12.5681 11.2947C13.9283 9.5308 14.2998 7.23853 13.5616 5.1613C12.8236 3.08439 11.0702 1.48725 8.86994 0.888013C6.66989 0.288729 4.30337 0.763496 2.53789 2.15874C0.772543 3.55382 -0.16685 5.69131 0.0244658 7.87791L0.0247006 7.87823ZM6.96867 2.62946C8.26895 2.62946 9.51591 3.12511 10.4353 4.00726C11.3547 4.88941 11.8713 6.08607 11.8713 7.33363C11.8713 8.58119 11.3547 9.77768 10.4353 10.6598C9.51591 11.542 8.2689 12.0376 6.96867 12.0376C5.66844 12.0376 4.42124 11.542 3.50184 10.6598C2.58245 9.77768 2.06587 8.58119 2.06587 7.33363C2.06587 6.08607 2.58245 4.88941 3.50184 4.00726C4.42124 3.12511 5.66844 2.62946 6.96867 2.62946Z"
            fill="#676767"
          />
        </svg>
      </form>
    </div>
    <table class="w-full">
      <thead class="withdrawal-thead p-4 text-292">
        <div class="">Organização</div>
        <div class="">Banco</div>
        <div class="">Agência</div>
        <div class="">Conta</div>
        <div class="">Saldo atual</div>
        <div class="">Data de solicitação</div>
        <div class="">Valor</div>
        <div class="">Ações</div>
      </thead>
      <tbody class="flex flex-col gap-4 pb-12 mb-10 text-292">
        <div v-for="(organization, index) in organizations" class="withdrawal-tr border rounded-xl p-4">
          <td class="flex items-center font-bold flex flex-wrap">
            <RouterLink
              :to="{
                name: 'organizations.edit',
                params: { organizationId: organization.id },
              }"
              class="hover:text-primary transition-color duration-200"
            >
              {{ organization.name }}
            </RouterLink>
          </td>
          <td class="">@ Bradesco</td>
          <td class="flex flex-col" id="td-contact">@ Agência 123</td>
          <!-- <td class="col-span-2">
                        < <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M6.9 20.6C8.4 21.5 10.2 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 13.8 2.5 15.5 3.3 17L2.44044 20.306C2.24572 21.0549 2.93892 21.7317 3.68299 21.5191L6.9 20.6Z" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.5 14.8485C16.5 15.0105 16.4639 15.177 16.3873 15.339C16.3107 15.501 16.2116 15.654 16.0809 15.798C15.86 16.041 15.6167 16.2165 15.3418 16.329C15.0714 16.4415 14.7784 16.5 14.4629 16.5C14.0033 16.5 13.512 16.392 12.9937 16.1715C12.4755 15.951 11.9572 15.654 11.4434 15.2805C10.9251 14.9025 10.4339 14.484 9.9652 14.0205C9.501 13.5525 9.08187 13.062 8.70781 12.549C8.33826 12.036 8.04081 11.523 7.82449 11.0145C7.60816 10.5015 7.5 10.011 7.5 9.543C7.5 9.237 7.55408 8.9445 7.66224 8.6745C7.77041 8.4 7.94166 8.148 8.18052 7.923C8.46895 7.6395 8.78443 7.5 9.11793 7.5C9.24412 7.5 9.37031 7.527 9.48297 7.581C9.60015 7.635 9.70381 7.716 9.78493 7.833L10.8305 9.3045C10.9116 9.417 10.9702 9.5205 11.0108 9.6195C11.0513 9.714 11.0739 9.8085 11.0739 9.894C11.0739 10.002 11.0423 10.11 10.9792 10.2135C10.9206 10.317 10.835 10.425 10.7268 10.533L10.3843 10.8885C10.3348 10.938 10.3122 10.9965 10.3122 11.0685C10.3122 11.1045 10.3167 11.136 10.3257 11.172C10.3393 11.208 10.3528 11.235 10.3618 11.262C10.4429 11.4105 10.5826 11.604 10.7809 11.838C10.9837 12.072 11.2 12.3105 11.4344 12.549C11.6778 12.7875 11.9121 13.008 12.151 13.2105C12.3853 13.4085 12.5791 13.5435 12.7323 13.6245C12.7549 13.6335 12.7819 13.647 12.8135 13.6605C12.8495 13.674 12.8856 13.6785 12.9261 13.6785C13.0028 13.6785 13.0613 13.6515 13.1109 13.602L13.4534 13.2645C13.5661 13.152 13.6743 13.0665 13.7779 13.0125C13.8816 12.9495 13.9852 12.918 14.0979 12.918C14.1835 12.918 14.2737 12.936 14.3728 12.9765C14.472 13.017 14.5756 13.0755 14.6883 13.152L16.18 14.2095C16.2972 14.2905 16.3783 14.385 16.4279 14.4975C16.473 14.61 16.5 14.7225 16.5 14.8485Z" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10"/>
                        </svg> -->
          <!--<a class="text-greenbase text-underline" :href="`https://wa.me/${organization.contactPhone}`" target="_blank">{{ organization.contactPhone }}</a>
                    </td> -->
          <td class="rounded-xl flex items-center gap-2 font-semibold">@ conta</td>
          <td class="rounded-xl flex items-center gap-2 font-semibold">@ R$ 127,00</td>
          <td class="rounded-xl flex items-center gap-2 font-semibold">@ 20/10/2023</td>
          <td class="rounded-xl flex items-center gap-2 font-semibold">@ R$ 30, 00</td>
          <td>
            <div class="flex items-center gap-2">
              <button class="bg-red-600 p-2 rounded-lg text-white" @click="isRejectWithdrawalModalOpen = true">Recusar</button>
              <button class="bg-emerald-600 p-2 rounded-lg text-white" @click="isApproveWithdrawalModalOpen = true">Aprovar</button>
            </div>
          </td>
        </div>
      </tbody>
    </table>
    <!-- <Pagination /> -->
    <MyModal :width="'330px !important'" :height="'270px !important'" :isOpen="isApproveWithdrawalModalOpen" :toggleModal="(v) => isApproveWithdrawalModalOpen = v">
      <div class="p-4 confirm-withdrawal-approval">
        <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 24 24" fill="none">
          <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill="#079f72"/>
        </svg>
        <h3>Atenção</h3>
        <p class="pt-4">Tem certeza que deseja aprovar o saque?</p>
        <div class="flex justify-between mt-8 confirm-withdrawal-approval__btns items-center">
          <button>Sim</button>
          <button @click="isApproveWithdrawalModalOpen = false">Cancelar</button>
        </div>
      </div>
    </MyModal>
    <MyModal :width="'330px !important'" :height="'270px !important'" :isOpen="isRejectWithdrawalModalOpen" :toggleModal="(v) => isRejectWithdrawalModalOpen = v">
      <div class="p-4 confirm-withdrawal-approval">
        <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 24 24" fill="none">
          <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z" fill="#A20711"/>
        </svg>
        <h3>Atenção</h3>
        <p class="pt-4">Tem certeza que deseja aprovar o saque?</p>
        <div class="flex justify-between mt-8 confirm-withdrawal-approval__btns items-center">
          <button>Sim</button>
          <button @click="isRejectWithdrawalModalOpen = false">Cancelar</button>
        </div>
      </div>
    </MyModal>
  </div>
</template>

<script lang="ts" setup>
  import { onMounted, ref } from 'vue'
  import MyModal from '@/components/modal/MyModal.vue'
  import axiosClient from '@/api/axios'
  // import Pagination from '@/components/pagination/Pagination.vue'

  // const selectedOrganizationActions = ref<number>()
  const isApproveWithdrawalModalOpen = ref(false)
  const isRejectWithdrawalModalOpen = ref(false)
  const organizations = ref<any>([])

  onMounted(() => {
    axiosClient.get('/administrator/organizations').then((res: any) => {
      organizations.value = res.data.data
    })
  })
</script>

<style lang="scss" scoped>
  .withdrawals {
    input[type='text'] {
      width: 100%;
      height: 55px;
      border: none;
      border-radius: 16px;
      background: #f5f5f5;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 500;
      transition: background 0.2s;
      &:focus {
        border: none !important;
        outline: none !important;
      }
      &:focus::placeholder {
        color: transparent;
        border: none !important;
      }
    }
    .withdrawal-thead {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 12%;
      column-gap: 20px;
      div {
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        color: #090909;
      }
    }
    .withdrawal-tr {
      min-height: 90px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 12%;
      justify-content: space-between;
      align-items: center;
      column-gap: 20px;
      font-family: 'Inter', sans-serif;
      border: 1px solid #d3d3d3 !important;
      border-radius: 1rem;
    }
    .confirm-withdrawal-approval {
      h3 {
        color: #090909;
        font-family: Nunito, sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        text-align: center;    
      }
      p {
        color: #6f6f6f;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 15px;
      }
      &__btns {
        button {
          height: 30px;
          width: 80px;
          top: unset;
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 18px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: color .2s;
          &:hover{
            color: $primary-color;
          }
          &:first-child {
            height: 60px;
            border-radius: 30px;
            background: #090909;
            color: #FFF;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border: none;
            width: 173px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .withdrawals {
      .withdrawal-thead {
        display: none;
      }
      .withdrawal-tr {
        grid-template-columns: 1fr;
      }
      &__filter {
        grid-template-columns: 1fr;
      }
      #td-contact {
        padding-bottom: 1rem;
      }
    }
  }
</style>
