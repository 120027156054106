enum BankAccountTypeEnum {
  'CASHING' = 'cashing',
  'SAVING' = 'saving',
  'DIGITAL_WALLET' = 'digital_wallet',
}

export const bankAccountTypeTranslations = {
  [BankAccountTypeEnum.CASHING]: 'Conta Corrente',
  [BankAccountTypeEnum.SAVING]: 'Conta Poupança',   
  [BankAccountTypeEnum.DIGITAL_WALLET]: 'Carteira Digital' 
}

export default BankAccountTypeEnum
