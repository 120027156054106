import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "date-picker" }
const _hoisted_2 = { class: "select-date" }
const _hoisted_3 = ["onMousedown"]
const _hoisted_4 = ["onMousedown"]
const _hoisted_5 = { class: "select-day" }
const _hoisted_6 = { class: "days date" }
const _hoisted_7 = ["disabled", "value", "id", "name"]

import { ref, watch, onMounted } from 'vue'
import FxSelect from '@/components/inputs/FxSelect.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'FxDatePicker',
  props: {
    name: {},
    setValue: { type: Function },
    format: {},
    min: {}
  },
  setup(__props: any) {

const props = __props

const selectedYear = ref<number | string>(new Date(props.min.year, props.min.month - 1, props.min.day).getFullYear())
const selectedMonth = ref<number>(new Date(props.min.year, props.min.month - 1, props.min.day).getMonth())
const selectedDay = ref<string>(formatSingleDigit(new Date(props.min.year, props.min.month - 1, props.min.day).getDate()))

const mouthsDatePicker = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

const yearsDatePicker: number[] = []
for (let i = 2024; i >= 1920; i--) {
  yearsDatePicker.push(i)
}

let daysInMonthList: any[] = []

function getFirstDayOfWeekAndDaysInMonth (year: number, month: number) {
  const date = new Date(year, month, 1)
  const firstDayOfWeek = date.getDay()

  const nextMonthDate = new Date(year, month + 1, 0)
  const daysInMonth = nextMonthDate.getDate()

  return {
    firstDayOfWeek: firstDayOfWeek,
    daysInMonth: daysInMonth
  }
}

function daysInMonth () {
const firstDayOfWeek = getFirstDayOfWeekAndDaysInMonth(selectedYear.value as number, selectedMonth.value).firstDayOfWeek
const days = getFirstDayOfWeekAndDaysInMonth(selectedYear.value as number, selectedMonth.value).daysInMonth

daysInMonthList = []

switch (firstDayOfWeek) {
  case 1:
    for (let i = 0; i < 1; i++) {
      daysInMonthList.push(-1)
    }
    break
  case 2:
    for (let i = 0; i < 2; i++) {
      daysInMonthList.push(-1)
    }
    break
  case 3:
    for (let i = 0; i < 3; i++) {
      daysInMonthList.push(-1)
    }
    break
  case 4:
    for (let i = 0; i < 4; i++) {
      daysInMonthList.push(-1)
    }
    break
  case 5:
    for (let i = 0; i < 5; i++) {
      daysInMonthList.push(-1)
    }
    break
  case 6:
    for (let i = 0; i < 6; i++) {
      daysInMonthList.push(-1)
    }
    break
  default:
    break
}

for (let i = 0; i < days; i++) {
  daysInMonthList.push(formatSingleDigit(i + 1).toString())
}
}

function isDayBeforeCurrent (day: any) {
  const currentDate = new Date(props.min.year, props.min.month - 1, props.min.day)
  const selectedDate = new Date(selectedYear.value as number, selectedMonth.value, day)

  if (
    selectedDate.getFullYear() < currentDate.getFullYear() ||
      (selectedDate.getFullYear() === currentDate.getFullYear() && selectedDate.getMonth() < currentDate.getMonth()) ||
      (selectedDate.getFullYear() === currentDate.getFullYear() && selectedDate.getMonth() === currentDate.getMonth() && day < currentDate.getDate())
  ) {
    return true
  } else {
    return false
  }
}

function formatSingleDigit (number: number) {
  if (number.toString().length === 1) {
    return '0' + number
  } else {
    return number.toString()
  }
}

function parseSingleDigit (numberStr: string) {
  if (numberStr.length === 1) {
    return parseInt(numberStr)
  } else if (numberStr[0] === '0') {
    return parseInt(numberStr[1])
  } else {
    return parseInt(numberStr)
  }
}

function formatDate (format: string) {
  const year = selectedYear.value
  const month = (selectedMonth.value + 1).toString().padStart(2, '0')
  const day = selectedDay.value.toString().padStart(2, '0')

  return format.replace('yyyy', year.toString()).replace('mm', month).replace('dd', day)
}

daysInMonth()

onMounted(() => {
  const formattedDate = props.format ? formatDate(props.format) : `${selectedYear.value}-${(selectedMonth.value + 1).toString().padStart(2, '0')}-${selectedDay.value}`
  props.setValue(formattedDate)
})

watch([selectedYear, selectedMonth, selectedDay], () => {
  const currentDate = new Date(props.min.year, props.min.month - 1, props.min.day)
  const selectedDate = new Date(selectedYear.value as number, selectedMonth.value, parseSingleDigit(selectedDay.value))

  if (
    selectedDate.getFullYear() < currentDate.getFullYear() ||
      (selectedDate.getFullYear() === currentDate.getFullYear() && selectedDate.getMonth() < currentDate.getMonth()) ||
      (selectedDate.getFullYear() === currentDate.getFullYear() && selectedDate.getMonth() === currentDate.getMonth() && selectedDate.getDate() < currentDate.getDate())
  ) {
    const formattedDate = props.format ? formatDate(props.format) : `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`
    props.setValue(formattedDate)
  } else {
    const formattedDate = props.format ? formatDate(props.format) : `${selectedYear.value}-${(selectedMonth.value + 1).toString().padStart(2, '0')}-${selectedDay.value.toString().padStart(2, '0')}`
    props.setValue(formattedDate)
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(FxSelect, {
        name: 'year-date-' + _ctx.name + '-year',
        for: 'year-date-' + _ctx.name + '-year',
        currentValue: selectedYear.value.toString()
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(yearsDatePicker, (year, index) => {
            return _createElementVNode("li", {
              key: index,
              onMousedown: ($event: any) => {selectedYear.value = year.toString(); daysInMonth(); selectedDay.value = '15';}
            }, _toDisplayString(year), 41, _hoisted_3)
          }), 64))
        ]),
        _: 1
      }, 8, ["name", "for", "currentValue"]),
      _createVNode(FxSelect, {
        name: 'mouth-date-' + _ctx.name + '-mouth',
        for: 'year-date-' + _ctx.name + '-mouth',
        currentValue: mouthsDatePicker[selectedMonth.value]
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(mouthsDatePicker, (month, index) => {
            return _createElementVNode("li", {
              key: index,
              onMousedown: ($event: any) => {selectedMonth.value = index; daysInMonth(); selectedDay.value = '15';}
            }, _toDisplayString(month), 41, _hoisted_4)
          }), 64))
        ]),
        _: 1
      }, 8, ["name", "for", "currentValue"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "names date" }, [
        _createElementVNode("p", null, "Dom"),
        _createElementVNode("p", null, "Seg"),
        _createElementVNode("p", null, "Ter"),
        _createElementVNode("p", null, "Qua"),
        _createElementVNode("p", null, "Qui"),
        _createElementVNode("p", null, "Sex"),
        _createElementVNode("p", null, "Sab")
      ], -1)),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(daysInMonthList), (day, index) => {
          return (_openBlock(), _createElementBlock("label", {
            class: _normalizeClass(["day", {undefined: day == -1, disabled: isDayBeforeCurrent(day)}]),
            key: index
          }, [
            (day !== -1)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 0,
                  disabled: isDayBeforeCurrent(day),
                  type: "radio",
                  value: day,
                  id: 'day-' + _ctx.name + index,
                  name: 'day-limit-' + _ctx.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedDay).value = $event))
                }, null, 8, _hoisted_7)), [
                  [_vModelRadio, selectedDay.value]
                ])
              : _createCommentVNode("", true),
            _createElementVNode("span", null, _toDisplayString(day == -1 ? '•' : day), 1)
          ], 2))
        }), 128))
      ])
    ])
  ]))
}
}

})