export enum PaymentStatusEnum {
  Processing,
  Authorized,
  Paid,
  Refunded,
  WaitingPayment,
  PendingRefund,
  Refused,
  Chargedback,
  Analyzing,
  PendingReview,
  CheckoutAbandoned,
  StatusWaiting,
  Recurrency,
  Cancelled,
  Undefined,
  RefundPartial,
  ExpiredPayment,
}

// Define a interface para a estrutura de cada status de pagamento
interface PaymentStatusDetail {
  name: string
  tag: string
  color: string
}

// Define o objeto que mapeia o enum para os detalhes do status de pagamento
export const paymentStatusDetails: {
  [key in PaymentStatusEnum]: PaymentStatusDetail
} = {
  [PaymentStatusEnum.Processing]: {
    name: 'Em processamento',
    tag: 'processing',
    color: 'primary',
  },
  [PaymentStatusEnum.Authorized]: {
    name: 'Autorizado',
    tag: 'authorized',
    color: 'success',
  },
  [PaymentStatusEnum.Paid]: {
    name: 'Finalizado',
    tag: 'paid',
    color: 'success',
  },
  [PaymentStatusEnum.Refunded]: {
    name: 'Reembolsado',
    tag: 'refunded',
    color: 'info',
  },
  [PaymentStatusEnum.WaitingPayment]: {
    name: 'Aguardando pagamento',
    tag: 'waiting_payment',
    color: 'warning',
  },
  [PaymentStatusEnum.PendingRefund]: {
    name: 'Reembolso pendente',
    tag: 'pending_refund',
    color: 'info',
  },
  [PaymentStatusEnum.Refused]: {
    name: 'Recusado',
    tag: 'refused',
    color: 'danger',
  },
  [PaymentStatusEnum.Chargedback]: {
    name: 'Chargeback',
    tag: 'chargedback',
    color: 'danger',
  },
  [PaymentStatusEnum.Analyzing]: {
    name: 'Em análise (reembolso)',
    tag: 'analyzing',
    color: 'info',
  },
  [PaymentStatusEnum.PendingReview]: {
    name: 'Revisão pendente',
    tag: 'pending_review',
    color: 'info',
  },
  [PaymentStatusEnum.CheckoutAbandoned]: {
    name: 'Checkout abandonado',
    tag: 'checkout_abandoned',
    color: 'secondary',
  },
  [PaymentStatusEnum.StatusWaiting]: {
    name: 'Aguardando atualização',
    tag: 'status_waiting',
    color: 'secondary',
  },
  [PaymentStatusEnum.Recurrency]: {
    name: 'Recorrência',
    tag: 'recurrency',
    color: 'success',
  },
  [PaymentStatusEnum.Cancelled]: {
    name: 'Cancelado',
    tag: 'cancelled',
    color: 'danger',
  },
  [PaymentStatusEnum.Undefined]: {
    name: 'Aguardando pagamento',
    tag: 'undefined',
    color: 'warning',
  },
  [PaymentStatusEnum.RefundPartial]: {
    name: 'Reembolso Parcial',
    tag: 'refund_partial',
    color: 'info',
  },
  [PaymentStatusEnum.ExpiredPayment]: {
    name: 'Boleto Vencido',
    tag: 'expired_payment',
    color: 'danger',
  },
}
