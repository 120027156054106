import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AdminLayout from '../layouts/admin/AdminLayout.vue'
import HomeView from '@/views/admin/home/HomeView.vue'
import ProductsView from '@/views/admin/product/ProductsView.vue'
import EditProductView from '@/views/admin/product/EditProductView.vue'
import LoginView from '@/views/admin/auth/LoginView.vue'
import OrdersView from '@/views/admin/order/OrdersView.vue'
import EditOrderView from '@/views/admin/order/EditOrderView.vue'
import OrganizationsView from '@/views/admin/organization/OrganizationsView.vue'
import EditOrganizationView from '@/views/admin/organization/EditOrganizationView.vue'
import { isSidebarOpen } from '@/global/state/GlobalState'
import WithdrawalsView from '@/views/admin/withdrawal/WithdrawalsView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: LoginView,
    name: 'login',
  },
  {
    path: '/',
    component: AdminLayout,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('token')) {
        next({ name: 'login' })
      } else {
        next()
      }
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: HomeView,
      },
      {
        path: '/users',
        name: 'users',
        component: HomeView,
      },
      {
        path: '/organizations',
        name: 'organizations',
        component: OrganizationsView,
      },
      {
        path: '/edit-organization/:organizationId',
        name: 'organizations.edit',
        component: EditOrganizationView,
        props: true,
      },
      {
        path: '/organizations/products',
        name: 'products',
        component: ProductsView,
      },
      {
        path: '/organizations/orders',
        name: 'orders',
        component: OrdersView,
      },
      {
        path: '/organizations/:organizationId/orders/:orderId',
        name: 'orders.edit',
        component: EditOrderView,
        props: true,
      },
      {
        path: '/organizations/:organizationId/products/:productId',
        name: 'products.edit',
        component: EditProductView,
        props: true,
      },

      {
        path: '/withdrawals',
        name: 'withdrawals',
        component: WithdrawalsView,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  isSidebarOpen.value = false
  next()
})

export default router
