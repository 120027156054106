<template>
  <div class="orders font-inter flex flex-col gap-8">
    <div>
      <h1 class="text-3xl font-bold pb-2 font-nunito">Gerenciar pedidos</h1>
      <p class="text-gray-500 text-sm">Adicione, edite e exclua pedidos</p>
    </div>
    <div class="grid grid-cols-12">
      <form @submit.prevent class="rounded-xl w-full relative col-span-12 sm:col-span-9">
        <input type="text" placeholder="Busque pelo nome do pedido" class="rounded-xl input-primary w-full h-full p-4 py-3 bg-f5 pl-11" />
        <svg class="absolute top-1/2 -translate-y-1/2 left-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M0.0247006 7.87823C0.162935 9.46532 0.887052 10.9532 2.06688 12.0735C3.24671 13.1938 4.80475 13.8732 6.46033 13.9891C8.11592 14.1051 9.76012 13.6501 11.0972 12.7061L14.2375 15.7092C14.4312 15.8952 14.6938 15.9996 14.9677 15.9996C15.2415 15.9996 15.5043 15.8953 15.6978 15.7093C15.8915 15.5235 16.0002 15.2714 16 15.0085C16.0002 14.7458 15.8915 14.4937 15.698 14.3078L12.5681 11.2947C13.9283 9.5308 14.2998 7.23853 13.5616 5.1613C12.8236 3.08439 11.0702 1.48725 8.86994 0.888013C6.66989 0.288729 4.30337 0.763496 2.53789 2.15874C0.772543 3.55382 -0.16685 5.69131 0.0244658 7.87791L0.0247006 7.87823ZM6.96867 2.62946C8.26895 2.62946 9.51591 3.12511 10.4353 4.00726C11.3547 4.88941 11.8713 6.08607 11.8713 7.33363C11.8713 8.58119 11.3547 9.77768 10.4353 10.6598C9.51591 11.542 8.2689 12.0376 6.96867 12.0376C5.66844 12.0376 4.42124 11.542 3.50184 10.6598C2.58245 9.77768 2.06587 8.58119 2.06587 7.33363C2.06587 6.08607 2.58245 4.88941 3.50184 4.00726C4.42124 3.12511 5.66844 2.62946 6.96867 2.62946Z"
            fill="#676767"
          />
        </svg>
      </form>
    </div>
    <table class="w-full" v-if="orders">
      <thead class="hidden sm:grid grid-cols-11 p-4 gap-4">
        <th class="col-span-1">#ID</th>
        <th class="col-span-1">Total</th>
        <th class="col-span-1">Produto</th>
        <th class="col-span-2">Status/Método</th>
        <th class="col-span-2">Organização</th>
        <th class="col-span-2">Cliente</th>
        <th class="col-span-1">Criado em</th>
        <th class="col-span-1">Ações</th>
      </thead>
      <tbody class="flex flex-col gap-4">
        <tr v-for="order in orders.data" class="justify-between border rounded-xl p-4 grid grid-cols-11 gap-4 order-tr">
          <td class="col-span-5 sm:col-span-1">{{ order.orderId }}</td>
          <td class="col-span-5 sm:col-span-1">
            {{
              order.amount?.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            }}
          </td>
          <td class="col-span-5 sm:col-span-1">{{ order.productTitle }}</td>
          <td class="col-span-3 sm:col-span-2 tag flex flex-col gap-2" :class="'text-' + paymentStatusDetails[order.statusId].color">
            <div class="inline-flex">
              <div class="badge inline-flex" :class="'bg-' + paymentStatusDetails[order.statusId].color">{{ paymentStatusDetails[order.statusId].name }}</div>
            </div>
            {{ PaymentMethodDetails[order.methodId].name }}
          </td>
          <td class="col-span-11 sm:col-span-2">
            <RouterLink
              :to="{
                name: 'organizations.edit',
                params: { organizationId: order.organizationId },
              }"
              class="hover:text-primary transition-color duration-200"
            >
              {{ order.organizationName }}
            </RouterLink>
          </td>
          <td class="col-span-11 sm:col-span-2">{{ order.clientName }}</td>
          <td class="col-span-6 sm:col-span-1">
            {{ new Date(order.createdAt).toLocaleString() }}
          </td>
          <td class="gap-4 col-span-5 sm:col-span-1 pl-2">
            <div class="flex items-center gap-4">
              <button v-if="$route.query.compliance !== undefined" @click="isApproveOrderModalOpen = true">
                <svg xmlns="http://www.w3.org/2000/svg" style="width: 1.3rem; height: 1.3rem" viewBox="0 0 13 10" fill="#323232">
                  <g clip-path="url(#clip0_366_3431)">
                    <path d="M4.6487 9.24519C4.53246 9.36121 4.37493 9.42638 4.2107 9.42638C4.04646 9.42638 3.88894 9.36121 3.7727 9.24519L0.272696 5.74519C0.186251 5.6589 0.11767 5.55641 0.0708778 5.44359C0.0240854 5.33077 0 5.20983 0 5.08769C0 4.96555 0.0240854 4.84461 0.0708778 4.73178C0.11767 4.61896 0.186251 4.51648 0.272696 4.43019L0.710696 3.99319C0.885096 3.81885 1.1216 3.72091 1.3682 3.72091C1.61479 3.72091 1.8513 3.81885 2.0257 3.99319L4.2107 6.17619L10.1107 0.276188C10.2851 0.101846 10.5216 0.00390625 10.7682 0.00390625C11.0148 0.00390625 11.2513 0.101846 11.4257 0.276188L11.8637 0.714188C11.9501 0.800475 12.0187 0.902962 12.0655 1.01578C12.1123 1.12861 12.1364 1.24955 12.1364 1.37169C12.1364 1.49383 12.1123 1.61477 12.0655 1.72759C12.0187 1.84041 11.9501 1.9429 11.8637 2.02919L4.6487 9.24519Z" stroke='none'/>
                  </g>
                  <defs>
                    <clipPath id="clip0_366_3431">
                      <rect width="12.141" height="9.427" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button v-if="$route.query.compliance !== undefined" @click="isRejectOrderModalOpen = true">
                <svg viewBox="0 0 24 24" role="presentation" style="width: 1.3rem; height: 1.3rem">
                  <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" style="fill: currentcolor"></path>
                </svg>
              </button>
              <RouterLink
                :to="{
                  name: 'orders.edit',
                  params: {
                    orderId: order.orderId,
                    organizationId: order.organizationId,
                  },
                }"
                class="flex gap-4 hover:stroke-primary"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
                    stroke="#171717"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="hover:parent:stroke-primary transition-all duration-200"
                  />
                  <path
                    d="M11.9998 20.27C15.5298 20.27 18.8198 18.19 21.1098 14.59C22.0098 13.18 22.0098 10.81 21.1098 9.39997C18.8198 5.79997 15.5298 3.71997 11.9998 3.71997C8.46984 3.71997 5.17984 5.79997 2.88984 9.39997C1.98984 10.81 1.98984 13.18 2.88984 14.59C5.17984 18.19 8.46984 20.27 11.9998 20.27Z"
                    stroke="#171717"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="hover:parent:stroke-primary transition-all duration-200"
                  />
                </svg>
              </RouterLink>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination v-if="orders?.pagination" :data="orders.pagination" :paginate="getOrders" />
    <MyModal :width="'330px !important'" :height="'290px !important'" :isOpen="isApproveOrderModalOpen" :toggleModal="(v) => isApproveOrderModalOpen = v">
      <div class="p-4 confirm-order-action-modal">
        <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 24 24" fill="none">
          <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill="#079f72"/>
        </svg>
        <h3>Atenção</h3>
        <p class="pt-4">Tem certeza que deseja aprovar o pedido?</p>
        <div class="flex justify-between mt-8 confirm-order-action-modal__btns items-center">
          <button>Sim</button>
          <button @click="isApproveOrderModalOpen = false">Cancelar</button>
        </div>
      </div>
    </MyModal>
    <MyModal :width="'330px !important'" :height="'290px !important'" :isOpen="isRejectOrderModalOpen" :toggleModal="(v) => isRejectOrderModalOpen = v">
      <div class="p-4 confirm-order-action-modal">
        <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 24 24" fill="none">
          <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z" fill="#A20711"/>
        </svg>
        <h3>Atenção</h3>
        <p class="pt-4">Tem certeza que deseja aprovar o pedido?</p>
        <div class="flex justify-between mt-8 confirm-order-action-modal__btns items-center">
          <button>Sim</button>
          <button @click="isRejectOrderModalOpen = false">Cancelar</button>
        </div>
      </div>
    </MyModal>
  </div>
</template>

<script lang="ts" setup>
  import { onMounted, ref } from 'vue'
  import axiosClient from '@/api/axios'
  import { paymentStatusDetails } from '@/enums/PaymentStatusEnum'
  import { PaymentMethodDetails } from '@/enums/PaymentMethodEnum'
  import Pagination from '@/components/pagination/Pagination.vue'
  import { IOrder } from '@/interfaces/organization/order/IGetOrderResult'
  import IPagination from '@/interfaces/pagination/IPagination'
  import MyModal from '@/components/modal/MyModal.vue'

  const orders = ref<IPagination<IOrder>>()
  const isRejectOrderModalOpen = ref(false)
  const isApproveOrderModalOpen = ref(false)

  function getOrders(page?: number) {
    const params: { page?: number } = {}

    if (page) {
      params.page = page
    }

    axiosClient.get('/administrator/organizations/orders', { params }).then((res: any) => {
      orders.value = res.data
    })
  }

  onMounted(() => {
    getOrders()
  })
</script>

<style lang="scss">
  .orders {
    input[type='text'] {
      width: 100%;
      height: 55px;
      border: none;
      border-radius: 16px;
      background: #f5f5f5;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 500;
      transition: background 0.2s;
      &:focus {
        border: none !important;
        outline: none !important;
      }
      &:focus::placeholder {
        color: transparent;
        border: none !important;
      }
    }
    th, td {
      font-weight: 500;
    }
    .order-tr {
      border: 1px solid #d3d3d3 !important;
      border-radius: 16px;
      min-height: 100px;
      align-items: center;
    }
    .confirm-order-action-modal {
      h3 {
        color: #090909;
        font-family: Nunito, sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        text-align: center;    
      }
      p {
        color: #6f6f6f;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 15px;
      }
      &__btns {
        button {
          height: 30px;
          width: 80px;
          top: unset;
          color: #090909;
          font-family: 'Inter', sans-serif;
          font-size: 18px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: color .2s;
          &:hover{
            color: $primary-color;
          }
          &:first-child {
            height: 60px;
            border-radius: 30px;
            background: #090909;
            color: #FFF;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border: none;
            width: 173px;
          }
        }
      }
    }
  }
</style>
