<template>
  <div class="organization-view__transactions-tab">
    <div class="grid grid-cols-12 gap-4 pb-10">
      <div class="col-span-12 sm:col-span-7 flex flex-col gap-4">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 sm:col-span-4 bg-light-green p-4 rounded-xl">
            <div class="flex items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="34" viewBox="0 0 25 24" fill="none">
                <path
                  d="M17.0127 20.5H7.0127C4.0127 20.5 2.0127 19 2.0127 15.5V8.5C2.0127 5 4.0127 3.5 7.0127 3.5H17.0127C20.0127 3.5 22.0127 5 22.0127 8.5V15.5C22.0127 19 20.0127 20.5 17.0127 20.5Z"
                  stroke="rgb(81, 220, 157)"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.0127 15C13.6695 15 15.0127 13.6569 15.0127 12C15.0127 10.3431 13.6695 9 12.0127 9C10.3558 9 9.0127 10.3431 9.0127 12C9.0127 13.6569 10.3558 15 12.0127 15Z"
                  stroke="rgb(81, 220, 157)"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M8.5127 6H7.0127C5.6327 6 4.5127 7.12 4.5127 8.5V10" stroke="rgb(81, 220, 157)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M15.5127 6H17.0127C18.3927 6 19.5127 7.12 19.5127 8.5V10"
                  stroke="rgb(81, 220, 157)"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M8.5127 18H7.0127C5.6327 18 4.5127 16.88 4.5127 15.5V14" stroke="rgb(81, 220, 157)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M15.5127 18H17.0127C18.3927 18 19.5127 16.88 19.5127 15.5V14"
                  stroke="rgb(81, 220, 157)"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <h2 class="font-bold text-lg" style="color: rgb(81, 220, 157)">Saldo Disponível</h2>
            </div>
            <h3 class="font-bold py-4 text-lg" v-if="balances?.balance?.real">
              {{
                parseFloat(balances?.balance?.real).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
              }}
            </h3>
          </div>
          <div class="col-span-6 sm:col-span-4 bg-light-blue p-4 rounded-xl">
            <div class="flex items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="34" viewBox="0 0 25 24" fill="none">
                <path
                  d="M9.51172 13.75C9.51172 14.72 10.2617 15.5 11.1817 15.5H13.0617C13.8617 15.5 14.5117 14.82 14.5117 13.97C14.5117 13.06 14.1117 12.73 13.5217 12.52L10.5117 11.47C9.92172 11.26 9.52173 10.94 9.52173 10.02C9.52173 9.17999 10.1717 8.48999 10.9717 8.48999H12.8517C13.7717 8.48999 14.5217 9.26999 14.5217 10.24"
                  stroke="rgb(124, 215, 236)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M12.0117 7.5V16.5" stroke="rgb(124, 215, 236)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M22.0117 12C22.0117 17.52 17.5317 22 12.0117 22C6.49172 22 2.01172 17.52 2.01172 12C2.01172 6.48 6.49172 2 12.0117 2"
                  stroke="rgb(124, 215, 236)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M17.0117 3V7H21.0117" stroke="rgb(124, 215, 236)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M22.0117 2L17.0117 7" stroke="rgb(124, 215, 236)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h2 class="font-bold text-lg" style="color: rgb(124, 215, 236)">A receber</h2>
            </div>
            <h3 v-if="balances?.release?.real" class="font-bold py-4 text-lg">
              {{
                parseFloat(balances?.release?.real).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
              }}
            </h3>
          </div>
          <div class="col-span-6 sm:col-span-4 bg-light-red p-4 rounded-xl">
            <div class="flex items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="34" viewBox="0 0 25 24" fill="none">
                <path
                  d="M2.05029 15.2V9C2.05029 5.5 4.05029 4 7.05029 4H17.0503C20.0503 4 22.0503 5.5 22.0503 9V15C22.0503 18.5 20.0503 20 17.0503 20H8.55029"
                  stroke="rgb(236 124 124)"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.0503 14.5C13.431 14.5 14.5503 13.3807 14.5503 12C14.5503 10.6193 13.431 9.5 12.0503 9.5C10.6696 9.5 9.55029 10.6193 9.55029 12C9.55029 13.3807 10.6696 14.5 12.0503 14.5Z"
                  stroke="rgb(236 124 124)"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M18.5503 9.5V14.5" stroke="rgb(236 124 124)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M9.05029 18C9.05029 18.75 8.84028 19.46 8.47028 20.06C7.78028 21.22 6.51029 22 5.05029 22C3.59029 22 2.32031 21.22 1.63031 20.06C1.26031 19.46 1.05029 18.75 1.05029 18C1.05029 15.79 2.84029 14 5.05029 14C7.26029 14 9.05029 15.79 9.05029 18Z"
                  stroke="rgb(236 124 124)"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M7.80029 15.25L2.30029 20.75" stroke="rgb(236 124 124)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h2 class="font-bold text-lg" style="color: rgb(236 124 124)">Bloqueado</h2>
            </div>
            <h3 class="font-bold py-4 text-lg" v-if="balances?.blocked?.real">
              {{
                parseFloat(balances?.blocked?.real).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
              }}
            </h3>
          </div>
        </div>
        <div class="border p-4 rounded-xl">
          <div class="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M20.9498 14.55L14.5598 20.94C13.1598 22.34 10.8598 22.34 9.44978 20.94L3.05977 14.55C1.65977 13.15 1.65977 10.85 3.05977 9.44001L9.44978 3.05C10.8498 1.65 13.1498 1.65 14.5598 3.05L20.9498 9.44001C22.3498 10.85 22.3498 13.15 20.9498 14.55Z"
                stroke="#171717"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M6.25 6.25L17.75 17.75" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17.75 6.25L6.25 17.75" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <h2 class="font-bold text-lg">Transferência via PIX</h2>
          </div>
          <div class="flex items-center gap-2 pt-1">
            <p class="text-gray-500 text-sm">Escolha a chave pix</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
              <path
                d="M19.7901 14.9301C17.7301 16.9801 14.7801 17.6101 12.1901 16.8001L7.48015 21.5001C7.14015 21.8501 6.47015 22.0601 5.99015 21.9901L3.81015 21.6901C3.09015 21.5901 2.42015 20.9101 2.31015 20.1901L2.01015 18.0101C1.94015 17.5301 2.17015 16.8601 2.50015 16.5201L7.20015 11.8201C6.40015 9.22007 7.02015 6.27007 9.08015 4.22007C12.0301 1.27007 16.8201 1.27007 19.7801 4.22007C22.7401 7.17007 22.7401 11.9801 19.7901 14.9301Z"
                stroke="#171717"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M6.89014 17.49L9.19014 19.79" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M14.5 11C15.3284 11 16 10.3284 16 9.5C16 8.67157 15.3284 8 14.5 8C13.6716 8 13 8.67157 13 9.5C13 10.3284 13.6716 11 14.5 11Z"
                stroke="#171717"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="flex items-center gap-4 flex-wrap py-4 text-sm">
            <button
              class="py-3 px-4 border p-4 rounded-xl flex items-center gap-2 hover:border-primary hover:text-primary transition-all duration-200"
              :class="payload.keyType == 'document' ? 'border-primary text-primary' : ''"
              @click="payload.keyType = 'document'"
            >
              CPF/CNPJ
            </button>
            <button
              class="py-3 px-4 border p-4 rounded-xl flex items-center gap-2 hover:border-primary hover:text-primary transition-all duration-200"
              :class="payload.keyType == 'email' ? 'border-primary text-primary' : ''"
              @click="payload.keyType = 'email'"
            >
              Email
            </button>
            <button
              class="py-3 px-4 border p-4 rounded-xl flex items-center gap-2 hover:border-primary hover:text-primary transition-all duration-200"
              :class="payload.keyType == 'phoneNumber' ? 'border-primary text-primary' : ''"
              @click="payload.keyType = 'phoneNumber'"
            >
              Celular
            </button>
            <button
              class="py-3 px-4 border p-4 rounded-xl flex items-center gap-2 hover:border-primary hover:text-primary transition-all duration-200"
              :class="payload.keyType == 'randomKey' ? 'border-primary text-primary' : ''"
              @click="payload.keyType = 'randomKey'"
            >
              Chave aleatória
            </button>
            <button
              class="py-3 px-4 border p-4 rounded-xl flex items-center gap-2 hover:border-primary hover:text-primary transition-all duration-200"
              :class="payload.keyType == 'accountId' ? 'border-primary text-primary' : ''"
              @click="payload.keyType = 'accountId'"
            >
              Conta bancária
            </button>
          </div>
          <input
            type="text"
            placeholder="Chave pix"
            class="border border-d5 p-3 rounded-xl mb-4 w-full input-primary"
            v-model="payload.key"
            v-maska="payload.keyType == 'document' ? (payload.key?.length <= 14 ? '###.###.###-###' : '##.###.###/####-##') : ''"
          />
          <div class="flex flex-col gap-2 col-span-6">
            <label for="" class="font-semibold text-lg input-primary">Valor a Transferir</label>
            <div class="flex">
              <input type="text" placeholder="ex: 00,00" class="border border-d5 p-3 rounded-tl-xl rounded-bl-xl w-full input-primary" v-model="payload.amount" />
              <button
                class="rounded-tr-xl rounded-br-xl bg-f5 p-4 px-4 sm:px-12 create-transaction-confirmation"
                :disabled="!payload.keyType || !payload.key || (payload.key.length != 14 && payload.key.length != 18 && payload.keyType == 'document')"
                @click="createTransaction"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="transactions" class="col-span-12 sm:col-span-5 border p-4 rounded-xl flex flex-col gap-4">
        <h2 class="font-bold text-lg">Movimentações</h2>
        <div v-for="transaction in transactions" class="flex items-center gap-4 border py-2 px-4 rounded-xl">
          <svg v-if="transaction.status == 'pending'" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none">
            <path
              d="M22 12C22 17.52 17.52 22 12 22C6.48 22 3.11 16.44 3.11 16.44M3.11 16.44H7.63M3.11 16.44V21.44M2 12C2 6.48 6.44 2 12 2C18.67 2 22 7.56 22 7.56M22 7.56V2.56M22 7.56H17.56"
              stroke="#171717"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg v-else-if="transaction.status == 'fail'" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none">
            <path
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
              stroke="#171717"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="stroke-red-500"
            />
            <path d="M12 8.5V14.5" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="stroke-red-500" />
            <path d="M9 12.5L12 15.5L15 12.5" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="stroke-red-500" />
          </svg>
          <svg
            v-else-if="transaction.status == 'success'"
            :class="true ? 'transform rotate-180 stroke-success' : ''"
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
              stroke="#171717"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="stroke-red-500"
            />
            <path d="M12 8.5V14.5" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="stroke-red-500" />
            <path d="M9 12.5L12 15.5L15 12.5" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="stroke-red-500" />
          </svg>
          <div class="w-full">
            <div class="flex items-center justify-between">
              <h3 class="font-bold">
                {{ transactionTypes[transaction.type] }}
              </h3>
              <div class="flex items-center gap-4">
                <p class="text-sm font-semibold" :class="transaction.amount > 0 ? 'text-success' : 'text-red-500'">
                  {{
                    parseFloat(transaction.amount).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                  }}
                </p>
                <p class="text-sm font-semibold">
                  {{
                    parseFloat(transaction.balance).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                  }}
                </p>
              </div>
            </div>
            <p class="text-gray-500 text-xs pt-1">
              {{ new Date(transaction.createdAt).toLocaleString('pt-BR') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { onMounted, ref } from 'vue'
  import axiosClient from '@/api/axios'
  import { vMaska } from 'maska/vue'
  import { useToast } from 'vue-toastification'

  const toast = useToast()
  const payload = ref({ amount: '', key: '', keyType: '' })
  const props = defineProps<{ organizationId: number }>()
  const balances = ref()
  const transactions = ref()
  const transactionTypes = {
    ted: 'TED',
    pix: 'PIX',
    billet: 'Pgto Boleto',
    anticipation: 'Antecipação',
    credit_adjustment: 'Ajuste de Crédito',
    debit_adjustment: 'Ajuste de Débito',
    withdraw: 'Transferência',
    tax: 'Taxa',
    purchase: 'Compra',
    deposit: 'Depósito',
    payment: 'Pagamento',
    transfer: 'Transferência',
  }

  function getBalances() {
    axiosClient.get(`/organizations/${props.organizationId}/finance/balance/`).then((res) => {
      balances.value = res.data
    })
  }

  function getTransactions() {
    axiosClient.get(`/organizations/${props.organizationId}/finance/movimentation/`).then((res) => {
      transactions.value = res.data
    })
  }

  function createTransaction() {
    const pix: Record<string, string> = {}

    // Adiciona os campos específicos com base no tipo
    switch (payload.value.keyType) {
      case 'document':
        pix.cpfCnpj = payload.value.key.replace(/\D/g, '')

        break
      case 'email':
        pix.email = payload.value.key

        break
      case 'phoneNumber':
        pix.phoneNumber = payload.value.key.replace(/\D/g, '')

        break
      case 'randomKey':
        pix.randomKey = payload.value.key

        break
      case 'accountId':
        pix.accountId = payload.value.key

        break
      default:
        return null
    }

    axiosClient
      .post(`/organizations/${props.organizationId}/finance/movimentation/`, {
        amount: payload.value.amount,
        confirmation: true,
        pix: pix,
        type: 'pix',
      })
      .then((res) => {
        console.log(res)
        toast.success('Transferência concluída')
      })
  }

  onMounted(() => {
    getBalances()
    getTransactions()
  })
</script>

<style lang="scss" scoped>
  .organization-view__transactions-tab {
    .create-transaction-confirmation {
      background: #63c999;
      color: #fff;
      &:disabled {
        cursor: not-allowed;
        background: #f5f5f5;
        color: #d5d5d5;
      }
    }
  }

  .stroke-success {
    path {
      stroke: var(--greenbase);
    }
  }
</style>
