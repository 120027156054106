<template>
  <div class="edit-organization font-inter">
    <RouterLink :to="{ name: 'organizations' }" class="inline-flex items-center gap-2 go-back hover:stroke-fff">
      <div class="bg-f5 p-2 rounded-full icon-wrapper transition-all duration-200">
        <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#292f31"
              class="transition-stroke duration-200 hover:parent:stroke-fff stroke-292"
            >
              <path d="M9.57 5.92969L3.5 11.9997L9.57 18.0697M20.5 11.9997H3.67" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </div>
      <h1 class="font-bold text-3xl font-nunito transition-color duration-200">Editar organização</h1>
    </RouterLink>
    <div class="flex items-center mb-6 tabs flex-wrap">
      <button class="" @click="currentTab = 'organization'">
        <p class="flex items-center gap-2" :class="currentTab == 'organization' ? 'active' : ''">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M3.00977 11.22V15.71C3.00977 20.2 4.80977 22 9.29977 22H14.6898C19.1798 22 20.9798 20.2 20.9798 15.71V11.22"
              stroke="#171717"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
            <path
              d="M12 12C13.83 12 15.18 10.51 15 8.68L14.34 2H9.66999L8.99999 8.68C8.81999 10.51 10.17 12 12 12Z"
              stroke="#171717"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
            <path
              d="M18.3098 12C20.3298 12 21.8098 10.36 21.6098 8.35L21.3298 5.6C20.9698 3 19.9698 2 17.3498 2H14.2998L14.9998 9.01C15.1698 10.66 16.6598 12 18.3098 12Z"
              stroke="#171717"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
            <path
              d="M5.63988 12C7.28988 12 8.77988 10.66 8.93988 9.01L9.15988 6.8L9.63988 2H6.58988C3.96988 2 2.96988 3 2.60988 5.6L2.33988 8.35C2.13988 10.36 3.61988 12 5.63988 12Z"
              stroke="#171717"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
            <path
              d="M12 17C10.33 17 9.5 17.83 9.5 19.5V22H14.5V19.5C14.5 17.83 13.67 17 12 17Z"
              stroke="#171717"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
          </svg>
          Organização
        </p>
      </button>
      <button class="" @click="currentTab = 'addresses'">
        <p class="flex items-center gap-2" :class="currentTab == 'addresses' ? 'active' : ''">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M12 13.43C13.7231 13.43 15.12 12.0331 15.12 10.31C15.12 8.58687 13.7231 7.19 12 7.19C10.2769 7.19 8.88 8.58687 8.88 10.31C8.88 12.0331 10.2769 13.43 12 13.43Z"
              stroke="#171717"
              stroke-width="1.5"
              class="hover:parent:stroke-primary"
            />
            <path
              d="M3.61995 8.49C5.58995 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.38995 20.54C5.62995 17.88 2.46995 13.57 3.61995 8.49Z"
              stroke="#171717"
              stroke-width="1.5"
              class="hover:parent:stroke-primary"
            />
          </svg>
          Endereços
        </p>
      </button>
      <button @click="currentTab = 'bank_accounts'">
        <p class="flex items-center gap-2" :class="currentTab == 'bank_accounts' ? 'active' : ''">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M12.37 2.15009L21.37 5.75006C21.72 5.89006 22 6.31006 22 6.68006V10.0001C22 10.5501 21.55 11.0001 21 11.0001H3C2.45 11.0001 2 10.5501 2 10.0001V6.68006C2 6.31006 2.28 5.89006 2.63 5.75006L11.63 2.15009C11.83 2.07009 12.17 2.07009 12.37 2.15009Z"
              stroke="#171717"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
            <path
              d="M22 22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19V22Z"
              stroke="#171717"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
            <path d="M4 18V11" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary" />
            <path d="M8 18V11" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary" />
            <path d="M12 18V11" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary" />
            <path d="M16 18V11" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary" />
            <path d="M20 18V11" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary" />
            <path d="M1 22H23" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary" />
            <path
              d="M12 8.5C12.8284 8.5 13.5 7.82843 13.5 7C13.5 6.17157 12.8284 5.5 12 5.5C11.1716 5.5 10.5 6.17157 10.5 7C10.5 7.82843 11.1716 8.5 12 8.5Z"
              stroke="#171717"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
          </svg>
          Contas bancárias
        </p>
      </button>
      <button @click="currentTab = 'compliances'">
        <p class="flex items-center gap-2" :class="currentTab == 'compliances' ? 'active' : ''">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M10.4902 2.23006L5.50016 4.11006C4.35016 4.54006 3.41016 5.90006 3.41016 7.12006V14.5501C3.41016 15.7301 4.19016 17.2801 5.14016 17.9901L9.44016 21.2001C10.8502 22.2601 13.1702 22.2601 14.5802 21.2001L18.8802 17.9901C19.8302 17.2801 20.6102 15.7301 20.6102 14.5501V7.12006C20.6102 5.89006 19.6702 4.53006 18.5202 4.10006L13.5302 2.23006C12.6802 1.92006 11.3202 1.92006 10.4902 2.23006Z"
              stroke="#171717"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
            <path
              d="M12 12.5C13.1046 12.5 14 11.6046 14 10.5C14 9.39543 13.1046 8.5 12 8.5C10.8954 8.5 10 9.39543 10 10.5C10 11.6046 10.8954 12.5 12 12.5Z"
              stroke="#171717"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
            <path d="M12 12.5V15.5" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary" />
          </svg>
          Compliance
        </p>
      </button>
      <button @click="currentTab = 'partners'">
        <p class="flex items-center gap-2" :class="currentTab == 'partners' ? 'active' : ''">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M9.16006 10.87C9.06006 10.86 8.94006 10.86 8.83006 10.87C6.45006 10.79 4.56006 8.84 4.56006 6.44C4.56006 3.99 6.54006 2 9.00006 2C11.4501 2 13.4401 3.99 13.4401 6.44C13.4301 8.84 11.5401 10.79 9.16006 10.87Z"
              stroke="#171717"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
            <path
              d="M16.41 4C18.35 4 19.91 5.57 19.91 7.5C19.91 9.39 18.41 10.93 16.54 11C16.46 10.99 16.37 10.99 16.28 11"
              stroke="#171717"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
            <path
              d="M4.15997 14.56C1.73997 16.18 1.73997 18.82 4.15997 20.43C6.90997 22.27 11.42 22.27 14.17 20.43C16.59 18.81 16.59 16.17 14.17 14.56C11.43 12.73 6.91997 12.73 4.15997 14.56Z"
              stroke="#171717"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
            <path
              d="M18.34 20C19.06 19.85 19.74 19.56 20.3 19.13C21.86 17.96 21.86 16.03 20.3 14.86C19.75 14.44 19.08 14.16 18.37 14"
              stroke="#171717"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
          </svg>
          Sócios
        </p>
      </button>
      <button @click="currentTab = 'contracts'">
        <p class="flex items-center gap-2" :class="currentTab == 'contracts' ? 'active' : ''">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M15.81 20.18C15.55 20.18 15.28 20.17 14.99 20.14C14.47 20.1 13.88 20 13.27 19.85L11.59 19.45C6.98 18.36 5.47 15.92 6.55 11.32L7.53 7.13001C7.75 6.18002 8.01 5.41002 8.33 4.77002C10.05 1.22002 13.34 1.54001 15.68 2.09001L17.35 2.48001C19.69 3.03001 21.17 3.90001 22 5.23002C22.82 6.56002 22.95 8.27001 22.4 10.61L21.42 14.79C20.56 18.45 18.77 20.18 15.81 20.18ZM13.12 3.25001C11.45 3.25001 10.39 3.94002 9.68 5.42002C9.42 5.96002 9.19 6.63001 8.99 7.47001L8.01 11.66C7.12 15.44 8.15 17.09 11.93 17.99L13.61 18.39C14.15 18.52 14.66 18.6 15.12 18.64C17.84 18.91 19.19 17.72 19.95 14.45L20.93 10.27C21.38 8.34002 21.32 6.99002 20.72 6.02001C20.12 5.05001 18.94 4.39002 17 3.94002L15.33 3.55001C14.5 3.35001 13.76 3.25001 13.12 3.25001Z"
              fill="#171717"
              class="hover:parent:fill-primary"
            />
            <path
              d="M8.33 22.25C5.76 22.25 4.12 20.71 3.07 17.46L1.79 13.51C0.369998 9.10999 1.64 6.62999 6.02 5.20999L7.6 4.69999C8.12 4.53999 8.51 4.42999 8.86 4.36999C9.15 4.30999 9.43 4.41999 9.6 4.64999C9.77 4.87999 9.8 5.17999 9.68 5.43999C9.42 5.96999 9.19 6.63999 9 7.47999L8.02 11.67C7.13 15.45 8.16 17.1 11.94 18L13.62 18.4C14.16 18.53 14.67 18.61 15.13 18.65C15.45 18.68 15.71 18.9 15.8 19.21C15.88 19.52 15.76 19.84 15.5 20.02C14.84 20.47 14.01 20.85 12.96 21.19L11.38 21.71C10.23 22.07 9.23 22.25 8.33 22.25ZM7.78 6.21999L6.49 6.63999C2.92 7.78999 2.07 9.46999 3.22 13.05L4.5 17C5.66 20.57 7.34 21.43 10.91 20.28L12.49 19.76C12.55 19.74 12.6 19.72 12.66 19.7L11.6 19.45C6.99 18.36 5.48 15.92 6.56 11.32L7.54 7.12999C7.61 6.80999 7.69 6.49999 7.78 6.21999Z"
              fill="#171717"
              class="hover:parent:fill-primary"
            />
            <path
              d="M17.49 10.51C17.43 10.51 17.37 10.5 17.3 10.49L12.45 9.26002C12.05 9.16002 11.81 8.75002 11.91 8.35002C12.01 7.95002 12.42 7.71002 12.82 7.81002L17.67 9.04002C18.07 9.14002 18.31 9.55002 18.21 9.95002C18.13 10.28 17.82 10.51 17.49 10.51Z"
              fill="#171717"
              class="hover:parent:fill-primary"
            />
            <path
              d="M14.56 13.89C14.5 13.89 14.44 13.88 14.37 13.87L11.46 13.13C11.06 13.03 10.82 12.62 10.92 12.22C11.02 11.82 11.43 11.58 11.83 11.68L14.74 12.42C15.14 12.52 15.38 12.93 15.28 13.33C15.2 13.67 14.9 13.89 14.56 13.89Z"
              fill="#171717"
              class="hover:parent:fill-primary"
            />
          </svg>
          Contratos
        </p>
      </button>
      <button @click="currentTab = 'movements'">
        <p class="flex items-center gap-2" :class="currentTab == 'movements' ? 'active' : ''">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
              d="M2.51953 13.24V11.51C2.51953 9.44001 4.20953 7.75 6.27953 7.75H17.7595C19.8295 7.75 21.5195 9.44001 21.5195 11.51V12.95H19.4995C18.9395 12.95 18.4295 13.17 18.0595 13.55C17.6395 13.96 17.3995 14.55 17.4595 15.18C17.5495 16.26 18.5395 17.05 19.6195 17.05H21.5195V18.24C21.5195 20.31 19.8295 22 17.7595 22H12.2795"
              stroke="#171717"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
            <path
              d="M2.51953 12.4101V7.8401C2.51953 6.6501 3.24953 5.59006 4.35953 5.17006L12.2995 2.17006C13.5395 1.70006 14.8695 2.62009 14.8695 3.95009V7.75008"
              stroke="#171717"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
            <path
              d="M22.5783 13.9702V16.0302C22.5783 16.5802 22.1383 17.0302 21.5783 17.0502H19.6183C18.5383 17.0502 17.5483 16.2602 17.4583 15.1802C17.3983 14.5502 17.6383 13.9602 18.0583 13.5502C18.4283 13.1702 18.9383 12.9502 19.4983 12.9502H21.5783C22.1383 12.9702 22.5783 13.4202 22.5783 13.9702Z"
              stroke="#171717"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
            <path d="M7.01953 12H14.0195" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary" />
            <path
              d="M3.01953 16.5H8.35953C8.99953 16.5 9.51953 17.02 9.51953 17.66V18.94"
              stroke="#171717"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
            <path
              d="M4.23953 15.28L3.01953 16.5L4.23953 17.72"
              stroke="#171717"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
            <path
              d="M9.51953 21.7801H4.17953C3.53953 21.7801 3.01953 21.2601 3.01953 20.6201V19.3401"
              stroke="#171717"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
            <path
              d="M8.30078 23.0003L9.52078 21.7803L8.30078 20.5603"
              stroke="#171717"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="hover:parent:stroke-primary"
            />
          </svg>
          Movimentações
        </p>
      </button>
      <button @click="currentTab = 'integrations'">
        <p class="flex items-center gap-2" :class="currentTab == 'integrations' ? 'active' : ''">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M12.0156 15C13.6725 15 15.0156 13.6569 15.0156 12C15.0156 10.3431 13.6725 9 12.0156 9C10.3588 9 9.01562 10.3431 9.01562 12C9.01562 13.6569 10.3588 15 12.0156 15Z" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary" />
            <path d="M2.01562 12.8801V11.1201C2.01562 10.0801 2.86563 9.22006 3.91563 9.22006C5.72563 9.22006 6.46562 7.94006 5.55562 6.37006C5.03562 5.47006 5.34562 4.30006 6.25562 3.78006L7.98562 2.79006C8.77562 2.32006 9.79563 2.60006 10.2656 3.39006L10.3756 3.58006C11.2756 5.15006 12.7556 5.15006 13.6656 3.58006L13.7756 3.39006C14.2456 2.60006 15.2656 2.32006 16.0556 2.79006L17.7856 3.78006C18.6956 4.30006 19.0056 5.47006 18.4856 6.37006C17.5756 7.94006 18.3156 9.22006 20.1256 9.22006C21.1656 9.22006 22.0256 10.0701 22.0256 11.1201V12.8801C22.0256 13.9201 21.1756 14.7801 20.1256 14.7801C18.3156 14.7801 17.5756 16.0601 18.4856 17.6301C19.0056 18.5401 18.6956 19.7001 17.7856 20.2201L16.0556 21.2101C15.2656 21.6801 14.2456 21.4001 13.7756 20.6101L13.6656 20.4201C12.7656 18.8501 11.2856 18.8501 10.3756 20.4201L10.2656 20.6101C9.79563 21.4001 8.77562 21.6801 7.98562 21.2101L6.25562 20.2201C5.34562 19.7001 5.03562 18.5301 5.55562 17.6301C6.46562 16.0601 5.72563 14.7801 3.91563 14.7801C2.86563 14.7801 2.01562 13.9201 2.01562 12.8801Z" stroke="#171717" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" class="hover:parent:stroke-primary" />
          </svg>
          Integrações
        </p>
      </button>
    </div>
    <div v-if="currentTab == 'organization'" class="flex flex-col gap-8 h-full">
      <div class="grid grid-cols-12">
        <div class="col-span-12 bg-f5 rounded-xl">
          <div :style="{width: health?.totalPercentage + '%' }" class="rounded-xl px-4 text-white" :class="{ 
            'bg-success' : (health?.totalPercentage >= 98), 
            'bg-warning': (health?.totalPercentage >= 96 && health?.totalPercentage < 98), 
            'bg-orange': (health?.totalPercentage >= 94 && health?.totalPercentage < 96), 
            'bg-danger': (health?.totalPercentage < 94), 
          }">{{ health?.totalPercentage }}%</div>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 border p-4 rounded-xl">
        <div class="col-span-12 grid grid-cols-12 gap-6">
          <div class="flex flex-col gap-2 col-span-6 sm:col-span-3">
            <label for="" class="font-semibold text-lg input-primary">Razão Social</label>
            <input type="text" v-model="organizationForm.name" placeholder="ex: Empresa" class="border border-d5 p-3 rounded-xl w-full input-primary" />
          </div>
          <div class="flex flex-col gap-2 col-span-6 sm:col-span-3">
            <label for="" class="font-semibold text-lg input-primary">Documento</label>
            <input type="text" v-model="organizationForm.document" placeholder="ex: 000.000.000-00" class="border border-d5 p-3 rounded-xl w-full input-primary" />
          </div>
          <div class="flex flex-col gap-2 col-span-3">
            <label for="" class="font-semibold text-lg input-primary">Nome Fantasia</label>
            <input type="text" v-model="organizationForm.nameFantasy" placeholder="ex: Empresa Pagamentos" class="border border-d5 p-3 rounded-xl w-full input-primary" />
          </div>
          <div class="flex flex-col gap-2 col-span-3">
            <label for="" class="font-semibold text-lg input-primary">Email</label>
            <input type="text" v-model="organizationForm.contactEmail" placeholder="ex: empresa@gmail.com" class="border border-d5 p-3 rounded-xl w-full input-primary" />
          </div>
          <div class="col-span-6 flex flex-col gap-2">
            <label for="" class="font-semibold text-lg">MCC</label>
            <FxSearchSelect :name="'mcc'" :currentValue="MCCSearch" v-model="MCCSearch" @blur="onMCCBlur">
              <li v-for="(option, index) in filteredMCCOptions" :key="index" @mousedown="organizationForm.mcc = option; MCCSearch =  `${option.code} - ${option.category}`">{{ option.code }} - {{ option.category }}</li>
            </FxSearchSelect>
          </div>
          <div class="flex flex-col gap-2 col-span-6">
            <label for="" class="font-semibold text-lg input-primary">Descrição da fatura</label>
            <input type="text" v-model="organizationForm.softDescriptor" placeholder="ex: Gastos" class="border border-d5 p-3 rounded-xl w-full input-primary" />
          </div>
          <div class="col-span-3 flex flex-col gap-2">
            <label for="" class="font-semibold text-lg">Capital Social</label>
            <input type="number" v-model="organizationForm.shareCapital" placeholder="ex: 3.000,00" class="border border-d5 p-3 rounded-xl w-full input-primary" />
          </div>
          <div class="flex flex-col gap-2 col-span-3">
            <label for="" class="font-semibold text-lg input-primary">Valor de risco</label>
            <input type="number" v-model="organizationForm.riskValue" placeholder="ex: 3.000,00" class="border border-d5 p-3 rounded-xl w-full input-primary" />
          </div>
          <div class="col-span-3 flex flex-col gap-2">
            <label for="" class="font-semibold text-lg">Faturamento Previsto</label>
            <input type="number" v-model="organizationForm.turnover" placeholder="ex: 3.000,00" class="border border-d5 p-3 rounded-xl w-full input-primary" />
          </div>
          <div class="flex flex-col gap-2 col-span-6 sm:col-span-3">
            <label for="" class="font-semibold text-lg input-primary">Faturamento</label>
            <input type="number" v-model="organizationForm.billing" placeholder="ex: 500.000,00" class="border border-d5 p-3 rounded-xl w-full input-primary" />
          </div>
          <div class="flex flex-col gap-2 col-span-3">
            <label for="" class="font-semibold text-lg input-primary">Telefone</label>
            <input type="text" v-model="organizationForm.contactPhone" placeholder="ex: 99 99999-9999" class="border border-d5 p-3 rounded-xl w-full input-primary" />
          </div>
          <div class="flex flex-col gap-2 col-span-3">
            <label for="" class="font-semibold text-lg input-primary">Inscrição Estadual</label>
            <input type="text" v-model="organizationForm.stateRegistration" placeholder="ex: 87654" class="border border-d5 p-3 rounded-xl w-full input-primary" />
          </div>
          <div class="col-span-6 flex flex-col gap-2">
            <label for="" class="font-semibold text-lg">Enquadramento</label>
            <FxSelect :name="'businessTypeOptions'" :currentValue="organizationForm.classification">
              <li v-for="businessType in businessTypeOptions"@mousedown="organizationForm.classification = businessType.label">{{ businessType.label }}</li>
            </FxSelect>
          </div>
          <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
            <label for="" class="font-semibold text-lg input-primary">Data de cadastro</label>
            <input type="text" :value="new Date(organizationForm.createdAt).toLocaleString()" placeholder="ex: 06/02/123" class="border border-d5 p-3 rounded-xl w-full input-primary" />
          </div>
          <div class="flex flex-col gap-2 col-span-12 sm:col-span-6">
            <label for="" class="font-semibold text-lg input-primary">Website</label>
            <input type="text" v-model="organizationForm.website" placeholder="ex: https://empresa.com" class="border border-d5 p-3 rounded-xl w-full input-primary" />
          </div>
          <div class="flex flex-col gap-2 col-span-12">
            <label for="" class="font-semibold text-lg input-primary">Observações</label>
            <textarea v-model="organizationForm.observations" placeholder="ex: empresa ativa" class="border border-d5 p-3 rounded-xl w-full input-primary" rows="5"></textarea>
          </div>
        </div>
        <div class="col-span-12 grid grid-cols-12 gap-8">
          <div class="flex flex-col gap-2 col-span-2">
            <label for="" class="font-semibold text-lg">Status</label>
            <MySwitch :id="'status'" :checked="organizationForm.status == 'active'" v-model="organizationForm.status" />
          </div>
        </div>
      </div>
      <nav class="fixed">
        <input type="button" value="Enviar" @click="updateOrganization()" 
        :disabled="!organizationForm.nameFantasy || !organizationForm.website || !organizationForm.observations || !organizationForm.mcc?.code || !organizationForm.stateRegistration || !organizationForm.classification" 
        />
        <input type="button" value="Cancelar" @click="$router.push({ name: 'organizations' })" />
      </nav>
    </div>
    <AddressesTab v-if="currentTab == 'addresses'" :organizationId="props.organizationId" :document="organizationForm.document" />
    <BankAccountsTab :organizationId="props.organizationId" v-if="currentTab == 'bank_accounts'" />
    <PartnersTab :organizationId="props.organizationId" v-if="currentTab == 'partners'" />
    <CompliancesTab :organization="organizationForm" v-if="currentTab == 'compliances'" />
    <ContractsTab :organizationId="props.organizationId" v-if="currentTab == 'contracts'" />
    <MovementsTab :organizationId="props.organizationId" v-if="currentTab == 'movements'" />
    <IntegrationsTab :organizationId="props.organizationId" v-if="currentTab == 'integrations'" />
  </div>
</template>
<script lang="ts" setup>
  import { defineProps, ref, onMounted, computed } from 'vue'
  import axiosClient from '@/api/axios'
  import { useToast } from 'vue-toastification'
  import FxSelect from '@/components/inputs/FxSelect.vue'
  import FxSearchSelect from '@/components/inputs/FxSearchSelect.vue'
  import CompliancesTab from './components/CompliancesTab.vue'
  import PartnersTab from './components/PartnersTab.vue'
  import MovementsTab from './components/MovementsTab.vue'
  import ContractsTab from './components/ContractsTab.vue'
  import BankAccountsTab from './components/BankAccountsTab.vue'
  import AddressesTab from './components/AddressesTab.vue'
  import IntegrationsTab from './components/IntegrationsTab.vue'
  import MySwitch from '@/components/inputs/MySwitch.vue'
  import OrganizationStatusEnum from '@/enums/organization/OrganizationStatusEnum'

  const props = defineProps<{ organizationId: number }>()
  const currentTab = ref('organization')
  const organizationForm = ref<any>({})
  const toast = useToast()
  const health = ref<any>()
  const MCCSearch = ref('')
  const MCCoptions = ref<{ code: string, category: string, description: string }[]>([])
  const filteredMCCOptions = computed(() => MCCoptions.value.filter((option) => `${option.code} - ${option.category.toLocaleLowerCase()}`.includes(MCCSearch.value.toLocaleLowerCase())))

  function getOrganization() {
    axiosClient.get(`/administrator/organizations/${props.organizationId}`).then((res) => {
      organizationForm.value = { ...res.data.organization, status: res.data.organization.status === 'active' }
    })
  }

  function getOrganizationHealth() {
    axiosClient.get(`/organizations/${props.organizationId}/health`).then((res) => {
      health.value = res.data
    })
  }

  function getMCC() {
    axiosClient.get(`/administrator/general/mcc`)
      .then(((res) => {
        MCCoptions.value = res.data
      }))
  }

  function onMCCBlur() {
    console.log(MCCSearch.value !== `${organizationForm.value.mcc?.code} - ${organizationForm.value.mcc?.category}`)
    if (MCCSearch.value !== `${organizationForm.value.mcc?.code} - ${organizationForm.value.mcc?.category}`) {
      organizationForm.value.mcc = null
      MCCSearch.value = ''
    } 
  }

  onMounted(() => {
    getMCC()
    getOrganization()
    getOrganizationHealth()
  })

  function updateOrganization() {
    const payload = { 
      name: organizationForm.value.name,
      nameFantasy: organizationForm.value.nameFantasy,
      contactEmail: organizationForm.value.contactEmail,
      contactPhone: organizationForm.value.contactPhone,
      website: organizationForm.value.website,
      softDescriptor: organizationForm.value.softDescriptor,
      observations: organizationForm.value.observations,
      mcc: organizationForm.value.mcc.id,
      stateRegistration: organizationForm.value.stateRegistration,
      classification: organizationForm.value.classification ?? '',
      shareCapital: organizationForm.value.shareCapital ?? '',
      riskValue: organizationForm.value.riskValue ?? '',
      turnover: organizationForm.value.turnover ?? '',
      status: organizationForm.value ? OrganizationStatusEnum.ACTIVE : OrganizationStatusEnum.INACTIVE  
    }
    // status: Joi.string().valid('active', 'pending', 'inactive').required(),
    //   name: Joi.string().required(),
    //   nameFantasy: Joi.string().required(),
    //   contactEmail: Joi.string().email().required(),
    //   contactPhone: Joi.string().required(),
    //   website: Joi.string().uri(),
    //   softDescriptor: Joi.string().required(),
    //   observations: Joi.string().optional(),
    //   mcc: Joi.number().integer().required(),
    //   stateRegistration: Joi.string().required(),
    //   classification: Joi.string().valid('SA', 'LTDA', 'MEI', 'ME', 'EPP', 'EIRELI').required(),
    //   shareCapital: Joi.number().positive().required(),
    //   riskValue: Joi.number().positive().required(),
    //   turnover: Joi.number().positive().required()
    axiosClient
      .put(`/administrator/organizations/${props.organizationId}`, payload)
      .then(() => {
        toast.success('Organização atualizada')
      })
  }

  const businessTypeOptions = [
    { value: '113', label: 'SA' },
    { value: '114', label: 'LTDA' },
    { value: '115', label: 'MEI' },
    { value: '116', label: 'ME' },
    { value: '117', label: 'EPP' },
    { value: '118', label: 'EIRELI' },
  ]

</script>

<style lang="scss">
  .edit-organization {
    padding-bottom: 8rem;
    input[type='text'],
    input[type='number'] {
      width: 100%;
      height: 55px;
      border-radius: 14px;
      border: 1px solid #d3d3d3;
      color: $primary-color;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 15px 20px;
      box-sizing: border-box;
      transition:
        border-color 0.2s,
        color 0.2s,
        background 0.2s;
      &:focus {
        outline: 1px solid $primary-color;
        border: 1px solid $primary-color;
        &::placeholder {
          color: transparent;
        }
      }
      &::placeholder {
        color: $placeholder-color;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        transition: color 0.2s;
      }
    }
    tr {
      border: 1px solid #d3d3d3 !important;
    }
    nav {
      width: 100%;
      max-width: 1200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 32px;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      bottom: 5px;
      column-gap: 20px;
      input {
        border: none;
        cursor: pointer;
        &:first-child {
          width: 100%;
          max-width: 464px;
          height: 60px;
          border-radius: 30px;
          background: $primary-color;
          color: #fff;
          text-align: center;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          transition: background 0.2s;
          &:hover {
            background: $primary-color;
          }
        }
        &:last-child {
          color: #737373;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          background: unset;
          transition: color 0.2s;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }

    .modal{
      &__content{
        margin: 15px 0 auto 0;
      }
      &.confirm-delete{
        div{
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 26px 15px 24px;
          h1{
            color: #090909;
            font-family: 'Nunito', sans-serif;
            font-size: 28px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            margin: 2px 0 7px 0;
          }
          h2{
            color: #6F6F6F;
            text-align: center;
            font-family: 'Inter', sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0;
          }
          section{
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            padding: 0;
            margin: auto 0 0 0;
            button{
              height: 30px;
              width: 80px;
              top: unset;
              color: #090909;
              font-family: 'Inter', sans-serif;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: color .2s;
              cursor: pointer;
              &:hover{
                color: $primary-color;
              }
            }
            input{
              height: 60px;
              border-radius: 30px;
              background: #090909;
              color: #FFF;
              font-family: 'Inter', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              border: none;
              width: 173px;
              cursor: pointer;
            }
          }
        }
        button{
          display: flex;
          top: 28px;
          right: 28px;
        }
      }
    }
  }
  .input-image {
    border: 1px dashed #d5d5d5;
    padding: 1rem;
    width: 50%;
    height: 22rem;
  }
  button:disabled, input:disabled {
    cursor: not-allowed !important;
    background: #f5f5f5 !important;
    color: #d5d5d5 !important;
  }
</style>
