<template>
  <div
    :style="{ height: height, width: width, zIndex: zIndex }"
    class="transition-top overflow-auto duration-300 fixed bg-white box-shadow z-20 sm:rounded-xl modal left-1/2 -translate-x-1/2"
    :class="[isOpen ? ('open top-1/2 -translate-y-1/2 sm:bottom-0 opacity-100 ' + className ?? '') : ('top-[-100%] opacity-0 ' + className ?? '')]"
  >
    <slot></slot>
  </div>
  <div class="fixed top-0 left-0 w-full bottom-0 h-full bg-black bg-opacity-50" :style="{ zIndex: (zIndex ?? 2) - 1 }" v-if="isOpen" @click="toggleModal(!isOpen)"></div>
</template>

<script setup lang="ts">
  import { defineProps, watch } from 'vue'

  const props = defineProps<{
    isOpen: boolean;
    height?: string;
    width?: string;
    toggleModal: (value: boolean) => void;
    zIndex?: number;
    className?: string;
  }>()

  watch(
    () => props.isOpen,
    (newValue) => {
      if (newValue) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    },
  )
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 800px) {
    .modal {
      height: auto !important;
      bottom: 0;
      width: 100% !important;
      left: 0;
      right: 0;
      transform: translate3d(0, 100vh, 0);
      top: 0;
      opacity: 0;
      transition: all 0.2s ease-out;
      border-radius: 0;

      &.open {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }
</style>
