import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center my-4" }
const _hoisted_2 = {
  key: 0,
  class: "my-10 py-10"
}
const _hoisted_3 = {
  key: 1,
  class: "w-full"
}
const _hoisted_4 = { class: "flex flex-col gap-4" }
const _hoisted_5 = { class: "grid grid-cols-5 border rounded-xl p-4" }
const _hoisted_6 = { class: "col-span-2 sm:col-span-1" }
const _hoisted_7 = { class: "col-span-2 sm:col-span-1" }
const _hoisted_8 = { class: "col-span-2 sm:col-span-1" }
const _hoisted_9 = { class: "col-span-2 sm:col-span-1" }
const _hoisted_10 = { class: "col-span-1 sm:col-span-1 flex gap-4" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "p-4 sm:p-8" }
const _hoisted_14 = { class: "flex justify-between items-start" }
const _hoisted_15 = {
  viewBox: "0 0 24 24",
  role: "presentation",
  style: {"width":"1.5rem","height":"1.5rem"}
}
const _hoisted_16 = { class: "grid grid-cols-12 gap-6 rounded-xl" }
const _hoisted_17 = { class: "flex flex-col gap-4 col-span-12 sm:col-span-6" }
const _hoisted_18 = { class: "flex flex-col gap-2" }
const _hoisted_19 = { class: "flex flex-col gap-2" }
const _hoisted_20 = { class: "flex flex-col gap-2" }
const _hoisted_21 = { class: "col-span-12 sm:col-span-6 flex flex-col gap-2" }
const _hoisted_22 = ["disabled"]
const _hoisted_23 = { class: "p-4 sm:p-8" }
const _hoisted_24 = { class: "flex justify-between items-start" }
const _hoisted_25 = {
  viewBox: "0 0 24 24",
  role: "presentation",
  style: {"width":"1.5rem","height":"1.5rem"}
}
const _hoisted_26 = { class: "grid grid-cols-12 gap-6 rounded-xl" }
const _hoisted_27 = { class: "col-span-12 sm:col-span-6 flex flex-col gap-2" }
const _hoisted_28 = { class: "flex flex-col gap-2" }
const _hoisted_29 = { class: "flex flex-col gap-2" }
const _hoisted_30 = { class: "flex flex-col gap-2" }
const _hoisted_31 = { class: "flex flex-col gap-2 col-span-12 sm:col-span-6" }
const _hoisted_32 = ["disabled"]

import MyModal from '@/components/modal/MyModal.vue'
  import { ref, onMounted } from 'vue'
  import axiosClient from '@/api/axios'
  import IPartner from '@/interfaces/organization/IPartner'
  import { useToast } from 'vue-toastification'
  import { vMaska } from 'maska/vue'
  import FxDatePicker from '@/components/date/FxDatePicker.vue'


  
export default /*@__PURE__*/_defineComponent({
  __name: 'PartnersTab',
  props: {
    organizationId: {}
  },
  setup(__props: any) {

  const partners = ref<Partial<IPartner>[]>()
  const partnerForm = ref<Partial<IPartner>>({})
  const partnerToUpdate = ref<Partial<IPartner>>({})
  const isCreatePartnersModalOpen = ref(false)
  const isEditPartnerModalOpen = ref(false)
  const isConfirmDeleteModalOpen = ref(false)
  const props = __props
  const toast = useToast()

  function getPartners() {
    axiosClient.get(`/organizations/${props.organizationId}/partners`).then((res) => {
      partners.value = res.data  
    })
  }

  function createPartners() {
    axiosClient.post(`/organizations/${props.organizationId}/partners`, { ...partnerForm.value, document: partnerForm.value.document!.replace(/[\.\-\/]/g, '') }).then((res) => {
      if (partners.value) {
        partners.value.push(res.data)
      } else {
        partners.value = [res.data]
      }
      partnerForm.value = {}
      isCreatePartnersModalOpen.value = false
      toast.success("Sócio criado com sucesso")
    })
  }

  function updatePartner() {
    axiosClient.put(`/organizations/${props.organizationId}/partners/${partnerToUpdate.value.id}`, { 
      name: partnerToUpdate.value.name, 
      document: partnerToUpdate.value.document!.replace(/[\.\-\/]/g, ''), 
      dateOfBirth: partnerToUpdate.value.dateOfBirth, 
      percentage: partnerToUpdate.value.percentage, 
    }).then((res) => {
      const updatePartnerIndex = partners.value!.findIndex((partner) => partner.id === partnerToUpdate.value.id)
      partners.value![updatePartnerIndex] = partnerToUpdate.value
      isEditPartnerModalOpen.value = false
      toast.success('Sócio editado com sucesso')
    })
  }

  function deletePartner() {
    axiosClient.delete(`/organizations/${props.organizationId}/partners/${partnerToUpdate.value.id}`)
      .then(() => {
        partners.value = partners.value?.filter((bankAcc) => bankAcc.id !== partnerToUpdate.value.id)
        toast.success('Sócio deletada com sucesso')
      })
  }

  onMounted(() => {
    getPartners()
  })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[12] || (_cache[12] = _createElementVNode("div", null, [
        _createElementVNode("h4", { class: "font-bold text-2xl text-292" }, "Gerenciar sócios"),
        _createElementVNode("p", { class: "mb-6 text-94 text-sm" }, "Crie e edite os sócios da organização")
      ], -1)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (isCreatePartnersModalOpen.value = true)),
        class: "bg-primary px-4 py-3 text-white flex items-center gap-2 rounded-3xl font-bold"
      }, _cache[11] || (_cache[11] = [
        _createStaticVNode("<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\"><path d=\"M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M3.40991 22C3.40991 18.13 7.25991 15 11.9999 15C12.9599 15 13.8899 15.13 14.7599 15.37\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M22 18C22 18.32 21.96 18.63 21.88 18.93C21.79 19.33 21.63 19.72 21.42 20.06C20.73 21.22 19.46 22 18 22C16.97 22 16.04 21.61 15.34 20.97C15.04 20.71 14.78 20.4 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.92 14.43 15.93 15.13 15.21C15.86 14.46 16.88 14 18 14C19.18 14 20.25 14.51 20.97 15.33C21.61 16.04 22 16.98 22 18Z\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M19.49 17.98H16.51\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path><path d=\"M18 16.52V19.51\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg> Criar ", 2)
      ]))
    ]),
    (partners.value && !partners.value?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[13] || (_cache[13] = [
          _createStaticVNode("<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"104\" height=\"104\" viewBox=\"0 0 24 24\" fill=\"none\" class=\"mx-auto\"><path d=\"M12.0699 8.95005C12.0299 8.95005 11.9699 8.95005 11.9199 8.95005C10.8699 8.91005 10.0399 8.06005 10.0399 7.00005C10.0399 5.92005 10.9099 5.05005 11.9899 5.05005C13.0699 5.05005 13.9399 5.93005 13.9399 7.00005C13.9499 8.06005 13.1199 8.92005 12.0699 8.95005Z\" fill=\"#171717\"></path><path d=\"M9.24994 11.9601C7.91994 12.8501 7.91994 14.3001 9.24994 15.1901C10.7599 16.2001 13.2399 16.2001 14.7499 15.1901C16.0799 14.3001 16.0799 12.8501 14.7499 11.9601C13.2399 10.9601 10.7699 10.9601 9.24994 11.9601Z\" fill=\"#171717\"></path><path opacity=\"0.4\" d=\"M18 2H6C4.34 2 3 3.33 3 4.97V15.88C3 17.52 4.34 18.85 6 18.85H6.76C7.56 18.85 8.32 19.16 8.88 19.72L10.59 21.41C11.37 22.18 12.64 22.18 13.42 21.41L15.13 19.72C15.69 19.16 16.46 18.85 17.25 18.85H18C19.66 18.85 21 17.52 21 15.88V4.97C21 3.33 19.66 2 18 2ZM12 5.05C13.08 5.05 13.95 5.93 13.95 7C13.95 8.06 13.11 8.91 12.07 8.95C12.03 8.95 11.97 8.95 11.92 8.95C10.87 8.91 10.04 8.06 10.04 7C10.05 5.93 10.92 5.05 12 5.05ZM14.75 15.19C13.24 16.2 10.76 16.2 9.25 15.19C7.92 14.31 7.92 12.85 9.25 11.96C10.77 10.95 13.25 10.95 14.75 11.96C16.08 12.85 16.08 14.3 14.75 15.19Z\" fill=\"#171717\"></path></svg><h4 class=\"font-semibold text-292 text-xl text-center\">Nenhum sócio cadastrado</h4><p class=\"text-zinc-400 text-center text-sm\">Clique em criar para cadastradar um sócio</p>", 3)
        ])))
      : (_openBlock(), _createElementBlock("table", _hoisted_3, [
          _cache[16] || (_cache[16] = _createElementVNode("thead", { class: "grid grid-cols-5 p-4 hidden sm:grid" }, [
            _createElementVNode("th", { class: "col-span-1" }, "Nome"),
            _createElementVNode("th", { class: "col-span-1" }, "Documento"),
            _createElementVNode("th", { class: "col-span-1" }, "Data de nascimento"),
            _createElementVNode("th", { class: "col-span-1" }, "Porcentagem"),
            _createElementVNode("th", { class: "col-span-1" }, "Ações")
          ], -1)),
          _createElementVNode("tbody", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(partners.value, (partner) => {
              return (_openBlock(), _createElementBlock("tr", _hoisted_5, [
                _createElementVNode("td", _hoisted_6, _toDisplayString(partner.name), 1),
                _createElementVNode("td", _hoisted_7, _toDisplayString(partner.document), 1),
                _createElementVNode("td", _hoisted_8, _toDisplayString(new Date(partner.dateOfBirth!).toLocaleDateString()), 1),
                _createElementVNode("td", _hoisted_9, _toDisplayString(partner.percentage), 1),
                _createElementVNode("td", _hoisted_10, [
                  _createElementVNode("button", {
                    class: "hover:stroke-primary",
                    onClick: ($event: any) => {isEditPartnerModalOpen.value = true; partnerToUpdate.value = { id: partner.id, name: partner.name, document: partner.document, dateOfBirth: partner.dateOfBirth, percentage: partner.percentage }}
                  }, _cache[14] || (_cache[14] = [
                    _createElementVNode("svg", {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "25",
                      height: "24",
                      viewBox: "0 0 25 24",
                      fill: "none"
                    }, [
                      _createElementVNode("path", {
                        d: "M11.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V13",
                        stroke: "#171717",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        class: "hover:parent:stroke-primary transition-all duration-200"
                      }),
                      _createElementVNode("path", {
                        d: "M16.5399 3.02001L8.65988 10.9C8.35988 11.2 8.05988 11.79 7.99988 12.22L7.56988 15.23C7.40988 16.32 8.17988 17.08 9.26988 16.93L12.2799 16.5C12.6999 16.44 13.2899 16.14 13.5999 15.84L21.4799 7.96001C22.8399 6.60001 23.4799 5.02001 21.4799 3.02001C19.4799 1.02001 17.8999 1.66001 16.5399 3.02001Z",
                        stroke: "#171717",
                        "stroke-width": "1.5",
                        "stroke-miterlimit": "10",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        class: "hover:parent:stroke-primary transition-all duration-200"
                      }),
                      _createElementVNode("path", {
                        d: "M15.4099 4.15002C16.0799 6.54002 17.9499 8.41002 20.3499 9.09002",
                        stroke: "#171717",
                        "stroke-width": "1.5",
                        "stroke-miterlimit": "10",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        class: "hover:parent:stroke-primary transition-all duration-200"
                      })
                    ], -1)
                  ]), 8, _hoisted_11),
                  _createElementVNode("button", {
                    class: "hover:stroke-primary",
                    onClick: ($event: any) => {isConfirmDeleteModalOpen.value = true; partnerToUpdate.value = partner}
                  }, _cache[15] || (_cache[15] = [
                    _createElementVNode("svg", {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24",
                      fill: "none"
                    }, [
                      _createElementVNode("path", {
                        fill: "none",
                        d: "M21 5.98C17.67 5.65 14.32 5.48 10.98 5.48C9 5.48 7.02 5.58 5.04 5.78L3 5.98M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97M18.85 9.14L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79C6 22 5.91 20.78 5.8 19.21L5.15 9.14M10.33 16.5H13.66M9.5 12.5H14.5",
                        stroke: "#292F31",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        class: "hover:parent:stroke-primary transition-all duration-200"
                      })
                    ], -1)
                  ]), 8, _hoisted_12)
                ])
              ]))
            }), 256))
          ])
        ])),
    _createVNode(MyModal, {
      height: '580px',
      width: '40rem',
      isOpen: isCreatePartnersModalOpen.value,
      toggleModal: (v) => (isCreatePartnersModalOpen.value = v)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _cache[18] || (_cache[18] = _createElementVNode("div", null, [
              _createElementVNode("h4", { class: "font-bold text-2xl" }, "Cadastrar sócio"),
              _createElementVNode("p", { class: "mb-6 text-94 text-sm" }, "Campos com (*) são obrigatórios")
            ], -1)),
            _createElementVNode("button", {
              class: "transition duration-200 hover:text-primary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (isCreatePartnersModalOpen.value = false))
            }, [
              (_openBlock(), _createElementBlock("svg", _hoisted_15, _cache[17] || (_cache[17] = [
                _createElementVNode("path", {
                  d: "M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z",
                  style: {"fill":"currentcolor"}
                }, null, -1)
              ])))
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _cache[19] || (_cache[19] = _createElementVNode("label", {
                  for: "",
                  class: "font-semibold text-lg input-primary"
                }, "Nome", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((partnerForm.value.name) = $event)),
                  placeholder: "ex: João",
                  class: "border border-d5 p-3 rounded-xl w-full input-primary"
                }, null, 512), [
                  [_vModelText, partnerForm.value.name]
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _cache[20] || (_cache[20] = _createElementVNode("label", {
                  for: "",
                  class: "font-semibold text-lg input-primary"
                }, "CPF/CNPJ", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "tel",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((partnerForm.value.document) = $event)),
                  placeholder: "ex: 000.000.000-00",
                  class: "border border-d5 p-3 rounded-xl w-full input-primary"
                }, null, 512), [
                  [_vModelText, partnerForm.value.document],
                  [_unref(vMaska), (partnerForm.value.document?.length ?? 0) <= 14 ? '###.###.###-###' : '##.###.###/####-##']
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _cache[21] || (_cache[21] = _createElementVNode("label", {
                  for: "",
                  class: "font-semibold text-lg input-primary"
                }, "Porcentagem", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "number",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((partnerForm.value.percentage) = $event)),
                  placeholder: "ex: 30",
                  class: "border border-d5 p-3 rounded-xl w-full input-primary"
                }, null, 512), [
                  [_vModelText, partnerForm.value.percentage]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _cache[22] || (_cache[22] = _createElementVNode("label", {
                for: "",
                class: "font-semibold text-lg input-primary"
              }, "Data de nascimento", -1)),
              _createVNode(FxDatePicker, {
                min: { day: 1, month: 1, year: 1930 },
                name: 'birthDate',
                setValue: (date) => partnerForm.value.dateOfBirth = date
              }, null, 8, ["setValue"])
            ])
          ]),
          _createElementVNode("button", {
            disabled: !partnerForm.value.name || (partnerForm.value.document?.length !== 14 && partnerForm.value.document?.length !== 18) || !partnerForm.value.dateOfBirth || !partnerForm.value.percentage,
            onClick: createPartners,
            class: "bg-primary px-8 py-3 text-white flex items-center gap-2 rounded-3xl font-bold mt-12 w-full justify-center"
          }, _cache[23] || (_cache[23] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z",
                stroke: "#fff",
                "stroke-width": "1.5",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }),
              _createElementVNode("path", {
                d: "M3.40991 22C3.40991 18.13 7.25991 15 11.9999 15C12.9599 15 13.8899 15.13 14.7599 15.37",
                stroke: "#fff",
                "stroke-width": "1.5",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }),
              _createElementVNode("path", {
                d: "M22 18C22 18.32 21.96 18.63 21.88 18.93C21.79 19.33 21.63 19.72 21.42 20.06C20.73 21.22 19.46 22 18 22C16.97 22 16.04 21.61 15.34 20.97C15.04 20.71 14.78 20.4 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.92 14.43 15.93 15.13 15.21C15.86 14.46 16.88 14 18 14C19.18 14 20.25 14.51 20.97 15.33C21.61 16.04 22 16.98 22 18Z",
                stroke: "#fff",
                "stroke-width": "1.5",
                "stroke-miterlimit": "10",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }),
              _createElementVNode("path", {
                d: "M19.49 17.98H16.51",
                stroke: "#fff",
                "stroke-width": "1.5",
                "stroke-miterlimit": "10",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }),
              _createElementVNode("path", {
                d: "M18 16.52V19.51",
                stroke: "#fff",
                "stroke-width": "1.5",
                "stroke-miterlimit": "10",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              })
            ], -1),
            _createTextVNode(" Cadastrar sócio ")
          ]), 8, _hoisted_22)
        ])
      ]),
      _: 1
    }, 8, ["isOpen", "toggleModal"]),
    _createVNode(MyModal, {
      height: '580px',
      width: '40rem',
      isOpen: isEditPartnerModalOpen.value,
      toggleModal: (v) => (isEditPartnerModalOpen.value = v)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("div", _hoisted_24, [
            _cache[25] || (_cache[25] = _createElementVNode("div", null, [
              _createElementVNode("h4", { class: "font-bold text-2xl" }, "Editar sócio"),
              _createElementVNode("p", { class: "mb-6 text-94 text-sm" }, "Campos com (*) são obrigatórios")
            ], -1)),
            _createElementVNode("button", {
              class: "transition duration-200 hover:text-primary",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (isEditPartnerModalOpen.value = false))
            }, [
              (_openBlock(), _createElementBlock("svg", _hoisted_25, _cache[24] || (_cache[24] = [
                _createElementVNode("path", {
                  d: "M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z",
                  style: {"fill":"currentcolor"}
                }, null, -1)
              ])))
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _cache[26] || (_cache[26] = _createElementVNode("label", {
                  for: "",
                  class: "font-semibold text-lg input-primary"
                }, "Nome", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((partnerToUpdate.value.name) = $event)),
                  placeholder: "ex: João",
                  class: "border border-d5 p-3 rounded-xl w-full input-primary"
                }, null, 512), [
                  [_vModelText, partnerToUpdate.value.name]
                ])
              ]),
              _createElementVNode("div", _hoisted_29, [
                _cache[27] || (_cache[27] = _createElementVNode("label", {
                  for: "",
                  class: "font-semibold text-lg input-primary"
                }, "CPF/CNPJ", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "tel",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((partnerToUpdate.value.document) = $event)),
                  placeholder: "ex: 000.000.000-00",
                  class: "border border-d5 p-3 rounded-xl w-full input-primary"
                }, null, 512), [
                  [_vModelText, partnerToUpdate.value.document],
                  [_unref(vMaska), (partnerToUpdate.value.document?.length ?? 0) <= 14 ? '###.###.###-###' : '##.###.###/####-##']
                ])
              ]),
              _createElementVNode("div", _hoisted_30, [
                _cache[28] || (_cache[28] = _createElementVNode("label", {
                  for: "",
                  class: "font-semibold text-lg input-primary"
                }, "Porcentagem", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "number",
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((partnerToUpdate.value.percentage) = $event)),
                  placeholder: "ex: 30",
                  class: "border border-d5 p-3 rounded-xl w-full input-primary"
                }, null, 512), [
                  [_vModelText, partnerToUpdate.value.percentage]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_31, [
              _cache[29] || (_cache[29] = _createElementVNode("label", {
                for: "",
                class: "font-semibold text-lg input-primary"
              }, "Data de nascimento", -1)),
              _createVNode(FxDatePicker, {
                min: { day: 1, month: 1, year: 1930 },
                name: 'birthDate',
                setValue: (date) => partnerToUpdate.value.dateOfBirth = date
              }, null, 8, ["setValue"])
            ])
          ]),
          _createElementVNode("button", {
            onClick: updatePartner,
            disabled: !partnerToUpdate.value.name || (partnerToUpdate.value.document?.length !== 14 && partnerToUpdate.value.document?.length !== 18) || !partnerToUpdate.value.dateOfBirth || !partnerToUpdate.value.percentage,
            class: "bg-primary px-8 py-3 text-white flex items-center gap-2 rounded-3xl font-bold mt-12 w-full justify-center"
          }, _cache[30] || (_cache[30] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none"
            }, [
              _createElementVNode("path", {
                d: "M15.02 3.01001C14.18 2.37001 13.14 2 12 2C9.24 2 7 4.24 7 7C7 9.76 9.24 12 12 12C14.76 12 17 9.76 17 7",
                stroke: "#fff",
                "stroke-width": "1.5",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }),
              _createElementVNode("path", {
                d: "M19.2101 15.74L15.67 19.2801C15.53 19.4201 15.4 19.68 15.37 19.87L15.18 21.22C15.11 21.71 15.45 22.05 15.94 21.98L17.29 21.79C17.48 21.76 17.75 21.63 17.88 21.49L21.42 17.95C22.03 17.34 22.32 16.63 21.42 15.73C20.53 14.84 19.8201 15.13 19.2101 15.74Z",
                stroke: "#fff",
                "stroke-width": "1.5",
                "stroke-miterlimit": "10",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }),
              _createElementVNode("path", {
                d: "M18.7002 16.25C19.0002 17.33 19.8402 18.17 20.9202 18.47",
                stroke: "#fff",
                "stroke-width": "1.5",
                "stroke-miterlimit": "10",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }),
              _createElementVNode("path", {
                d: "M3.40991 22C3.40991 18.13 7.25994 15 11.9999 15C13.0399 15 14.0399 15.15 14.9699 15.43",
                stroke: "#fff",
                "stroke-width": "1.5",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              })
            ], -1),
            _createTextVNode(" Salvar alterações ")
          ]), 8, _hoisted_32)
        ])
      ]),
      _: 1
    }, 8, ["isOpen", "toggleModal"]),
    _createVNode(MyModal, {
      width: '330px !important',
      height: '260px !important',
      isOpen: isConfirmDeleteModalOpen.value,
      "class-name": 'confirm-delete',
      toggleModal: (v) => isConfirmDeleteModalOpen.value = v,
      zIndex: 100
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _cache[31] || (_cache[31] = _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            width: "52",
            height: "52",
            viewBox: "0 0 52 52",
            fill: "none"
          }, [
            _createElementVNode("path", {
              d: "M42.2722 12.6747L29.4021 5.24301C27.3005 4.02967 24.7005 4.02967 22.5771 5.24301L9.72881 12.6747C7.62715 13.888 6.32715 16.1413 6.32715 18.5897V33.4097C6.32715 35.8363 7.62715 38.0897 9.72881 39.3247L22.5988 46.7563C24.7005 47.9697 27.3005 47.9697 29.4238 46.7563L42.2938 39.3247C44.3955 38.1113 45.6955 35.858 45.6955 33.4097V18.5897C45.6738 16.1413 44.3738 13.9097 42.2722 12.6747ZM24.3755 16.7913C24.3755 15.903 25.1121 15.1663 26.0005 15.1663C26.8888 15.1663 27.6255 15.903 27.6255 16.7913V28.1663C27.6255 29.0547 26.8888 29.7913 26.0005 29.7913C25.1121 29.7913 24.3755 29.0547 24.3755 28.1663V16.7913ZM27.9938 36.0313C27.8855 36.2913 27.7338 36.5297 27.5388 36.7463C27.1271 37.158 26.5855 37.3747 26.0005 37.3747C25.7188 37.3747 25.4371 37.3097 25.1771 37.2013C24.8955 37.093 24.6788 36.9413 24.4621 36.7463C24.2671 36.5297 24.1155 36.2913 23.9855 36.0313C23.8771 35.7713 23.8338 35.4897 23.8338 35.208C23.8338 34.6447 24.0505 34.0813 24.4621 33.6697C24.6788 33.4747 24.8955 33.323 25.1771 33.2147C25.9788 32.868 26.9321 33.063 27.5388 33.6697C27.7338 33.8863 27.8855 34.103 27.9938 34.3847C28.1021 34.6447 28.1671 34.9263 28.1671 35.208C28.1671 35.4897 28.1021 35.7713 27.9938 36.0313Z",
              fill: "#A30711"
            })
          ], -1)),
          _cache[32] || (_cache[32] = _createElementVNode("h1", null, "Cuidado!", -1)),
          _cache[33] || (_cache[33] = _createElementVNode("h2", null, "Deseja mesmo deletar esse endereço?", -1)),
          _createElementVNode("section", null, [
            _createElementVNode("input", {
              autocomplete: "off",
              onClick: _cache[9] || (_cache[9] = ($event: any) => {isConfirmDeleteModalOpen.value = false; deletePartner();}),
              type: "button",
              value: "Sim"
            }),
            _createElementVNode("button", {
              onClick: _cache[10] || (_cache[10] = ($event: any) => {isConfirmDeleteModalOpen.value = false;})
            }, "Cancelar")
          ])
        ])
      ]),
      _: 1
    }, 8, ["isOpen", "toggleModal"])
  ]))
}
}

})