import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

import { watch } from 'vue'

  
export default /*@__PURE__*/_defineComponent({
  __name: 'MyModal',
  props: {
    isOpen: { type: Boolean },
    height: {},
    width: {},
    toggleModal: { type: Function },
    zIndex: {},
    className: {}
  },
  setup(__props: any) {

  const props = __props

  watch(
    () => props.isOpen,
    (newValue) => {
      if (newValue) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    },
  )

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      style: _normalizeStyle({ height: _ctx.height, width: _ctx.width, zIndex: _ctx.zIndex }),
      class: _normalizeClass(["transition-top overflow-auto duration-300 fixed bg-white box-shadow z-20 sm:rounded-xl modal left-1/2 -translate-x-1/2", [_ctx.isOpen ? ('open top-1/2 -translate-y-1/2 sm:bottom-0 opacity-100 ' + _ctx.className ?? '') : ('top-[-100%] opacity-0 ' + _ctx.className ?? '')]])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 6),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "fixed top-0 left-0 w-full bottom-0 h-full bg-black bg-opacity-50",
          style: _normalizeStyle({ zIndex: (_ctx.zIndex ?? 2) - 1 }),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleModal(!_ctx.isOpen)))
        }, null, 4))
      : _createCommentVNode("", true)
  ], 64))
}
}

})