import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pagination" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "active" }
const _hoisted_4 = {
  key: 3,
  disabled: ""
}
const _hoisted_5 = ["disabled"]

import { computed } from 'vue'

  interface IPaginationData {
    total: number
    lastPage: number
    prevPage?: number
    nextPage: number
    perPage: number
    currentPage: number
    from: number
    to: number
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'Pagination',
  props: {
    data: {},
    paginate: { type: Function }
  },
  setup(__props: any) {

  const props = __props
  const displayNextToLast = computed(() => !!(props.data.lastPage - 1) && (props.data.lastPage - 1) !== props.data.nextPage && (props.data.lastPage - 1) !== props.data.currentPage && (props.data.lastPage - 1) !== props.data.prevPage)
  const displayLastPage = computed(() => props.data.lastPage !== props.data.nextPage && props.data.lastPage !== props.data.currentPage)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      disabled: !_ctx.data.prevPage,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.paginate(_ctx.data.prevPage!))),
      class: "passers"
    }, _toDisplayString(_ctx.data.prevPage), 9, _hoisted_2),
    (_ctx.data.prevPage !== 1 && _ctx.data.currentPage !== 1)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.paginate(1)))
        }, " 1 "))
      : _createCommentVNode("", true),
    (_ctx.data.prevPage)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.paginate(_ctx.data.prevPage!)))
        }, _toDisplayString(_ctx.data.prevPage), 1))
      : _createCommentVNode("", true),
    _createElementVNode("button", _hoisted_3, _toDisplayString(_ctx.data.currentPage), 1),
    (_ctx.data.nextPage)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.paginate(_ctx.data.nextPage)))
        }, _toDisplayString(_ctx.data.nextPage), 1))
      : _createCommentVNode("", true),
    (displayLastPage.value)
      ? (_openBlock(), _createElementBlock("button", _hoisted_4, " ... "))
      : _createCommentVNode("", true),
    (displayNextToLast.value)
      ? (_openBlock(), _createElementBlock("button", {
          key: 4,
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.paginate((_ctx.data.lastPage - 1))))
        }, _toDisplayString((_ctx.data.lastPage - 1)), 1))
      : _createCommentVNode("", true),
    (displayLastPage.value)
      ? (_openBlock(), _createElementBlock("button", {
          key: 5,
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.paginate(_ctx.data.lastPage)))
        }, _toDisplayString(_ctx.data.lastPage), 1))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      disabled: !_ctx.data.nextPage,
      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.paginate(_ctx.data.nextPage!))),
      class: "passers"
    }, _toDisplayString(_ctx.data.nextPage), 9, _hoisted_5)
  ]))
}
}

})