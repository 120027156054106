import router from '@/router'
import axios from 'axios'
import { apiBaseUrl, isLoading } from '@/global/state/GlobalState'

// Obtém o subdomínio atual da URL
const currentSubdomain = window.location.hostname.split('.')[0]

// Constrói a URL da API usando o subdomínio atual
// const apiBaseURL = `http://${currentSubdomain}.${process.env.VUE_APP_API_BASE_URL}/api`;
const apiBaseURL = `${apiBaseUrl}`

const axiosClient = axios.create({
  baseURL: apiBaseURL,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosClient.interceptors.request.use((request: any) => {
  isLoading.value = true
  const token = localStorage.getItem('token')
  if (token) {
    request.headers.Authorization = 'Bearer ' + token
  }

  return request
})

axiosClient.interceptors.response.use(
  (response: any) => {
    isLoading.value = false
    return response
  },
  (error: any) => {
    isLoading.value = false
    if (error.response && error.response?.status === 404) {
      router.push({ name: 'nonexistent-domain' }) // Substitua '404' pelo nome da rota da sua página 404
    }

    if ((error.response && error.response?.status === 403) || error.response?.status === 401) {
      localStorage.removeItem('token')
      router.push({ name: 'login' })
    }

    return Promise.reject(error)
  },
)

export default axiosClient
