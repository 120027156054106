<template>
  <div>
    <input class="hidden chk" type="checkbox" :id="id" :checked="checked" @change="onChange" />
    <label :for="id" class="switch">
      <span class="slider"></span>
    </label>
  </div>
</template>

<script setup lang="ts">
  import { defineProps } from 'vue'

  const props = defineProps<{
    id: string
    checked: boolean
  }>()

  const emit = defineEmits<{
    (event: 'update:modelValue', value: boolean): void
  }>()

  function onChange(e) {
    const checked = (e.target as HTMLInputElement).checked
    emit('update:modelValue', checked)
  }
</script>

<style lang="scss">
  .switch {
    position: relative;
    background-color: #777;
    width: 50px;
    height: 24px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding: 5px;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
  }

  .slider {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    transition: all 0.1s ease-in-out;
  }

  .chk:checked ~ .switch {
    background-color: var(--primary-color);
  }

  .chk:checked ~ .switch .slider {
    transform: translateX(21px);
  }
</style>
