import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "main-layout" }
const _hoisted_2 = { class: "flex flex-col gap-3" }
const _hoisted_3 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "15",
  height: "8",
  viewBox: "0 0 15 8",
  stroke: "none",
  style: {"transform":"rotate(270deg)"}
}
const _hoisted_4 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "15",
  height: "8",
  viewBox: "0 0 15 8",
  stroke: "none",
  style: {"transform":"rotate(270deg)"}
}
const _hoisted_5 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "15",
  height: "8",
  viewBox: "0 0 15 8",
  stroke: "none",
  style: {"transform":"rotate(270deg)"}
}
const _hoisted_6 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "15",
  height: "8",
  viewBox: "0 0 15 8",
  stroke: "none",
  style: {"transform":"rotate(270deg)"}
}
const _hoisted_7 = {
  id: "view",
  style: {"width":"95%"}
}

import { isLoading, isSidebarOpen } from '@/global/state/GlobalState'
  import CentralSpinner from '../../components/loaders/CentralSpinner.vue'
  import router from '@/router'
  import { useToast } from 'vue-toastification'
  import { ref } from 'vue'

  
export default /*@__PURE__*/_defineComponent({
  __name: 'AdminLayout',
  setup(__props) {

  const toast = useToast()
  const isComplianceMenuOpen = ref<boolean>(false)

  function logout() {
    localStorage.removeItem('token')
    toast.success('Deslogado com sucesso')
    router.push({ name: 'login' })
  }

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("aside", {
      class: _normalizeClass(["main-layout__sidebar font-inter p-4 flex flex-col gap-6", _unref(isSidebarOpen) ? 'open' : ''])
    }, [
      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "flex items-center gap-4 pb-5 pt-4" }, [
        _createElementVNode("img", {
          class: "main-layout__sidebar--logo",
          src: "/img/logo/hoopay_logo.png"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_RouterLink, {
          to: { name: 'organizations' },
          class: _normalizeClass([{
            'router-link-active': _ctx.$route.path.includes('edit-organization'),
          }, "flex items-center justify-between gap-4 hover:stroke-primary"]),
          href: ""
        }, {
          default: _withCtx(() => [
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex items-center gap-4" }, [
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none"
              }, [
                _createElementVNode("path", {
                  d: "M3.00977 11.22V15.71C3.00977 20.2 4.80977 22 9.29977 22H14.6898C19.1798 22 20.9798 20.2 20.9798 15.71V11.22",
                  stroke: "#171717",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  class: "hover:parent:stroke-primary"
                }),
                _createElementVNode("path", {
                  d: "M12 12C13.83 12 15.18 10.51 15 8.68L14.34 2H9.66999L8.99999 8.68C8.81999 10.51 10.17 12 12 12Z",
                  stroke: "#171717",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  class: "hover:parent:stroke-primary"
                }),
                _createElementVNode("path", {
                  d: "M18.3098 12C20.3298 12 21.8098 10.36 21.6098 8.35L21.3298 5.6C20.9698 3 19.9698 2 17.3498 2H14.2998L14.9998 9.01C15.1698 10.66 16.6598 12 18.3098 12Z",
                  stroke: "#171717",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  class: "hover:parent:stroke-primary"
                }),
                _createElementVNode("path", {
                  d: "M5.63988 12C7.28988 12 8.77988 10.66 8.93988 9.01L9.15988 6.8L9.63988 2H6.58988C3.96988 2 2.96988 3 2.60988 5.6L2.33988 8.35C2.13988 10.36 3.61988 12 5.63988 12Z",
                  stroke: "#171717",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  class: "hover:parent:stroke-primary"
                }),
                _createElementVNode("path", {
                  d: "M12 17C10.33 17 9.5 17.83 9.5 19.5V22H14.5V19.5C14.5 17.83 13.67 17 12 17Z",
                  stroke: "#171717",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  class: "hover:parent:stroke-primary"
                })
              ]),
              _createTextVNode(" Organizações ")
            ], -1)),
            (_openBlock(), _createElementBlock("svg", _hoisted_3, _cache[5] || (_cache[5] = [
              _createElementVNode("path", {
                d: "M7.5,105.14a1.085,1.085,0,0,1-.743-.288L.308,98.818a.939.939,0,0,1,0-1.39,1.1,1.1,0,0,1,1.486,0l5.707,5.34,5.707-5.339a1.1,1.1,0,0,1,1.485,0,.938.938,0,0,1,0,1.39l-6.45,6.035A1.085,1.085,0,0,1,7.5,105.14Z",
                transform: "translate(-0.001 -97.14)"
              }, null, -1)
            ])))
          ]),
          _: 1
        }, 8, ["class"]),
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'orders' }))),
          class: _normalizeClass([{ 'router-link-active': _ctx.$route.path.includes('orders') && _ctx.$route.query.compliance === undefined }, "flex items-center justify-between gap-4 hover:stroke-primary"]),
          href: ""
        }, [
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "flex items-center gap-4" }, [
            _createElementVNode("svg", {
              stroke: "#292f31",
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
              class: "svg request stroke"
            }, [
              _createElementVNode("path", {
                d: "M12 17.22V22M2 13H22M7.5 22H16.5M6.44 2H17.55C21.11 2 22 2.89 22 6.44V12.77C22 16.33 21.11 17.21 17.56 17.21H6.44C2.89 17.22 2 16.33 2 12.78V6.44C2 2.89 2.89 2 6.44 2Z",
                "stroke-width": "1.5",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                class: "hover:parent:stroke-primary"
              })
            ]),
            _createTextVNode(" Pedidos ")
          ], -1)),
          (_openBlock(), _createElementBlock("svg", _hoisted_4, _cache[7] || (_cache[7] = [
            _createElementVNode("path", {
              d: "M7.5,105.14a1.085,1.085,0,0,1-.743-.288L.308,98.818a.939.939,0,0,1,0-1.39,1.1,1.1,0,0,1,1.486,0l5.707,5.34,5.707-5.339a1.1,1.1,0,0,1,1.485,0,.938.938,0,0,1,0,1.39l-6.45,6.035A1.085,1.085,0,0,1,7.5,105.14Z",
              transform: "translate(-0.001 -97.14)"
            }, null, -1)
          ])))
        ], 2),
        _createVNode(_component_RouterLink, {
          to: { name: 'products' },
          class: "flex items-center justify-between gap-4 hover:stroke-primary",
          href: ""
        }, {
          default: _withCtx(() => [
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "flex items-center gap-4" }, [
              _createElementVNode("svg", {
                width: "24",
                height: "25",
                viewBox: "0 0 24 25",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
                class: "svg stroke-292"
              }, [
                _createElementVNode("path", {
                  d: "M3.17188 8.22656L12.0019 13.3366L20.7719 8.25656M12.0019 22.3966V13.3266",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  class: "hover:parent:stroke-primary"
                }),
                _createElementVNode("path", {
                  d: "M9.93062 3.26516L4.59063 6.23516C3.38063 6.90516 2.39062 8.58516 2.39062 9.96516V15.6152C2.39062 16.9952 3.38063 18.6752 4.59063 19.3452L9.93062 22.3152C11.0706 22.9452 12.9406 22.9452 14.0806 22.3152L19.4206 19.3452C20.6306 18.6752 21.6206 16.9952 21.6206 15.6152V9.96516C21.6206 8.58516 20.6306 6.90516 19.4206 6.23516L14.0806 3.26516C12.9306 2.62516 11.0706 2.62516 9.93062 3.26516Z",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  class: "hover:parent:stroke-primary"
                }),
                _createElementVNode("path", {
                  d: "M17.0017 14.0267V10.3667L7.51172 4.88672",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  class: "hover:parent:stroke-primary"
                })
              ]),
              _createTextVNode(" Produtos ")
            ], -1)),
            (_openBlock(), _createElementBlock("svg", _hoisted_5, _cache[9] || (_cache[9] = [
              _createElementVNode("path", {
                d: "M7.5,105.14a1.085,1.085,0,0,1-.743-.288L.308,98.818a.939.939,0,0,1,0-1.39,1.1,1.1,0,0,1,1.486,0l5.707,5.34,5.707-5.339a1.1,1.1,0,0,1,1.485,0,.938.938,0,0,1,0,1.39l-6.45,6.035A1.085,1.085,0,0,1,7.5,105.14Z",
                transform: "translate(-0.001 -97.14)"
              }, null, -1)
            ])))
          ]),
          _: 1
        }),
        _createElementVNode("div", null, [
          _createElementVNode("a", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (isComplianceMenuOpen.value = !isComplianceMenuOpen.value)),
            class: _normalizeClass(["flex items-center justify-between gap-4 hover:stroke-primary cursor-pointer", 
            _ctx.$route.path == '/compliance/organizations' || 
            (_ctx.$route.path.includes('orders') && _ctx.$route.query.compliance === '1') || 
            _ctx.$route.path.includes('organizations') && _ctx.$route.query.status === 'pending' ||
            isComplianceMenuOpen.value ? 'router-link-active' : ''])
          }, [
            _cache[12] || (_cache[12] = _createStaticVNode("<div class=\"flex items-center gap-4\"><div class=\"relative\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\"><path d=\"M10.4902 2.23006L5.50016 4.11006C4.35016 4.54006 3.41016 5.90006 3.41016 7.12006V14.5501C3.41016 15.7301 4.19016 17.2801 5.14016 17.9901L9.44016 21.2001C10.8502 22.2601 13.1702 22.2601 14.5802 21.2001L18.8802 17.9901C19.8302 17.2801 20.6102 15.7301 20.6102 14.5501V7.12006C20.6102 5.89006 19.6702 4.53006 18.5202 4.10006L13.5302 2.23006C12.6802 1.92006 11.3202 1.92006 10.4902 2.23006Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"hover:parent:stroke-primary\"></path><path d=\"M12 12.5C13.1046 12.5 14 11.6046 14 10.5C14 9.39543 13.1046 8.5 12 8.5C10.8954 8.5 10 9.39543 10 10.5C10 11.6046 10.8954 12.5 12 12.5Z\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"hover:parent:stroke-primary\"></path><path d=\"M12 12.5V15.5\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"hover:parent:stroke-primary\"></path></svg></div> Compliance </div>", 1)),
            (_openBlock(), _createElementBlock("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              width: "15",
              height: "8",
              viewBox: "0 0 15 8",
              stroke: "none",
              class: "transition-transform duration-200",
              style: _normalizeStyle({
                transform: isComplianceMenuOpen.value ? 'rotate(180deg)' : '',
              })
            }, _cache[11] || (_cache[11] = [
              _createElementVNode("path", {
                d: "M7.5,105.14a1.085,1.085,0,0,1-.743-.288L.308,98.818a.939.939,0,0,1,0-1.39,1.1,1.1,0,0,1,1.486,0l5.707,5.34,5.707-5.339a1.1,1.1,0,0,1,1.485,0,.938.938,0,0,1,0,1.39l-6.45,6.035A1.085,1.085,0,0,1,7.5,105.14Z",
                transform: "translate(-0.001 -97.14)"
              }, null, -1)
            ]), 4))
          ], 2),
          _createElementVNode("div", {
            class: "grid transition-all duration-200",
            style: _normalizeStyle({ gridTemplateRows: isComplianceMenuOpen.value ? '1fr' : '0fr' })
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["overflow-hidden pl-4 transition-padding duration-200", isComplianceMenuOpen.value ? 'pt-2' : ''])
            }, [
              _createElementVNode("a", {
                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$router.push({ name: 'organizations', query: { status: 'pending' } })), ["stop"])),
                class: _normalizeClass([{ 'router-link-active': _ctx.$route.path.includes('organizations') && _ctx.$route.query.status === 'pending' }, "flex items-center justify-between gap-4 mb-2 hover:stroke-primary"]),
                href: ""
              }, _cache[13] || (_cache[13] = [
                _createElementVNode("div", { class: "flex items-center gap-2" }, [
                  _createElementVNode("div", { class: "bg-zinc-600 w-2 h-2 rounded-full mr-2" }),
                  _createTextVNode(" Organizações ")
                ], -1)
              ]), 2),
              _createElementVNode("a", {
                onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$router.push({ name: 'orders', query: { compliance: 1 } })), ["stop"])),
                class: _normalizeClass([{ 'router-link-active': _ctx.$route.path.includes('orders') && _ctx.$route.query.compliance === '1' }, "flex items-center justify-between gap-4 hover:stroke-primary"]),
                href: ""
              }, _cache[14] || (_cache[14] = [
                _createElementVNode("div", { class: "flex items-center gap-2" }, [
                  _createElementVNode("div", { class: "bg-zinc-600 w-2 h-2 rounded-full mr-2" }),
                  _createTextVNode(" Pedidos ")
                ], -1)
              ]), 2)
            ], 2)
          ], 4)
        ]),
        _createVNode(_component_RouterLink, {
          to: { name: 'withdrawals' },
          class: "flex items-center justify-between gap-4 hover:stroke-primary",
          href: ""
        }, {
          default: _withCtx(() => [
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "flex items-center gap-4" }, [
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                width: "24",
                height: "24",
                viewBox: "0 0 24 24",
                fill: "none"
              }, [
                _createElementVNode("path", {
                  d: "M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H6.26C4.19 22 2.5 20.31 2.5 18.24V11.51C2.5 9.44001 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z",
                  stroke: "#292f31",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  class: "hover:parent:stroke-primary"
                }),
                _createElementVNode("path", {
                  d: "M2.5 12.4101V7.8401C2.5 6.6501 3.23 5.59006 4.34 5.17006L12.28 2.17006C13.52 1.70006 14.85 2.62009 14.85 3.95009V7.75008",
                  stroke: "#292f31",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  class: "hover:parent:stroke-primary"
                }),
                _createElementVNode("path", {
                  d: "M22.5588 13.9702V16.0302C22.5588 16.5802 22.1188 17.0302 21.5588 17.0502H19.5988C18.5188 17.0502 17.5288 16.2602 17.4388 15.1802C17.3788 14.5502 17.6188 13.9602 18.0388 13.5502C18.4088 13.1702 18.9188 12.9502 19.4788 12.9502H21.5588C22.1188 12.9702 22.5588 13.4202 22.5588 13.9702Z",
                  stroke: "#292f31",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  class: "hover:parent:stroke-primary"
                }),
                _createElementVNode("path", {
                  d: "M7 12H14",
                  stroke: "#292f31",
                  "stroke-width": "1.5",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  class: "hover:parent:stroke-primary"
                })
              ]),
              _createTextVNode(" Liquidação ")
            ], -1)),
            (_openBlock(), _createElementBlock("svg", _hoisted_6, _cache[15] || (_cache[15] = [
              _createElementVNode("path", {
                d: "M7.5,105.14a1.085,1.085,0,0,1-.743-.288L.308,98.818a.939.939,0,0,1,0-1.39,1.1,1.1,0,0,1,1.486,0l5.707,5.34,5.707-5.339a1.1,1.1,0,0,1,1.485,0,.938.938,0,0,1,0,1.39l-6.45,6.035A1.085,1.085,0,0,1,7.5,105.14Z",
                transform: "translate(-0.001 -97.14)",
                class: "hover:parent:fill-primary"
              }, null, -1)
            ])))
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _cache[18] || (_cache[18] = _createElementVNode("h6", null, "Seus dados", -1)),
        _createElementVNode("div", null, [
          _createElementVNode("a", {
            onClick: logout,
            class: "flex justify-between items-center gap-4 cursor-pointer hover:stroke-primary"
          }, _cache[17] || (_cache[17] = [
            _createStaticVNode("<div class=\"flex items-center gap-4\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\"><path d=\"M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"hover:parent:stroke-primary\"></path><path d=\"M15 12H3.62\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"hover:parent:stroke-primary\"></path><path d=\"M5.85 8.65002L2.5 12L5.85 15.35\" stroke=\"#171717\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" class=\"hover:parent:stroke-primary\"></path></svg> Sair </div>", 1)
          ]))
        ])
      ])
    ], 2),
    _createElementVNode("main", null, [
      _createElementVNode("button", {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (isSidebarOpen.value = !_unref(isSidebarOpen))),
        class: "hidden fixed top-4 left-4 open-sidebar-btn"
      }, _cache[20] || (_cache[20] = [
        _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none"
        }, [
          _createElementVNode("path", {
            d: "M3 7H21",
            stroke: "#171717",
            "stroke-width": "1.5",
            "stroke-linecap": "round"
          }),
          _createElementVNode("path", {
            d: "M3 12H21",
            stroke: "#171717",
            "stroke-width": "1.5",
            "stroke-linecap": "round"
          }),
          _createElementVNode("path", {
            d: "M3 17H21",
            stroke: "#171717",
            "stroke-width": "1.5",
            "stroke-linecap": "round"
          })
        ], -1)
      ])),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_RouterView)
      ])
    ]),
    (_unref(isLoading))
      ? (_openBlock(), _createBlock(CentralSpinner, {
          key: 0,
          zIndex: 99
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})