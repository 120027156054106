<template>
  <div class="font-inter flex flex-col gap-8">
    <div class="grid grid-cols-12 gap-4 products__filter">
      <div class="col-span-12 md:col-span-6 lg:col-span-2 border border-f5 rounded-xl p-6 flex flex-col gap-6">
        <img src="/assets/img/integrations/iopay-logo.png" alt="" srcset="">
        <button class="rounded-3xl bg-f5 py-2 flex gap-2 px-4 text-sm items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 3.11 16.44 3.11 16.44M3.11 16.44H7.63M3.11 16.44V21.44M2 12C2 6.48 6.44 2 12 2C18.67 2 22 7.56 22 7.56M22 7.56V2.56M22 7.56H17.56" stroke="#171717" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Sincronizar/Atualizar
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
 
</script>

<style lang="scss">
  
</style>
