<template>
  <!-- pagination  -->
  <div class="pagination">
    <button
      :disabled="!data.prevPage"
      @click="paginate(data.prevPage!)"
      class="passers"
    >
      {{ data.prevPage }}
    </button>
    <button @click="paginate(1)" v-if="data.prevPage !== 1 && data.currentPage !== 1" >
      1
    </button>
    <button @click="paginate(data.prevPage!)" v-if="data.prevPage" >
      {{ data.prevPage }}
    </button>
    <button
      class="active"
    >
      {{ data.currentPage }}
    </button>
    <button @click="paginate(data.nextPage)" v-if="data.nextPage" >
      {{ data.nextPage }}
    </button>
    <button v-if="displayLastPage" disabled >
      ...
    </button>
    <button @click="paginate((data.lastPage - 1))" v-if="displayNextToLast" >
      {{ (data.lastPage - 1) }}
    </button>
    <button @click="paginate(data.lastPage)" v-if="displayLastPage" >
      {{ data.lastPage }}
    </button>
    <button
      :disabled="!data.nextPage"
      @click="paginate(data.nextPage!)"
      class="passers"
    >
      {{ data.nextPage }}
    </button>
  </div>
  <!-- pagination  -->
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  interface IPaginationData {
    total: number
    lastPage: number
    prevPage?: number
    nextPage: number
    perPage: number
    currentPage: number
    from: number
    to: number
  }

  const props = defineProps<{ data: IPaginationData, paginate: (page: number) => void }>()
  const displayNextToLast = computed(() => !!(props.data.lastPage - 1) && (props.data.lastPage - 1) !== props.data.nextPage && (props.data.lastPage - 1) !== props.data.currentPage && (props.data.lastPage - 1) !== props.data.prevPage)
  const displayLastPage = computed(() => props.data.lastPage !== props.data.nextPage && props.data.lastPage !== props.data.currentPage)
</script>

<style scoped lang="scss">
  /* Pagination */
.pagination{
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 10px;
  margin: 1rem 0 4rem auto;
  button{
      width: 50px;
      min-width: 35px;
      height: 40px;
      min-height: 35px;
      border-radius: 8px;
      background: #e4e0ec;
      color: #090909;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      transition: background .2s, color .2s;
      &:hover{
          color: $primary-color;
      }
      &.active{
          background: $primary-color;
          color: #FFF;
      }
      &.passers{
          overflow: hidden;
          background: transparent;
          color: transparent;
          position: relative;
          &:disabled{
              cursor: not-allowed !important;
              &::before{
                  border-color: #D3D3D3 !important;
              }
          }
          &:hover{
              &::before{
                  border-color: $primary-color;
              }
          }
          &::before{
              content: '';
              width: 10px;
              height: 10px;
              border-top: 2px solid #090909;
              border-left: 2px solid #090909;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              border-radius: 1px;
              transition: border-color .2s;
          }
          &:first-child{
              &::before{
                  transform: rotate(-45deg);
              }
          }
          &:last-child{
              &::before{
                  transform: rotate(135deg);
              }
          }
      }
  }
}
</style>
