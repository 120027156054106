import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "font-inter flex flex-col gap-8 products" }
const _hoisted_2 = { class: "grid grid-cols-12 gap-4 products__filter" }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = { class: "flex flex-col gap-4" }
const _hoisted_5 = { class: "grid grid-cols-7 border rounded-xl p-4 product-tr" }
const _hoisted_6 = { class: "col-span-1" }
const _hoisted_7 = { class: "col-span-1" }
const _hoisted_8 = { class: "col-span-1" }
const _hoisted_9 = { class: "col-span-1" }
const _hoisted_10 = { class: "col-span-1" }
const _hoisted_11 = { class: "col-span-1" }

import { onMounted, ref } from 'vue'
  import axiosClient from '@/api/axios'
  import Pagination from '@/components/pagination/Pagination.vue'

  
export default /*@__PURE__*/_defineComponent({
  __name: 'ProductsView',
  setup(__props) {

  const products = ref<any>([])

  function getProducts(): void {
    axiosClient.get('/administrator/organizations/products').then((res: any) => {
      products.value = res.data
    })
  }

  onMounted(() => {
    getProducts()
  })

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("div", null, [
      _createElementVNode("h1", { class: "text-3xl font-bold pb-2 font-nunito" }, "Gerenciar produtos"),
      _createElementVNode("p", { class: "text-gray-500 text-sm" }, "Adicione, edite e exclua produtos")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
        class: "rounded-xl w-full relative col-span-7 products__filter__search"
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("input", {
          type: "text",
          placeholder: "Busque pelo nome do produto",
          class: "rounded-xl input-primary w-full h-full p-4 py-3 bg-f5 pl-11"
        }, null, -1),
        _createElementVNode("svg", {
          class: "absolute top-1/2 -translate-y-1/2 left-4",
          xmlns: "http://www.w3.org/2000/svg",
          width: "16",
          height: "16",
          viewBox: "0 0 16 16",
          fill: "none"
        }, [
          _createElementVNode("path", {
            d: "M0.0247006 7.87823C0.162935 9.46532 0.887052 10.9532 2.06688 12.0735C3.24671 13.1938 4.80475 13.8732 6.46033 13.9891C8.11592 14.1051 9.76012 13.6501 11.0972 12.7061L14.2375 15.7092C14.4312 15.8952 14.6938 15.9996 14.9677 15.9996C15.2415 15.9996 15.5043 15.8953 15.6978 15.7093C15.8915 15.5235 16.0002 15.2714 16 15.0085C16.0002 14.7458 15.8915 14.4937 15.698 14.3078L12.5681 11.2947C13.9283 9.5308 14.2998 7.23853 13.5616 5.1613C12.8236 3.08439 11.0702 1.48725 8.86994 0.888013C6.66989 0.288729 4.30337 0.763496 2.53789 2.15874C0.772543 3.55382 -0.16685 5.69131 0.0244658 7.87791L0.0247006 7.87823ZM6.96867 2.62946C8.26895 2.62946 9.51591 3.12511 10.4353 4.00726C11.3547 4.88941 11.8713 6.08607 11.8713 7.33363C11.8713 8.58119 11.3547 9.77768 10.4353 10.6598C9.51591 11.542 8.2689 12.0376 6.96867 12.0376C5.66844 12.0376 4.42124 11.542 3.50184 10.6598C2.58245 9.77768 2.06587 8.58119 2.06587 7.33363C2.06587 6.08607 2.58245 4.88941 3.50184 4.00726C4.42124 3.12511 5.66844 2.62946 6.96867 2.62946Z",
            fill: "#676767"
          })
        ], -1)
      ]), 32)
    ]),
    _createElementVNode("table", _hoisted_3, [
      _cache[4] || (_cache[4] = _createElementVNode("thead", { class: "grid grid-cols-7 p-4" }, [
        _createElementVNode("th", { class: "col-span-1" }, "Nome"),
        _createElementVNode("th", { class: "col-span-1" }, "Preço"),
        _createElementVNode("th", { class: "col-span-1" }, "Vendas"),
        _createElementVNode("th", { class: "col-span-1" }, "Reembolsos"),
        _createElementVNode("th", { class: "col-span-1" }, "Cadastrado"),
        _createElementVNode("th", { class: "col-span-1" }, "Proprietário"),
        _createElementVNode("th", { class: "col-span-1" }, "Ações")
      ], -1)),
      _createElementVNode("tbody", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(products.value.data, (product) => {
          return (_openBlock(), _createElementBlock("tr", _hoisted_5, [
            _createElementVNode("td", _hoisted_6, _toDisplayString(product.title), 1),
            _createElementVNode("td", _hoisted_7, _toDisplayString(product.priceMin
                ? product.priceMin === product.priceMax
                  ? product.priceMin.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                  : product.priceMin.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }) +
                    ' a ' +
                    product.priceMax.toLocaleString('pt-BR', {
                      styçe: 'currency',
                      currency: 'BRL',
                    })
                : '-'), 1),
            _createElementVNode("td", _hoisted_8, _toDisplayString(product.totalSales), 1),
            _cache[3] || (_cache[3] = _createElementVNode("td", { class: "col-span-1" }, "@ 8", -1)),
            _createElementVNode("td", _hoisted_9, _toDisplayString(new Date(product.createdAt).toLocaleDateString('pt-BR')), 1),
            _createElementVNode("td", _hoisted_10, [
              _createVNode(_component_RouterLink, {
                class: "hover:text-primary transition-color duration-100",
                to: {
                name: 'organizations.edit',
                params: { organizationId: product.organizationId },
              }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(product.organizationName), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _createElementVNode("td", _hoisted_11, [
              _createVNode(_component_RouterLink, {
                class: "flex hover:stroke-primary",
                to: {
                name: 'products.edit',
                params: {
                  productId: product.id,
                  organizationId: product.organizationId,
                },
              }
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("svg", {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "25",
                    height: "24",
                    viewBox: "0 0 25 24",
                    fill: "none"
                  }, [
                    _createElementVNode("path", {
                      d: "M11.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V13",
                      stroke: "#171717",
                      "stroke-width": "1.5",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      class: "hover:parent:stroke-primary transition-all duration-200"
                    }),
                    _createElementVNode("path", {
                      d: "M16.5399 3.02001L8.65988 10.9C8.35988 11.2 8.05988 11.79 7.99988 12.22L7.56988 15.23C7.40988 16.32 8.17988 17.08 9.26988 16.93L12.2799 16.5C12.6999 16.44 13.2899 16.14 13.5999 15.84L21.4799 7.96001C22.8399 6.60001 23.4799 5.02001 21.4799 3.02001C19.4799 1.02001 17.8999 1.66001 16.5399 3.02001Z",
                      stroke: "#171717",
                      "stroke-width": "1.5",
                      "stroke-miterlimit": "10",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      class: "hover:parent:stroke-primary transition-all duration-200"
                    }),
                    _createElementVNode("path", {
                      d: "M15.4099 4.15002C16.0799 6.54002 17.9499 8.41002 20.3499 9.09002",
                      stroke: "#171717",
                      "stroke-width": "1.5",
                      "stroke-miterlimit": "10",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      class: "hover:parent:stroke-primary transition-all duration-200"
                    })
                  ], -1)
                ])),
                _: 2
              }, 1032, ["to"])
            ])
          ]))
        }), 256))
      ])
    ]),
    (products.value.pagination)
      ? (_openBlock(), _createBlock(Pagination, {
          key: 0,
          data: products.value.pagination,
          paginate: getProducts
        }, null, 8, ["data"]))
      : _createCommentVNode("", true)
  ]))
}
}

})