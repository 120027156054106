enum PaymentTypeEnum {
    ONCE = 'once',
    RECURRENT = 'recurrent',
}

export const paymentTypeTranslations = {
    [PaymentTypeEnum.ONCE]: 'Único',
    [PaymentTypeEnum.RECURRENT]: 'Recorrente',
}

export default PaymentTypeEnum