<template>
  <div class="px-4 font-inter flex flex-col justify-center login flex items-center justify-center">
    <div class="login__container" style="width: 30rem">
      <div class="text-center pb-4">
        <img class="mx-auto mb-2" src="/img/logo/hoopay_logo.png" alt="" srcset="" />
        <!-- <h1 class="text-3xl font-bold font-quicksand">Área de login</h1> -->
        <p class="text-d5 text-sm text-gray-500">Logue no no sistema administrativo</p>
      </div>
      <form @submit.prevent="" action="" class="flex flex-col gap-4 my-auto">
        <div class="flex flex-col gap-2">
          <label class="text-md font-medium" for="email">
            Email
            <span class="text-red-500">*</span>
          </label>
          <div class="relative">
            <svg class="absolute left-4 top-1/2 -translate-y-1/2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z"
                stroke="grey"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9" stroke="grey" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <input type="text" class="p-4 ps-12 border w-full rounded-xl input-primary text-sm md:text-md" placeholder="ex: seuemail@gmail.com" v-model="loginForm.email" id="email" />
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <label class="text-md font-medium" for="password">
            Senha
            <span class="text-red-500">*</span>
          </label>
          <div class="relative">
            <svg class="absolute left-4 top-1/2 -translate-y-1/2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M6.18014 22.7499C6.08014 22.7499 5.97014 22.7399 5.88014 22.7299L3.71014 22.4299C2.67014 22.2899 1.73014 21.3599 1.57014 20.2999L1.27014 18.1099C1.17014 17.4099 1.47014 16.4999 1.97014 15.9899L6.36014 11.5999C5.65014 8.75992 6.47014 5.75992 8.56014 3.68992C11.8001 0.459923 17.0701 0.449923 20.3201 3.68992C21.8901 5.25992 22.7501 7.34992 22.7501 9.56992C22.7501 11.7899 21.8901 13.8799 20.3201 15.4499C18.2201 17.5299 15.2301 18.3499 12.4101 17.6299L8.01014 22.0199C7.59014 22.4599 6.84014 22.7499 6.18014 22.7499ZM14.4301 2.75992C12.6801 2.75992 10.9401 3.41992 9.61014 4.74992C7.81014 6.53992 7.16014 9.15992 7.91014 11.5999C7.99014 11.8699 7.92014 12.1499 7.72014 12.3499L3.02014 17.0499C2.85014 17.2199 2.71014 17.6599 2.74014 17.8899L3.04014 20.0799C3.10014 20.4599 3.51014 20.8899 3.89014 20.9399L6.07014 21.2399C6.31014 21.2799 6.75014 21.1399 6.92014 20.9699L11.6401 16.2599C11.8401 16.0599 12.1301 15.9999 12.3901 16.0799C14.8001 16.8399 17.4301 16.1899 19.2301 14.3899C20.5101 13.1099 21.2201 11.3899 21.2201 9.56992C21.2201 7.73992 20.5101 6.02992 19.2301 4.74992C17.9301 3.42992 16.1801 2.75992 14.4301 2.75992Z"
                fill="grey"
              />
              <path
                d="M9.19008 20.5399C9.00008 20.5399 8.81008 20.4699 8.66008 20.3199L6.36008 18.0199C6.07008 17.7299 6.07008 17.2499 6.36008 16.9599C6.65008 16.6699 7.13008 16.6699 7.42008 16.9599L9.72008 19.2599C10.0101 19.5499 10.0101 20.0299 9.72008 20.3199C9.57008 20.4699 9.38008 20.5399 9.19008 20.5399Z"
                fill="grey"
              />
              <path
                d="M14.5 11.75C13.26 11.75 12.25 10.74 12.25 9.5C12.25 8.26 13.26 7.25 14.5 7.25C15.74 7.25 16.75 8.26 16.75 9.5C16.75 10.74 15.74 11.75 14.5 11.75ZM14.5 8.75C14.09 8.75 13.75 9.09 13.75 9.5C13.75 9.91 14.09 10.25 14.5 10.25C14.91 10.25 15.25 9.91 15.25 9.5C15.25 9.09 14.91 8.75 14.5 8.75Z"
                fill="grey"
              />
            </svg>
            <input
              :type="showPassword ? 'text' : 'password'"
              class="p-4 border w-full rounded-xl input-primary text-sm md:text-md ps-12"
              placeholder="ex: suasenha123"
              v-model="loginForm.password"
              id="password"
            />
            <button @click="showPassword = !showPassword" class="absolute right-[5%] top-1/2 -translate-y-1/2">
              <svg v-if="showPassword" viewBox="0 0 24 24" role="presentation" style="width: 1.8rem; height: 1.8rem">
                <path
                  d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z"
                  style="fill: gray"
                ></path>
              </svg>
              <svg v-else viewBox="0 0 24 24" role="presentation" style="width: 1.8rem; height: 1.8rem">
                <path
                  d="M2,5.27L3.28,4L20,20.72L18.73,22L15.65,18.92C14.5,19.3 13.28,19.5 12,19.5C7,19.5 2.73,16.39 1,12C1.69,10.24 2.79,8.69 4.19,7.46L2,5.27M12,9A3,3 0 0,1 15,12C15,12.35 14.94,12.69 14.83,13L11,9.17C11.31,9.06 11.65,9 12,9M12,4.5C17,4.5 21.27,7.61 23,12C22.18,14.08 20.79,15.88 19,17.19L17.58,15.76C18.94,14.82 20.06,13.54 20.82,12C19.17,8.64 15.76,6.5 12,6.5C10.91,6.5 9.84,6.68 8.84,7L7.3,5.47C8.74,4.85 10.33,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C12.69,17.5 13.37,17.43 14,17.29L11.72,15C10.29,14.85 9.15,13.71 9,12.28L5.6,8.87C4.61,9.72 3.78,10.78 3.18,12Z"
                  style="fill: gray"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <!-- <div class="flex items-center gap-2">
                    <MySwitch :checked="loginForm.remember" :setChecked="(v: boolean) => loginForm.remember = v" :id="'remember'"/>
                    <label for="remember" class="text-sm">Lembrar-me</label>
                </div> -->
        <div>
          <button
            @click="login"
            class="w-full p-4 py-3 text-white rounded-xl font-medium sm:mt-4 transition duration-200"
            :style="{
              background: loginForm.email && loginForm.password ? '#323232' : '#f5f5f5',
              color: loginForm.email && loginForm.password ? '#fff' : '#949494',
            }"
          >
            Entrar
          </button>
        </div>
      </form>
    </div>
    <CentralSpinner v-if="isLoading" :zIndex="10" />
  </div>
</template>
<script lang="ts" setup>
  import { ref, onMounted } from 'vue'
  import CentralSpinner from '../../../components/loaders/CentralSpinner.vue'
  import axiosClient from '../../../api/axios'
  import { useToast } from 'vue-toastification'
  import router from '@/router'
  import { isLoading } from '@/global/state/GlobalState'
  import MySwitch from '@/components/inputs/MySwitch.vue'

  const toast = useToast()

  const loginForm = ref<any>({
    email: '',
    password: '',
    // remember: false
  })

  const showPassword = ref<boolean>(false)

  onMounted(() => {
    const link = document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'icon'
    link.href = '/favicon.png'
    document.head.append(link)
  })

  function login() {
    if (loginForm.value.email && loginForm.value.password) {
      axiosClient
        .post('/sessions', loginForm.value)
        .then((res: any) => {
          if (res.data.roles.includes('ADMIN')) {
            localStorage.setItem('token', res.data.token)
            toast.success('Logado com sucesso')
            router.push({ name: 'organizations' })
          } else {
            toast.error('Credenciais inválidas')
          }
        })
        .catch((error: any) => {
          toast.error('Credenciais inválidas')
        })
    }
  }
</script>

<style lang="scss">
  .login {
    min-height: 85vh;

    img {
      width: 12rem;
    }

    .input-primary {
      border-color: #d5d5d5;
    }

    .input-primary:focus {
      border-color: var(--primary-color);
      outline: 1px solid var(--primary-color) !important;

      &::placeholder {
        color: var(--primary-color);
      }

      & + svg path {
        fill: var(--primary-color) !important;
      }
    }
  }

  @media screen and (max-width: 550px) {
    .login {
      &__container {
        width: 100% !important;
      }
    }
  }
</style>
