enum OrganizationStatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  BLOCKED = 'blocked',
  PENDING = 'pending',
}

export const organizationStatusTranslations = {
  [OrganizationStatusEnum.ACTIVE]: 'ativo',
  [OrganizationStatusEnum.INACTIVE]: 'inativo',
  [OrganizationStatusEnum.BLOCKED]: 'bloqueado',
  [OrganizationStatusEnum.PENDING]: 'pendente',
}

export default OrganizationStatusEnum