import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["value", "id", "name"]

import { ref } from 'vue'

  
export default /*@__PURE__*/_defineComponent({
  __name: 'FxSelect',
  props: {
    name: {},
    currentValue: {},
    for: {}
  },
  setup(__props: any) {

  const props = __props

  /* SCRIPT SELECT */
  const isSelectOpen = ref<boolean>(false)

  const closeSelect = () => {
    setTimeout(() => {
      isSelectOpen.value = false
    }, 80)
  }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["mySelect", { open: isSelectOpen.value }]),
    for: props.for || ''
  }, [
    _createElementVNode("input", {
      type: "text",
      placeholder: "Selecione",
      value: _ctx.currentValue,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (isSelectOpen.value ? closeSelect() : (isSelectOpen.value = true))),
      onBlur: closeSelect,
      id: props.for || 'my-select',
      name: _ctx.name,
      readonly: ""
    }, null, 40, _hoisted_2),
    _createElementVNode("div", null, [
      _createElementVNode("ul", null, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ], 10, _hoisted_1))
}
}

})